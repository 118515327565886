exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*$ui-primary: hsla(215, 100%, 95%, 1); !* #E5F0FF *!*/\n/* #E5F0FF */\n/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #FF4254 主题颜色 */\n/* #3373CC */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #E53C4C */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n/* layout contants from `layout-constants.js` */\n.inline-message_inline-message_3EzKK {\r\n    color: hsla(0, 100%, 100%, 1);\r\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-pack: end;\r\n    -webkit-justify-content: flex-end;\r\n        -ms-flex-pack: end;\r\n            justify-content: flex-end;\r\n    -webkit-box-align: center;\r\n    -webkit-align-items: center;\r\n        -ms-flex-align: center;\r\n            align-items: center;\r\n    font-size: .8125rem;\r\n}\n.inline-message_success_1HE_O {\r\n    color: hsla(0, 100%, 100%, 0.75);\r\n}\n.inline-message_info_8Giql {\r\n    color: hsla(0, 100%, 100%, 1);\r\n}\n.inline-message_warn_3ryT7 {\r\n    color: hsla(30, 100%, 70%, 1);\r\n}\n.inline-message_spinner_H-tr3 {\r\n    margin-right: 0.5rem;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"inline-message": "inline-message_inline-message_3EzKK",
	"inlineMessage": "inline-message_inline-message_3EzKK",
	"success": "inline-message_success_1HE_O",
	"info": "inline-message_info_8Giql",
	"warn": "inline-message_warn_3ryT7",
	"spinner": "inline-message_spinner_H-tr3"
};