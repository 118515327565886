/*
* author : zyk
* time : 2020-9-28
* */

import {replaceQuotationMarks} from "../common";

let MP3_Flag = 0;
export default function (Blockly) {

    // 打印
    Blockly.Python['wyb_printText'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        return 'print(' + TEXT + ')\n';
    };
    // interface 下拉框
    Blockly.Python['wyb_menu_INTERFACE_MENU'] = function(block) {
        let Interface = block.getFieldValue('INTERFACE_MENU');
        return [Interface];
    }
    // level下拉框
    Blockly.Python['wyb_menu_LEVEL_MENU'] = function(block) {
        let level = block.getFieldValue('LEVEL_MENU');
        return [level];
    }
    // 数字输入管脚下拉框
    Blockly.Python['wyb_menu_DIGITAL_INPUT_PIN_MENU'] = function(block) {
        let DIGITAL_INPUT_PIN = parseInt(block.getFieldValue('DIGITAL_INPUT_PIN_MENU'));
        return [DIGITAL_INPUT_PIN];
    }
    // 模拟输入管脚 下拉框
    Blockly.Python['IoTb_menu_ANALOG_INPUT_PIN_MENU'] = function(block) {
        let ANALOG_INPUT_PIN = parseInt(block.getFieldValue('ANALOG_INPUT_PIN_MENU'));
        return [ANALOG_INPUT_PIN];
    }
    // 语音指令
    Blockly.Python['wyb_menu_Speech_Recognition_MENU'] = function(block) {
        let Speech_Recognition = block.getFieldValue('Speech_Recognition_MENU');
        return [Speech_Recognition];
    }
    // 选择风扇开关下拉框
    Blockly.Python['wyb_menu_SWITCH_MENU'] = function(block) {
        let Fan = block.getFieldValue('SWITCH_MENU');
        return [Fan];
    }
    // 选择灯开关下拉框
    Blockly.Python['wyb_menu_SWITCH_MENU'] = function(block) {
        let Light = block.getFieldValue('SWITCH_MENU');
        return [Light];
    }
    // MP3操作
    Blockly.Python['wyb_menu_MP3_OPERATE_MENU'] = function(block){
        let MP3_OPERATE = block.getFieldValue('MP3_OPERATE_MENU');
        return [MP3_OPERATE]
    }
    //设置数字输出
    Blockly.Python['wyb_setDigitalOutput'] = function (block) {
        let INTERFACE = Blockly.Python.valueToCode(block, 'INTERFACE', Blockly.Python.ORDER_ATOMIC);
        let LEVEL = Blockly.Python.valueToCode(block, 'LEVEL', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return 'pin'+INTERFACE+' = machine.Pin('+INTERFACE+',machine.Pin.OUT)\n'+
            'pin'+INTERFACE+'.value('+LEVEL+')\n'
    }
    //读取数字量
    Blockly.Python['wyb_loadDigitalOutput'] = function(block){
        let DIGITAL_INPUT_PIN = Blockly.Python.valueToCode(block, 'DIGITAL_INPUT_PIN', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let functionName = Blockly.Python.provideFunction_(
            'digitalRead', ['def '+ Blockly.Python.FUNCTION_NAME_PLACEHOLDER_+'(p):',
                '  pin0 = machine.Pin(p, machine.Pin.IN)',
                '  return pin0.value()']
        )
        let code = functionName+'('+DIGITAL_INPUT_PIN+')'
        return [code]
    }
    //读取模拟量
    Blockly.Python['wyb_loadAnalogOutput'] = function(block){
        let ANALOG_INPUT_PIN = Blockly.Python.valueToCode(block, 'ANALOG_INPUT_PIN', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let functionName = Blockly.Python.provideFunction_(
            'analogRead',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(p):',
                '  adc = machine.ADC(machine.Pin(p))',
                '  adc.atten(machine.ADC.ATTN_11DB)',
                '  return adc.read()']);
        let code = functionName+'('+ANALOG_INPUT_PIN+')'
        return [code]
    }
    //风扇
    Blockly.Python['wyb_switchFan'] = function (block) {
        let SWITCH = Blockly.Python.valueToCode(block, 'SWITCH', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return 'pin27 = machine.Pin(27,machine.Pin.OUT)\npin27.value('+SWITCH+')\n'
    }
    //灯
    Blockly.Python['wyb_switchLight'] = function (block) {
        let SWITCH = Blockly.Python.valueToCode(block, 'SWITCH', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return 'pin25 = machine.Pin(25,machine.Pin.OUT)\npin25.value('+SWITCH+')\n'
    }
    //屏幕输出
    Blockly.Python['wyb_setOLEDShowText'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC);
        let COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_MAX7219'] = 'import MAX7219';
        // Blockly.Python.definitions_['import_PIN_SPI'] = 'from machine import Pin, SPI\n';
        Blockly.Python.definitions_['spi'] = 'spi = machine.SPI(1, baudrate=10000000, polarity=1, phase=0, sck=machine.Pin(18), mosi=machine.Pin(23))\nss = machine.Pin(5, machine.Pin.OUT)\n';
        Blockly.Python.definitions_['MAX7219'] = 'display = MAX7219.Matrix8x8(spi, ss, 8)\ndisplay.fill(0)\n';
        return 'display.text(' + TEXT + ','+COORDINATE_X+','+COORDINATE_Y+',1)\n'+
        'display.show()\n';
    };
    //展示时间
    Blockly.Python['wyb_setOLEDShowDate'] = function (block) {
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_time'] = 'import time';
        Blockly.Python.definitions_['import_MAX7219'] = 'import MAX7219';
        Blockly.Python.definitions_['import_DS3231'] = 'import DS3231';
        // Blockly.Python.definitions_['import_PIN_SPI'] = 'from machine import Pin, SPI\n';
        Blockly.Python.definitions_['spi'] = 'spi = machine.SPI(1, baudrate=10000000, polarity=1, phase=0, sck=machine.Pin(18), mosi=machine.Pin(23))\nss = machine.Pin(5, machine.Pin.OUT)\ndisplay = MAX7219.Matrix8x8(spi, ss, 8)\n';
        let functionName = Blockly.Python.provideFunction_(
            'LEDShowDate',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  datime = DS3231.DS3231(16,17)',
                '  nowtime = []',
                '  nowtime1 = ""',
                '  nowtime2 = ""',
                '  nowtime3 = ""',
                '  nowtime4 = ""',
                '  diandd = 0',
                '  nowtime = datime.TIME()',
                '  if len(str(nowtime[0])) == 2:',
                    '    nowtime1 = str(nowtime[0])[0]',
                    '    nowtime2 = str(nowtime[0])[1]',
                '  else:',
                    '    nowtime1 = "0"',
                    '    nowtime2 = str(nowtime[0])[0]',
                '  if len(str(nowtime[1])) == 2:',
                    '    nowtime3 = str(nowtime[1])[0]',
                    '    nowtime4 = str(nowtime[1])[1]',
                '  else:',
                    '    nowtime3 = "0"',
                    '    nowtime4 = str(nowtime[1])[0]',
                '  display.text(nowtime1,56,0,1)',
                '  display.text(nowtime2,47,0,1)',
                '  display.text(nowtime3,41,0,1)',
                '  display.text(nowtime4,32,0,1)',
                '  diandd = diandd + 1',
                '  if diandd < 5:',
                    '    display.text(":",36,0,1)',
                '  if diandd > 9:',
                    '    diandd = 0',
                '  display.show()'
            ]);
        return functionName+'()\n';
    };
    //展示温湿度
    Blockly.Python['wyb_setOLEDShowTempsht'] = function (block) {
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_MAX7219'] = 'import MAX7219';
        Blockly.Python.definitions_['import_sht20'] = 'import sht20';
        // Blockly.Python.definitions_['import_PIN_SPI'] = 'from machine import Pin, SPI\n';
        Blockly.Python.definitions_['import_LedShow'] = 'spi = machine.SPI(1, baudrate=10000000, polarity=1, phase=0, sck=machine.Pin(18), mosi=machine.Pin(23))\nss = machine.Pin(5, machine.Pin.OUT)\ndisplay = MAX7219.Matrix8x8(spi, ss, 8)\ni2c = machine.I2C(scl = machine.Pin(17), sda = machine.Pin(16), freq = 400000)';
        let functionName = Blockly.Python.provideFunction_(
            'LEDShowTempsht',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  sht = sht20.SHT20(i2c)',
                '  tempsht = 0',
                '  tempsht = sht.get_SHT_temperature()',
                '  tempsht = tempsht - 5',
                '  tempsht = str(tempsht)',
                '  display.text(tempsht[0],24,1,1)',
                '  display.text(tempsht[1],16,1,1)',
                '  display.text(tempsht[2],8,1,1)',
                '  display.text(tempsht[3],0,1,1)',
                '  display.show()'
            ]);
        return functionName+'()\n';
    };
    //MP3操作
    Blockly.Python['wyb_MP3MusicOperate'] = function (block) {
        let MP3_OPERATE = Blockly.Python.valueToCode(block, 'MP3_OPERATE', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['uart_init'] = 'uart = machine.UART(2, baudrate=9600, rx=19,tx=21,timeout=10)';
        return 'uart.write('+MP3_OPERATE+')\n';
    };
    // 初始化串口
    Blockly.Python['wyb_initUart'] = function (block) {
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return 'uart = machine.UART(2, baudrate=9600, rx=19,tx=21,timeout=10)\n'
    };
    // 串口读取数据
    Blockly.Python['wyb_whileUartAny'] = function (block) {
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return ['uart.any()', Blockly.Python.ORDER_ATOMIC]
    };
    // 串口读取一行数据
    Blockly.Python['wyb_uartReadline'] = function (block) {
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return 'bin_data = uart.readline()\n'
    };
    // 语音识别
    Blockly.Python['wyb_speechRecognition'] = function (block) {
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let Speech_Recognition =Blockly.Python.valueToCode(block, 'Speech_Recognition', Blockly.Python.ORDER_ATOMIC);

        let SUBSTACK = Blockly.Python.statementToCode(block, 'SUBSTACK'),
            pass = Blockly.Python.addLoopTrap(SUBSTACK, block.id) || '  pass';
        let pass_format = pass.split('\n');
        let func_body='';
        for(let i = 0;i< pass_format.length;i++){
            func_body += pass_format[i]+'\n'
        }
        return "if len(bin_data)> 4 and bin_data[4] == "+Speech_Recognition+":\n" +
            func_body+'\n'
    };
    // 串口写数据
    Blockly.Python['wyb_uartWrite'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let BYTES =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'BYTES', Blockly.Python.ORDER_ATOMIC));
        return 'uart.write('+BYTES+')\n'
    }
}


