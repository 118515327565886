import {regQuotationMarks,replaceQuotationMarks, colorHexToRGB} from '../common.js'

// function formatNumber(num) {
//     if(isNaN(num)){return 0}
//     else return num
// }

export default function (Blockly) {
    // 列表排序方式下拉框
    Blockly.Python['advanced_list_sort_menu'] = function(block) {
        let SORT = block.getFieldValue('SORT');
        return [SORT];
    }
    /**
     * 字符串
     */
    Blockly.Python['advanced_str_replace'] = function(block) {
        let STRING =Blockly.Python.valueToCode(block, 'STRING', Blockly.Python.ORDER_ATOMIC);
        let REPLACE1 =Blockly.Python.valueToCode(block, 'REPLACE1', Blockly.Python.ORDER_ATOMIC);
        let REPLACE2 =Blockly.Python.valueToCode(block, 'REPLACE2', Blockly.Python.ORDER_ATOMIC);
        return [STRING+'.replace('+REPLACE1+','+REPLACE2+')'];
    }
    Blockly.Python['advanced_str_split'] = function(block) {
        let STRING =Blockly.Python.valueToCode(block, 'STRING', Blockly.Python.ORDER_ATOMIC);
        let SPLIT =Blockly.Python.valueToCode(block, 'SPLIT', Blockly.Python.ORDER_ATOMIC);
        return [STRING+'.split('+SPLIT+')'];
    }

    /**
    * 列表
    * */
    Blockly.Python['advanced_init_list'] = function (block) {
        let list = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC));
        return ['['+list+']'];
    };
    Blockly.Python['advanced_list_append'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`,
        variable = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC));
        return replaceQuotationMarks(list)+'.append('+variable+')\n';
    };
    Blockly.Python['advanced_del_list_element'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`,
            index = Blockly.Python.valueToCode(block, 'INDEX', Blockly.Python.ORDER_ATOMIC);
        if(index === 'NaN'){index = 0}
        return 'del '+replaceQuotationMarks(list)+'['+index+']\n';
    };
    Blockly.Python['advanced_list_remove_first_value'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`,
            variable = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC));
        return replaceQuotationMarks(list)+'.remove('+variable+')\n';
    };
    Blockly.Python['advanced_list_clear'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`;
        return replaceQuotationMarks(list)+'.clear\n';
    };
    Blockly.Python['advanced_list_insert_index_value'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`,
            value = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC)),
            index = Blockly.Python.valueToCode(block, 'INDEX', Blockly.Python.ORDER_ATOMIC);
        if(index === 'NaN'){index = 0}
        return replaceQuotationMarks(list)+'.insert('+index+','+value+')\n';
    };
    Blockly.Python['advanced_list_replace_index_value'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`,
            value = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC)),
            index = Blockly.Python.valueToCode(block, 'INDEX', Blockly.Python.ORDER_ATOMIC);
        if(index === 'NaN'){index = 0}
        return replaceQuotationMarks(list)+'.replace('+index+','+value+')\n';
    };
    Blockly.Python['advanced_list_reverse'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`;
        return replaceQuotationMarks(list)+'.reverse()\n';
    };
    Blockly.Python['advanced_list_index_value'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`,
            index = Blockly.Python.valueToCode(block, 'INDEX', Blockly.Python.ORDER_ATOMIC);
        if(index === 'NaN'){index = 0}
        return [replaceQuotationMarks(list)+'['+index+']'];
    };
    Blockly.Python['advanced_list_value_index'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`,
            value = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC));
        return [replaceQuotationMarks(list)+'.index('+value+')'];
    };
    Blockly.Python['advanced_list_length'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`;
        return ['len('+replaceQuotationMarks(list)+')'];
    };
    Blockly.Python['advanced_list_has_value'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`,
            value = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC));
        return [value+' in '+replaceQuotationMarks(list), Blockly.Python.ORDER_ATOMIC];
    };
    Blockly.Python['advanced_list_to_set'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`;
        return ['tuple('+replaceQuotationMarks(list)+')']
    };
    Blockly.Python['advanced_list_del_repeat'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`;
        return ['list(set('+replaceQuotationMarks(list)+'))']
    };
    Blockly.Python['advanced_list_count_value'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`,
            value = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC));
        return [replaceQuotationMarks(list)+'.count('+value+')']
    };
    Blockly.Python['advanced_list_max_value'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`;
        return ['max('+replaceQuotationMarks(list)+')']
    };
    Blockly.Python['advanced_list_min_value'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`;
        return ['min('+replaceQuotationMarks(list)+')']
    };
    Blockly.Python['advanced_list_sort'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`,
        sort = Blockly.Python.valueToCode(block, 'SORT', Blockly.Python.ORDER_ATOMIC);
        return replaceQuotationMarks(list)+'.sort(reverse='+sort+')\n'
    };
    Blockly.Python['advanced_list_extend_seq'] = function (block) {
        let list = Blockly.Python.valueToCode(block, 'LIST', Blockly.Python.ORDER_ATOMIC)|| `[]`,
            seq = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SEQ', Blockly.Python.ORDER_ATOMIC));
        return replaceQuotationMarks(list)+'.extend('+seq+')\n'
    };


    /**
     * 元组
     * */
    Blockly.Python['advanced_init_tuple'] = function (block) {
        let tuple = Blockly.Python.valueToCode(block, 'TUPLE', Blockly.Python.ORDER_ATOMIC)|| `()`;
        return ['('+replaceQuotationMarks(tuple)+')']
    };
    Blockly.Python['advanced_tuple_index_value'] = function (block) {
        let tuple = Blockly.Python.valueToCode(block, 'TUPLE', Blockly.Python.ORDER_ATOMIC)|| `()`,
            index = Blockly.Python.valueToCode(block, 'INDEX', Blockly.Python.ORDER_ATOMIC);
        if(index === 'NaN'){index = 0}
        return [replaceQuotationMarks(tuple)+'['+index+']']
    };
    Blockly.Python['advanced_tuple_length'] = function (block) {
        let tuple = Blockly.Python.valueToCode(block, 'TUPLE', Blockly.Python.ORDER_ATOMIC)|| `()`;
        return ['len('+replaceQuotationMarks(tuple)+')']
    };
    Blockly.Python['advanced_tuple_has_value'] = function (block) {
        let tuple = Blockly.Python.valueToCode(block, 'TUPLE', Blockly.Python.ORDER_ATOMIC)|| `()`,
            value = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC));
        return [value+' in '+replaceQuotationMarks(tuple), Blockly.Python.ORDER_ATOMIC]
    };
    Blockly.Python['advanced_tuple_to_list'] = function (block) {
        let tuple = Blockly.Python.valueToCode(block, 'TUPLE', Blockly.Python.ORDER_ATOMIC)|| `()`
        return ['list('+tuple+')']
    };

    /**
     * 字典
     * */
    Blockly.Python['advanced_dictionary_init'] = function (block) {
        let dictionary = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'DICTIONARY', Blockly.Python.ORDER_ATOMIC)|| `{}`);
        return ['{'+dictionary+'}']
    };
    Blockly.Python['advanced_dictionary_key_value'] = function (block) {
        let dictionary = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'DICTIONARY', Blockly.Python.ORDER_ATOMIC)|| `{}`),
            key = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'KEY', Blockly.Python.ORDER_ATOMIC));
        return [dictionary+'['+key+']']
    };
    Blockly.Python['advanced_set_dictionary_key_value'] = function (block) {
        let dictionary = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'DICTIONARY', Blockly.Python.ORDER_ATOMIC)|| `{}`),
            key = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'KEY', Blockly.Python.ORDER_ATOMIC)),
            value = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC));
        return dictionary+'['+key+']'+' = '+value+'\n'
    };
    Blockly.Python['advanced_del_dictionary_key'] = function (block) {
        let dictionary = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'DICTIONARY', Blockly.Python.ORDER_ATOMIC)|| `{}`),
            key = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'KEY', Blockly.Python.ORDER_ATOMIC));
        return 'del '+dictionary+'['+key+']\n'
    };
    Blockly.Python['advanced_dictionary_key_count'] = function (block) {
        let dictionary = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'DICTIONARY', Blockly.Python.ORDER_ATOMIC)|| `{}`);
        return ['len('+dictionary+')']
    };
    Blockly.Python['advanced_dictionary_has_key'] = function (block) {
        let dictionary = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'DICTIONARY', Blockly.Python.ORDER_ATOMIC)|| `{}`),
            key = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'KEY', Blockly.Python.ORDER_ATOMIC));
        return [key+' in '+dictionary+'', Blockly.Python.ORDER_ATOMIC]
    };
    Blockly.Python['advanced_dictionary_clear'] = function (block) {
        let dictionary = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'DICTIONARY', Blockly.Python.ORDER_ATOMIC)|| `{}`);
        return dictionary+'.clear()\n'
    };

    /**
     * 集合
     * */
    Blockly.Python['advanced_set_init'] = function (block) {
        let set = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SET', Blockly.Python.ORDER_ATOMIC)|| `{}`);
        return ['{'+set+'}']
    };
    Blockly.Python['advanced_set_add_value'] = function (block) {
        let set = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SET', Blockly.Python.ORDER_ATOMIC)|| `set()`),
            value = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC));
        return set+'.update('+value+')\n'
    };
    Blockly.Python['advanced_set_del_value'] = function (block) {
        let set = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SET', Blockly.Python.ORDER_ATOMIC)|| `set()`),
            value = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC));
        return set+'.discard('+value+')\n'
    };
    Blockly.Python['advanced_set_element_count'] = function (block) {
        let set = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SET', Blockly.Python.ORDER_ATOMIC)|| `set()`);
        return ['len('+set+')']
    };
    Blockly.Python['advanced_set_has_value'] = function (block) {
        let set = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SET', Blockly.Python.ORDER_ATOMIC)|| `set()`),
            value = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC));
        return [value+' in '+set+'', Blockly.Python.ORDER_ATOMIC]
    };
    Blockly.Python['advanced_set_clear'] = function (block) {
        let set = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SET', Blockly.Python.ORDER_ATOMIC)|| `set()`);
        return set+'.clear()\n'
    };
    Blockly.Python['advanced_set_to_list'] = function (block) {
        let set = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SET', Blockly.Python.ORDER_ATOMIC)|| `set()`);
        return ['list('+set+')']
    };
    Blockly.Python['advanced_set_union'] = function (block) {
        let set1 = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SET1', Blockly.Python.ORDER_ATOMIC)|| `set()`);
        let set2 = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SET2', Blockly.Python.ORDER_ATOMIC)|| `set()`);
        return [set1+'.union('+set2+')']
    };
    Blockly.Python['advanced_set_intersection'] = function (block) {
        let set1 = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SET1', Blockly.Python.ORDER_ATOMIC)|| `set()`);
        let set2 = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SET2', Blockly.Python.ORDER_ATOMIC)|| `set()`);
        return [set1+'.intersection('+set2+')']
    };
    Blockly.Python['advanced_set_difference'] = function (block) {
        let set1 = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SET1', Blockly.Python.ORDER_ATOMIC)|| `set()`);
        let set2 = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SET2', Blockly.Python.ORDER_ATOMIC)|| `set()`);
        return [set1+'.difference('+set2+')']
    };
    Blockly.Python['advanced_set_sysmmetric_difference'] = function (block) {
        let set1 = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SET1', Blockly.Python.ORDER_ATOMIC)|| `set()`);
        let set2 = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SET2', Blockly.Python.ORDER_ATOMIC)|| `set()`);
        return [set1+'.sysmmetric_difference('+set2+')']
    };
    Blockly.Python['advanced_jsonLoadsValue'] = function (block) {
        Blockly.Python.definitions_['import_json'] = 'import json';
        let value = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC)|| ``);
        let name = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC)|| ``);
        return name+' = json.loads('+value+')\n'
    };
}
