exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*$ui-primary: hsla(215, 100%, 95%, 1); !* #E5F0FF *!*/\n/* #E5F0FF */\n/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #FF4254 主题颜色 */\n/* #3373CC */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #E53C4C */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.icon-button_container_Vz-T6 {\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-orient: vertical;\r\n    -webkit-box-direction: normal;\r\n    -webkit-flex-direction: column;\r\n        -ms-flex-direction: column;\r\n            flex-direction: column;\r\n    -webkit-box-align: center;\r\n    -webkit-align-items: center;\r\n        -ms-flex-align: center;\r\n            align-items: center;\r\n    cursor: pointer;\r\n    font-size: 0.75rem;\r\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\r\n    color: hsla(354, 100%, 63%, 1);\r\n    border-radius: 0.5rem;\r\n}\n.icon-button_container_Vz-T6 + .icon-button_container_Vz-T6 {\r\n    margin-top: 1.25rem;\r\n}\n.icon-button_title_3_47t {\r\n    margin-top: 0.5rem;\r\n    text-align: center;\r\n}\n.icon-button_disabled_14mfd {\r\n    opacity: 0.5;\r\n    pointer-events: none;\r\n}\n.icon-button_container_Vz-T6:active {\r\n    background-color: hsla(215, 100%, 65%, 0.15);\r\n}\r\n", ""]);

// exports
exports.locals = {
	"container": "icon-button_container_Vz-T6",
	"title": "icon-button_title_3_47t",
	"disabled": "icon-button_disabled_14mfd"
};