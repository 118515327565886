import classNames from 'classnames';
import {connect} from 'react-redux';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import Box from '../box/box.jsx';
import ReactDOM from "react-dom";
import styles from './code-editor.css';

import * as CodeMirror from 'codemirror/lib/codemirror';

import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/python/python';
import 'codemirror/theme/base16-light.css';
import 'codemirror/theme/neo.css'
import 'codemirror/theme/3024-day.css'
import 'codemirror/addon/scroll/simplescrollbars.css'
import 'codemirror/addon/scroll/simplescrollbars'


class CodeEditor extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'updateCode',
            'editorContainer',
            'handleEditTextChange'
        ])
        this.state = {
            code: "// Code",
        }
    }
    componentDidMount() {
        this.codeMirror = CodeMirror.fromTextArea(this.editor, {
            lineNumbers: true,
            mode: "python",
            extraKeys: { "Ctrl": "autocomplete" },
            theme: "neo",
            readOnly: 'cursor',
            lineWrapping: true,
            scrollbarStyle: "overlay", //滚动条样式
            indentUnit:4 // 缩进单位
        });
        let text = this.props.code ? this.props.code : "";
        this.codeMirror.setValue(text);
        this.codeMirror.on("change", this.handleEditTextChange)
        window.codeMirror = this.codeMirror;
    }
    componentDidUpdate(prevProps) {
        if (!this.props.canEdit && this.props.code !== prevProps.code) {
            this.codeMirror.setOption("readOnly", 'cursor')
            this.codeMirror.setValue(this.props.code);
        }else if(this.props.canEdit){
            this.codeMirror.setOption("readOnly", false);
        }
    }
    updateCode(newCode){
        this.setState({
            code: newCode,
        });
    }
    editorContainer(editor) {
        this.editor = editor;
    }
    /**
     * 编辑代码发生变化传给父组件
     * */
    handleEditTextChange(){
        let text = this.codeMirror.getValue()
        if (this.props.canEdit){
            this.props.handleChildTextarea(text)
        }
    }

    render () {
        return (
            <Box className={styles.container}>
                <textarea id="editor" ref={this.editorContainer}/>
            </Box>
        );
    }
}


export default CodeEditor;
