/*
* author : zyk
* time : 2020-10-14
* */
import {regQuotationMarks,replaceQuotationMarks, colorHexToRGB} from '../common.js'

export default function (Blockly) {
    // 管脚号下拉框
    Blockly.Arduino['wlb_menu_PIN_MENU'] = function (block) {
        let PIN_NUM = block.getFieldValue('PIN_MENU');
        return [PIN_NUM];
    }
    // 传感器管脚号下拉框
    Blockly.Arduino['wlb_menu_SENSOR_PIN_MENU'] = function (block) {
        let SENSOR_PIN = block.getFieldValue('SENSOR_PIN_MENU');
        return [SENSOR_PIN];
    }
    // 模拟打印管脚号下拉框
    Blockly.Arduino['wlb_menu_ANALOG_PRINT_PIN_MENU'] = function (block) {
        let ANALOG_PRINT_PIN = block.getFieldValue('ANALOG_PRINT_PIN_MENU');
        return [ANALOG_PRINT_PIN];
    }
    // 模拟量管脚号下拉框
    Blockly.Arduino['wlb_menu_ANALOG_PIN_MENU'] = function (block) {
        let ANALOG_PIN = block.getFieldValue('ANALOG_PIN_MENU');
        return [ANALOG_PIN];
    }
    // 工作模式下拉框
    Blockly.Arduino['wlb_menu_OPERATION_MODE_MENU'] = function (block) {
        let OPERATIONMODE = block.getFieldValue('OPERATION_MODE_MENU');
        return [OPERATIONMODE];
    }
    // 高低电平下拉框
    Blockly.Arduino['wlb_menu_LEVEL_MENU'] = function (block) {
        let LEVEL = block.getFieldValue('LEVEL_MENU');
        return [LEVEL];
    }
    // DHT下拉框
    Blockly.Arduino['wlb_menu_DHT_MENU'] = function (block) {
        let DHT = block.getFieldValue('DHT_MENU');
        return [DHT];
    }
    // 温湿度下拉框
    Blockly.Arduino['wlb_menu_HUMITURE_MENU'] = function (block) {
        let HUMITURE = block.getFieldValue('HUMITURE_MENU');
        return [HUMITURE];
    }
    // MPU6050下拉框
    Blockly.Arduino['wlb_menu_MPU_MENU'] = function (block) {
        let MPU = block.getFieldValue('MPU_MENU');
        return [MPU];
    }
    // 电机下拉框
    Blockly.Arduino['wlb_menu_MOTOR_MENU'] = function (block) {
        let MOTOR = block.getFieldValue('MOTOR_MENU');
        return [MOTOR];
    }
    // 红外遥控下拉框
    Blockly.Arduino['wlb_menu_INFRARED_BUTTON_MENU'] = function (block) {
        let INFRARED_BUTTON = block.getFieldValue('INFRARED_BUTTON_MENU');
        return [INFRARED_BUTTON];
    }
    // 数码管型号下拉框
    Blockly.Arduino['wlb_menu_DIGITRON_MODEL_MENU'] = function (block) {
        let DIGITRON_MODEL = block.getFieldValue('DIGITRON_MODEL_MENU');
        return [DIGITRON_MODEL];
    }
    //数码管状态下拉框
    Blockly.Arduino['wlb_menu_DIGITRON_STATE_MENU'] = function (block){
        let DIGITRON_STATE = block.getFieldValue('DIGITRON_STATE_MENU');
        return [DIGITRON_STATE]
    }
    //数码管亮度下拉框
    Blockly.Arduino['wlb_menu_DIGITRON_LIGHT_MENU'] = function (block){
        let DIGITRON_LIGHT = block.getFieldValue('DIGITRON_LIGHT_MENU');
        return [DIGITRON_LIGHT]
    }
    //数码管位数下拉框
    Blockly.Arduino['wlb_menu_DIGITRON_DIGIT_MENU'] = function (block){
        let DIGITRON_DIGIT = block.getFieldValue('DIGITRON_DIGIT_MENU');
        return [DIGITRON_DIGIT]
    }
    //数码管段位下拉框
    Blockly.Arduino['wlb_menu_DIGITRON_RANK_MENU'] = function (block){
        let DIGITRON_RANK = block.getFieldValue('DIGITRON_RANK_MENU');
        return [DIGITRON_RANK]
    }
    //LCD状态下拉框
    Blockly.Arduino['wlb_menu_LCD_STATE_MENU'] = function (block){
        let LCD_STATE = block.getFieldValue('LCD_STATE_MENU');
        return [LCD_STATE]
    }
    //LCD位置行下拉框
    Blockly.Arduino['wlb_menu_LCD_ADDRESS_X_MENU'] = function (block){
        let LCD_ADDRESS_X = block.getFieldValue('LCD_ADDRESS_X_MENU');
        return [LCD_ADDRESS_X]
    }
    //LCD位置列下拉框
    Blockly.Arduino['wlb_menu_LCD_ADDRESS_Y_MENU'] = function (block){
        let LCD_ADDRESS_Y = block.getFieldValue('LCD_ADDRESS_Y_MENU');
        return [LCD_ADDRESS_Y]
    }
    //LCD显示列表下拉框
    Blockly.Arduino['wlb_menu_LCD_DISPLAY_MENU'] = function (block){
        let LCD_DISPLAY = block.getFieldValue('LCD_DISPLAY_MENU');
        return [LCD_DISPLAY]
    }
    // 声明变量类型
    Blockly.Arduino['wlb_menu_VARIABLE_TYPE_MENU'] = function(block) {
        let VARIABLE_TYPE = block.getFieldValue('VARIABLE_TYPE_MENU');
        return [VARIABLE_TYPE];
    }
    // 变量操作符
    Blockly.Arduino['wlb_menu_VARIABLE_OPERATOR_MENU'] = function(block) {
        let VARIABLE_OPERATOR = block.getFieldValue('VARIABLE_OPERATOR_MENU');
        return [VARIABLE_OPERATOR];
    }

    // 初始化
    Blockly.Arduino['wlb_setUp'] = function (block) {
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        let SUBSTACK = Blockly.Arduino.statementToCode(block, 'SUBSTACK'),
            pass = Blockly.Arduino.addLoopTrap(SUBSTACK, block.id) || '';
        pass = pass.trim();
        Blockly.Arduino.setups_['setUp'] = pass;
        return ''
    }
    // 设置管脚工作模式
    Blockly.Arduino['wlb_setOperationMode'] = function (block) {
        let PIN_NUM = Blockly.Arduino.valueToCode(block, 'PIN', Blockly.Arduino.ORDER_ATOMIC);
        let OPERATIONMODE = Blockly.Arduino.valueToCode(block, 'OPERATION_MODE', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'pinMode(' + PIN_NUM + ',' + OPERATIONMODE + ');\n'
    }
    // 设置输出数字量
    Blockly.Arduino['wlb_setDigitalOutput'] = function (block) {
        let Digital_PIN = Blockly.Arduino.valueToCode(block, 'Digital_PIN', Blockly.Arduino.ORDER_ATOMIC);
        let LEVEL = Blockly.Arduino.valueToCode(block, 'LEVEL', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'digitalWrite(' + Digital_PIN + ',' + LEVEL + ');\n'
    }
    // 读取数字量
    Blockly.Arduino['wlb_readDigitalValue'] = function (block) {
        let Digital_PIN = Blockly.Arduino.valueToCode(block, 'Digital_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return ['digitalRead('+Digital_PIN+')',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 模拟输出
    Blockly.Arduino['wlb_setAnalogOutput'] = function (block) {
        let ANALOG_OUTPUT_PIN = Blockly.Arduino.valueToCode(block, 'ANALOG_OUTPUT_PIN', Blockly.Arduino.ORDER_ATOMIC);
        let TEXT = Blockly.Arduino.valueToCode(block, 'TEXT', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'analogWrite(' + ANALOG_OUTPUT_PIN + ',' + TEXT + ');\n'
    }
    // 读取管脚模拟量
    Blockly.Arduino['wlb_loadAnalogOutput'] = function (block) {
        let ADC_PIN = Blockly.Arduino.valueToCode(block, 'ADC_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return ['analogRead('+ADC_PIN+')',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 读取滑动电位器
    Blockly.Arduino['wlb_readSlidePotentOutput'] = function (block) {
        let SILDE_POTENT_PIN = Blockly.Arduino.valueToCode(block, 'SILDE_POTENT_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['pin_mode'] = 'pinMode('+SILDE_POTENT_PIN+', INPUT);\n'
        return ['analogRead('+SILDE_POTENT_PIN+')',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 光线传感器
    Blockly.Arduino['wlb_readLightSensorOutput'] = function (block) {
        let LIGHT_SENSOR_PIN = Blockly.Arduino.valueToCode(block, 'LIGHT_SENSOR_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['pin_mode'] = 'pinMode('+LIGHT_SENSOR_PIN+', INPUT);\n'
        return ['analogRead('+LIGHT_SENSOR_PIN+')',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 声音传感器
    Blockly.Arduino['wlb_readSoundSensorOutput'] = function (block) {
        let SOUND_SENSOR_PIN = Blockly.Arduino.valueToCode(block, 'SOUND_SENSOR_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['pin_mode'] = 'pinMode('+SOUND_SENSOR_PIN+', INPUT);\n'
        return ['analogRead('+SOUND_SENSOR_PIN+')',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 震动传感器
    Blockly.Arduino['wlb_readShockSensorOutput'] = function (block) {
        let SHOCK_SENSOR_PIN = Blockly.Arduino.valueToCode(block, 'SHOCK_SENSOR_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['pin_mode'] = 'pinMode('+SHOCK_SENSOR_PIN+', INPUT);\n'
        return ['analogRead('+SHOCK_SENSOR_PIN+')',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 压力传感器
    Blockly.Arduino['wlb_readPressureSensorOutput'] = function (block) {
        let PRESSURE_SENSOR_PIN = Blockly.Arduino.valueToCode(block, 'PRESSURE_SENSOR_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['pin_mode'] = 'pinMode('+PRESSURE_SENSOR_PIN+', INPUT);\n'
        return ['(1024 - analogRead('+PRESSURE_SENSOR_PIN+'))',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 火焰传感器
    Blockly.Arduino['wlb_readFlameSensorOutput'] = function (block) {
        let FLAME_SENSOR_PIN = Blockly.Arduino.valueToCode(block, 'FLAME_SENSOR_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['pin_mode'] = 'pinMode('+FLAME_SENSOR_PIN+', INPUT);\n'
        return ['analogRead('+FLAME_SENSOR_PIN+')',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 湿度传感器
    Blockly.Arduino['wlb_readHumiditySensorOutput'] = function (block) {
        let HUMIDITY_SENSOR_PIN = Blockly.Arduino.valueToCode(block, 'HUMIDITY_SENSOR_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['pin_mode'] = 'pinMode('+HUMIDITY_SENSOR_PIN+', INPUT);\n'
        return ['analogRead('+HUMIDITY_SENSOR_PIN+')',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // LM35温度
    Blockly.Arduino['wlb_readLM35TempOutput'] = function (block) {
        let LM35_TEMP_PIN = Blockly.Arduino.valueToCode(block, 'LM35_TEMP_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.definitions_['getLM35'] = 'float getLM35_'+LM35_TEMP_PIN+'() {\n' +
            '  float temp = 0.0;\n' +
            '  temp = analogRead('+LM35_TEMP_PIN+') * 5 / 10.24;\n' +
            '  return temp;\n' +
            '}'
        return ['getLM35_'+LM35_TEMP_PIN+'()',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 温湿度
    Blockly.Arduino['wlb_readHumitureOutput'] = function (block) {
        let HUMITURE_PIN = Blockly.Arduino.valueToCode(block, 'HUMITURE_PIN', Blockly.Arduino.ORDER_ATOMIC);
        let HUMITURE_DHT = Blockly.Arduino.valueToCode(block, 'HUMITURE_DHT', Blockly.Arduino.ORDER_ATOMIC);
        let HUMITURE_VALUE = Blockly.Arduino.valueToCode(block, 'HUMITURE_VALUE', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.includes_['DHT.h'] = '#include "DHT.h"';
        Blockly.Arduino.definitions_['DHT'] = 'DHT DHT_'+HUMITURE_PIN+';\n'+
            'int getDHT_'+HUMITURE_PIN+'(uint8_t mode) {\n' +
            '  DHT_'+HUMITURE_PIN+'.Read'+HUMITURE_DHT+'('+HUMITURE_PIN+');\n' +
            '  if (mode == 0) {\n' +
            '    return DHT_'+HUMITURE_PIN+'.temperature;\n' +
            '  } else {\n' +
            '    return DHT_'+HUMITURE_PIN+'.humidity;\n' +
            '  }\n'+
            '}'
        return ['getDHT_'+HUMITURE_PIN+'('+HUMITURE_VALUE+')',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 超声波传感器
    Blockly.Arduino['wlb_readUltrasonicSensorOutput'] = function (block) {
        let T_PIN = Blockly.Arduino.valueToCode(block, 'T_PIN', Blockly.Arduino.ORDER_ATOMIC);
        let E_PIN = Blockly.Arduino.valueToCode(block, 'E_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.definitions_['getDistance'] = 'float getDistance(uint8_t pin1,uint8_t pin2) {\n' +
            '  pinMode(pin1, OUTPUT);\n' +
            '  pinMode(pin2, INPUT);\n' +
            '  digitalWrite(pin1, LOW);\n' +
            '  delayMicroseconds(2);\n' +
            '  digitalWrite(pin1, HIGH);\n' +
            '  delayMicroseconds(10);\n' +
            '  digitalWrite(pin1, LOW);\n' +
            '  float distance = pulseIn(pin2, HIGH) / 58.00;\n' +
            '  delay(10);\n' +
            '  return distance;\n' +
            '}'
        return ['getDistance('+T_PIN+','+E_PIN+')',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // MPU6050
    // 此处问题不能再loop函数下只执行一次,未解决.
    Blockly.Arduino['wlb_readMPU6050Output'] = function (block) {
        let MPU6050 = Blockly.Arduino.valueToCode(block, 'MPU6050', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.includes_['MPU6050.h'] = '#include "MPU6050.h"';
        Blockly.Arduino.definitions_['MPU6050'] = 'MPU6050 mpu6050;\n'
        Blockly.Arduino.setups_['mpu_begin'] = 'mpu6050.begin();\n'
        Blockly.Arduino.loops_['mpu6050.update()'] = 'mpu6050.update();'
        return ['mpu6050.getAngle('+MPU6050+')',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 微动开关
    Blockly.Arduino['wlb_pushMicroSwitch'] = function (block) {
        let MICRO_PIN = Blockly.Arduino.valueToCode(block, 'MICRO_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['pin_mode'] = 'pinMode('+MICRO_PIN+', INPUT);\n'
        return ['digitalRead('+MICRO_PIN+')',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 按钮开关
    Blockly.Arduino['wlb_pushButtonSwitch'] = function (block) {
        let BUTTON_PIN = Blockly.Arduino.valueToCode(block, 'BUTTON_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['pin_mode'] = 'pinMode('+BUTTON_PIN+', INPUT);\n'
        return ['digitalRead('+BUTTON_PIN+')',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 巡线开关
    Blockly.Arduino['wlb_touchLinePatrolSwitch'] = function (block) {
        let LINE_PIN = Blockly.Arduino.valueToCode(block, 'LINE_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['pin_mode'] = 'pinMode('+LINE_PIN+', INPUT);\n'
        return ['digitalRead('+LINE_PIN+')',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 红外避障
    Blockly.Arduino['wlb_touchInfraredSwitch'] = function (block) {
        let INFRARED_PIN = Blockly.Arduino.valueToCode(block, 'INFRARED_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['pin_mode'] = 'pinMode('+INFRARED_PIN+', INPUT);\n'
        return ['digitalRead('+INFRARED_PIN+')',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 设置led状态
    Blockly.Arduino['wlb_setLEDState'] = function (block) {
        let LED_PIN = Blockly.Arduino.valueToCode(block, 'LED_PIN', Blockly.Arduino.ORDER_ATOMIC);
        let LEVEL = Blockly.Arduino.valueToCode(block, 'LEVEL', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['pin_mode'] = 'pinMode('+LED_PIN+', OUTPUT);\n'
        return 'digitalWrite(' + LED_PIN + ',' + LEVEL + ');\n'
    }
    // 电机转速
    Blockly.Arduino['wlb_setMotorSpeed'] = function (block) {
        let MOTOR = Blockly.Arduino.valueToCode(block, 'MOTOR', Blockly.Arduino.ORDER_ATOMIC);
        let TEXT = Blockly.Arduino.valueToCode(block, 'TEXT', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.definitions_['GK_Motor_Run'] =
            'void GK_Motor_Run(uint8_t pin1_dir, uint8_t pin2_pwm, int speed) {\n' +
            '  pinMode(pin1_dir, OUTPUT);\n' +
            '  pinMode(pin2_pwm, OUTPUT);\n' +
            '  speed = speed > 255 ? 255 : speed;\n' +
            '  speed = speed < -255 ? -255 : speed;\n' +
            '  if (speed >= 0) {\n' +
            '    digitalWrite(pin1_dir, HIGH);\n' +
            '    analogWrite(pin2_pwm, 255 - speed);\n' +
            '  } else {\n' +
            '    digitalWrite(pin1_dir, LOW);\n' +
            '    analogWrite(pin2_pwm, -speed);\n' +
            '  }\n'+
            '}'
        return 'GK_Motor_Run(' + MOTOR + ',' + TEXT + ');\n'
    }
    // RGB灯颜色
    Blockly.Arduino['wlb_setRGBLightColor'] = function (block) {
        let RGB_PIN =Blockly.Arduino.valueToCode(block, 'RGB_PIN', Blockly.Arduino.ORDER_ATOMIC);
        let NUM =Blockly.Arduino.valueToCode(block, 'NUM', Blockly.Arduino.ORDER_ATOMIC),
            color =Blockly.Python.valueToCode(block, 'COLOR', Blockly.Arduino.ORDER_ATOMIC);
        let rgb = colorHexToRGB(replaceQuotationMarks(color))
        rgb = rgb.substring(1, rgb.length-1).split(',')
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.includes_['RGB_WS2812.h'] = '#include "RGB_WS2812.h"';
        Blockly.Arduino.definitions_['MPU6050'] = 'RGB_WS2812 rgb_ws2812_'+RGB_PIN+'('+RGB_PIN+');\n'
        return 'rgb_ws2812_'+RGB_PIN+'.setColorAt('+NUM+','+rgb[0]+','+rgb[1]+','+rgb[2]+');\n'
    }
    // 电机转速
    Blockly.Arduino['wlb_setBuzzer'] = function (block) {
        let BUZZER_PIN = Blockly.Arduino.valueToCode(block, 'BUZZER_PIN', Blockly.Arduino.ORDER_ATOMIC);
        let BUZZER_FREQ = Blockly.Arduino.valueToCode(block, 'BUZZER_FREQ', Blockly.Arduino.ORDER_ATOMIC);
        let BUZZER_TIME = Blockly.Arduino.valueToCode(block, 'BUZZER_TIME', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.definitions_['Tone'] =
            'void _Tone(int pin, uint16_t frequency, uint32_t duration) {\n' +
            '  int period = 1000000L / frequency;\n' +
            '  int pulse = period / 2;\n' +
            '  pinMode(pin, OUTPUT);\n' +
            '  for (uint32_t i = 0; i < duration * 1000L; i += period) {\n' +
            '    digitalWrite(pin, HIGH);\n' +
            '    delayMicroseconds(pulse);\n' +
            '    digitalWrite(pin, LOW);\n' +
            '    delayMicroseconds(pulse);\n' +
            '  }\n'+
            '}'
        return '_Tone(' + BUZZER_PIN + ',' + BUZZER_FREQ + ',' + BUZZER_TIME + ');\n'
    }
    // 初始化管脚红外接收
    Blockly.Arduino['wlb_setInfraredReceive'] = function (block) {
        let INFRARED_PIN =Blockly.Arduino.valueToCode(block, 'INFRARED_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.includes_['IRremote.h'] = '#include "IRremote.h"';
        Blockly.Arduino.definitions_['IRrecv'] = 'IRrecv irrecv('+INFRARED_PIN+');\n'
        Blockly.Arduino.setups_['enableIRIn'] = 'irrecv.enableIRIn();\n'
        return ''
    }
    // 红外遥控
    //此处问题同MPU6065一样,未解决 //2021/6/10 解决
    Blockly.Arduino['wlb_touchInfraredButton'] = function (block) {
        let INFRARED_BUTTON = Blockly.Arduino.valueToCode(block, 'INFRARED_BUTTON', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.loops_['key = 0'] = 'key = 0;'
        Blockly.Arduino.definitions_['IR_key'] ='decode_results results;\n' +
            'unsigned long key;\n' +
            'void IR_key() {\n' +
            '  static double ir_LastTime;\n' +
            '  if (irrecv.decode(&results)) {\n' +
            '    ir_LastTime = millis();\n' +
            '    if (results.value != 4294967295) {\n' +
            '      key = results.value;\n' +
            '    }\n' +
            '    irrecv.resume();\n' +
            '  } else if ((millis() - ir_LastTime) >= 180) {\n' +
            '    key = 0;\n' +
            '  }\n'+
            '}'
        Blockly.Arduino.loops_['IR_key'] = 'IR_key();'
        return ['key == '+INFRARED_BUTTON, Blockly.Arduino.ORDER_ATOMIC]
    }
    // 舵机角度
    Blockly.Arduino['wlb_setServoAngle'] = function (block) {
        let SERVO_PIN =Blockly.Arduino.valueToCode(block, 'SERVO_PIN', Blockly.Arduino.ORDER_ATOMIC);
        let ANGLE =Blockly.Arduino.valueToCode(block, 'ANGLE', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.includes_['Servo.h'] = '#include <Servo.h>';
        Blockly.Arduino.definitions_['servo_pin;'] = 'Servo servo_'+SERVO_PIN+';\n'
        Blockly.Arduino.setups_['attach'] = 'servo_'+SERVO_PIN+'.attach('+SERVO_PIN+');\n'
        return 'servo_'+SERVO_PIN+'.write('+ANGLE+');\n'

    }
    // 数码管型号
    Blockly.Arduino['wlb_setDigitronModel'] = function (block) {
        let DIGITRON_MODEL =Blockly.Arduino.valueToCode(block, 'DIGITRON_MODEL', Blockly.Arduino.ORDER_ATOMIC);
        let DIO_PIN =Blockly.Arduino.valueToCode(block, 'DIO_PIN', Blockly.Arduino.ORDER_ATOMIC);
        let CLK_PIN =Blockly.Arduino.valueToCode(block, 'CLK_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.includes_['TM16XX.h'] = '#include "TM16XX.h"';
        Blockly.Arduino.definitions_['TM16XX_Digitron;'] = 'TM16XX Digitron('+DIGITRON_MODEL+','+DIO_PIN+','+CLK_PIN+');\n'
        return ''
    }
    //数码管状态
    Blockly.Arduino['wlb_setDigitronState'] = function (block) {
        let DIGITRON_STATE =Blockly.Arduino.valueToCode(block, 'DIGITRON_STATE', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'Digitron.setMode('+DIGITRON_STATE+');\n'

    }
    //数码管亮度
    Blockly.Arduino['wlb_setDigitronLight'] = function (block) {
        let DIGITRON_LIGHT =Blockly.Arduino.valueToCode(block, 'DIGITRON_LIGHT', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'Digitron.setBrightness('+DIGITRON_LIGHT+');\n'
    }
    //数码管显示
    Blockly.Arduino['wlb_setDigitronDisplay'] = function (block) {
        let DIGITRON_DISPLAY =Blockly.Arduino.valueToCode(block, 'DIGITRON_DISPLAY', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'Digitron.display('+DIGITRON_DISPLAY+');\n'
    }
    //数码管位数显示对应值
    Blockly.Arduino['wlb_setDigitronDigit'] = function (block) {
        let DIGITRON_DIGIT =Blockly.Arduino.valueToCode(block, 'DIGITRON_DIGIT', Blockly.Arduino.ORDER_ATOMIC);
        let DIGITRON_DISPLAY =Blockly.Arduino.valueToCode(block, 'DIGITRON_DISPLAY', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'Digitron.display('+DIGITRON_DIGIT+','+DIGITRON_DISPLAY+');\n'
    }
    //数码管位数显示对应段位值
    Blockly.Arduino['wlb_setDigitronRank'] = function (block) {
        let DIGITRON_DIGIT =Blockly.Arduino.valueToCode(block, 'DIGITRON_DIGIT', Blockly.Arduino.ORDER_ATOMIC);
        let DIGITRON_RANK =Blockly.Arduino.valueToCode(block, 'DIGITRON_RANK', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'Digitron.display('+DIGITRON_DIGIT+','+DIGITRON_RANK+');\n'
    }
    //初始化1602地址
    Blockly.Arduino['wlb_set1602LCDAddress'] = function (block) {
        let LCD_ADDRESS =Blockly.Arduino.valueToCode(block, 'LCD_ADDRESS', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.includes_['LiquidCrystal_I2C.h'] = '#include "LiquidCrystal_I2C.h"';
        Blockly.Arduino.definitions_['I2C_LCD;'] = 'LiquidCrystal_I2C lcd('+LCD_ADDRESS+', 16, 2);\n'
        Blockly.Arduino.setups_['begin'] = 'lcd.begin();\n'
        return ''
    }
    //设置LCD状态
    Blockly.Arduino['wlb_setLCDState'] = function (block) {
        let LCD_STATE =Blockly.Arduino.valueToCode(block, 'LCD_STATE', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'lcd.'+LCD_STATE+'();\n'
    }
    //设置LCD显示位置
    Blockly.Arduino['wlb_setLCDAddressDisplay'] = function (block) {
        let LCD_ADDRESS_X =Blockly.Arduino.valueToCode(block, 'LCD_ADDRESS_X', Blockly.Arduino.ORDER_ATOMIC);
        let LCD_ADDRESS_Y =Blockly.Arduino.valueToCode(block, 'LCD_ADDRESS_Y', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'lcd.setCursor('+LCD_ADDRESS_Y+','+LCD_ADDRESS_X+');\n'
    }
    //设置LCD显示内容
    Blockly.Arduino['wlb_setLCDDisplay'] = function (block) {
        let LCD_DISPLAY =Blockly.Arduino.valueToCode(block, 'LCD_DISPLAY', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'lcd.write('+LCD_DISPLAY+');\n'
    }
    //设置LCD打印
    Blockly.Arduino['wlb_setLCDPrint'] = function (block) {
        let LCD_PRINT =Blockly.Arduino.valueToCode(block, 'LCD_PRINT', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'lcd.print('+LCD_PRINT+');\n'
    }
    // 声明变量
    Blockly.Arduino['wlb_setVariableType'] = function (block) {
        let VARIABLE_TYPE =Blockly.Arduino.valueToCode(block, 'VARIABLE_TYPE', Blockly.Arduino.ORDER_ATOMIC);
        let NAME =Blockly.Arduino.valueToCode(block, 'NAME', Blockly.Arduino.ORDER_ATOMIC);
        return VARIABLE_TYPE+' '+NAME+';\n';
    }
    // 给变量赋值
    Blockly.Arduino['wlb_setVariableValue'] = function (block) {
        let NAME =Blockly.Arduino.valueToCode(block, 'NAME', Blockly.Arduino.ORDER_ATOMIC),
            VARIABLE_OPERATOR =Blockly.Arduino.valueToCode(block, 'VARIABLE_OPERATOR', Blockly.Arduino.ORDER_ATOMIC),
            VALUE =Blockly.Arduino.valueToCode(block, 'VALUE', Blockly.Arduino.ORDER_ATOMIC);
        return NAME+' '+VARIABLE_OPERATOR+ ' '+VALUE+';\n';
    }

    // 获取变量
    Blockly.Arduino['wlb_getVariable'] = function (block) {
        return [Blockly.Arduino.valueToCode(block, 'NAME', Blockly.Arduino.ORDER_ATOMIC), Blockly.Arduino.ORDER_ATOMIC];
    }
    // 串口打印自动换行
    Blockly.Arduino['wlb_serialPrintln'] = function (block) {
        let text = Blockly.Arduino.valueToCode(block, 'TEXT', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.setups_['serial_init'] = 'Serial.begin(9600);'
        return `Serial.println(`+text+`);\n`
    }
}
