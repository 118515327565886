import React from 'react';
import {FormattedMessage} from 'react-intl';

import musicIconURL from './music/music.png';
import musicInsetIconURL from './music/music-small.svg';

import penIconURL from './pen/pen.png';
import penInsetIconURL from './pen/pen-small.svg';

import videoSensingIconURL from './videoSensing/video-sensing.png';
import videoSensingInsetIconURL from './videoSensing/video-sensing-small.svg';

import text2speechIconURL from './text2speech/text2speech.png';
import text2speechInsetIconURL from './text2speech/text2speech-small.svg';

import translateIconURL from './translate/translate.png';
import translateInsetIconURL from './translate/translate-small.png';

import makeymakeyIconURL from './makeymakey/makeymakey.png';
import makeymakeyInsetIconURL from './makeymakey/makeymakey-small.svg';

import microbitIconURL from './microbit/microbit.png';
import microbitInsetIconURL from './microbit/microbit-small.svg';
import microbitConnectionIconURL from './microbit/microbit-illustration.svg';
import microbitConnectionSmallIconURL from './microbit/microbit-small.svg';

import ev3IconURL from './ev3/ev3.png';
import ev3InsetIconURL from './ev3/ev3-small.svg';
import ev3ConnectionIconURL from './ev3/ev3-hub-illustration.svg';
import ev3ConnectionSmallIconURL from './ev3/ev3-small.svg';

import wedo2IconURL from './wedo2/wedo.png'; // TODO: Rename file names to match variable/prop names?
import wedo2InsetIconURL from './wedo2/wedo-small.svg';
import wedo2ConnectionIconURL from './wedo2/wedo-illustration.svg';
import wedo2ConnectionSmallIconURL from './wedo2/wedo-small.svg';
import wedo2ConnectionTipIconURL from './wedo2/wedo-button-illustration.svg';

import boostIconURL from './boost/boost.png';
import boostInsetIconURL from './boost/boost-small.svg';
import boostConnectionIconURL from './boost/boost-illustration.svg';
import boostConnectionSmallIconURL from './boost/boost-small.svg';
import boostConnectionTipIconURL from './boost/boost-button-illustration.svg';

import gdxforIconURL from './gdxfor/gdxfor.png';
import gdxforInsetIconURL from './gdxfor/gdxfor-small.svg';
import gdxforConnectionIconURL from './gdxfor/gdxfor-illustration.svg';
import gdxforConnectionSmallIconURL from './gdxfor/gdxfor-small.svg';

import faceApiImage from './faceApi/faceApi.jpg';
import faceApiInsetImage from './faceApi/faceApi-small.svg';

import nlpImage from './nlp/nlp.jpg';
import nlpInsetImage from './nlp/nlp-small.svg'

import imageIdentification from './imageIdentification/imageIdentification.jpg'
import imageIdentificationInsetImage from './imageIdentification/imageIdentification-small.svg'

import scbImage from './scb/scb.jpg'
import scbInsetImage from './scb/scb-small.svg'

import voiceTechnologyImage from './voiceTechnology/voiceTechnology.jpg'
import voiceTechnologyInsetImage from './voiceTechnology/voiceTechnology.svg'

import imageProcessingImage from './imageProcessing/imageProcessing.jpg'
import imageProcessingInsetImage from './imageProcessing/imageProcessing.svg'

import IoTbImage from './IoTb/IoTb.jpg'
import IoTbInsetImage from './IoTb/IoTb-small.svg'

import AiPBoxImage from './AiPBox/AiPBox.jpg'
import AiPBoxInsetImage from './AiPBox/AiPBox-small.svg'

import AiMBoxImage from './AiMBox/AiMBox.jpg'
import AiMBoxInsetImage from './AiMBox/AiMBox-small.svg'

import AiGBoxImage from './AiGBox/AiGBox.jpg'
import AiGBoxInsetImage from './AiGBox/AiGBox-small.svg'

import wybImage from './wyb/wyb.jpg'
import wybInsetImage from './wyb/wyb-small.svg'
import wlbImage from './wlb/wlb.jpg'
import wlbInsetImage from './wlb/wlb-small.svg'
import ckbImage from './ckb/ckb.jpg'
import ckbInsetImage from './ckb/ckb-small.svg'
import k210Image from './k210/k210.jpg'
import k210InsetImage from './k210/k210-small.svg'
import MarsRoverImage from './MarsRover/MarsRover.png'

export default [
	{
        name: (
            <FormattedMessage
                defaultMessage="SCBoard"
                description="Science and technology board operation related modules"
                id="gui.extension.scb.name"
            />
        ),
        extensionId: 'scb',
        iconURL: scbImage,
        // insetIconURL: scbInsetImage,
        description: (
            <FormattedMessage
                defaultMessage="Science and technology board operation related modules"
                description="Science and technology board operation related modules"
                id="gui.extension.scb.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="IoTBoard"
                description="Internet of things board operation related modules"
                id="gui.extension.IoTb.name"
            />
        ),
        extensionId: 'IoTb',
        iconURL: IoTbImage,
        // insetIconURL: IoTbInsetImage,
        description: (
            <FormattedMessage
                defaultMessage="Internet of things board operation related modules"
                description="Internet of things board operation related modules"
                id="gui.extension.IoTb.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="wyb"
                description="Artificial Intelligence Intermediate Experiment Box"
                id="gui.extension.wyb.name"
            />
        ),
        extensionId: 'wyb',
        iconURL: wybImage,
        // insetIconURL: wybInsetImage,
        description: (
            <FormattedMessage
                defaultMessage="Artificial Intelligence Intermediate Experiment Box"
                description="Artificial Intelligence Intermediate Experiment Box"
                id="gui.extension.wyb.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="wlb"
                description="Artificial Intelligence Intermediate Experiment Box"
                id="gui.extension.wlb.name"
            />
        ),
        extensionId: 'wlb',
        iconURL: wlbImage,
        // insetIconURL: wlbInsetImage,
        description: (
            <FormattedMessage
                defaultMessage="Artificial Intelligence Intermediate Experiment Box"
                description="Artificial Intelligence Intermediate Experiment Box"
                id="gui.extension.wlb.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="ckb"
                description="Artificial Intelligence Intermediate Experiment Box"
                id="gui.extension.ckb.name"
            />
        ),
        extensionId: 'ckb',
        iconURL: ckbImage,
        // insetIconURL: ckbInsetImage,
        description: (
            <FormattedMessage
                defaultMessage="Artificial Intelligence Intermediate Experiment Box"
                description="Artificial Intelligence Intermediate Experiment Box"
                id="gui.extension.ckb.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="AiPBox"
                description="Artificial Intelligence Primary Experiment Box"
                id="gui.extension.AiPBox.name"
            />
        ),
        extensionId: 'AiPBox',
        iconURL: AiPBoxImage,
        // insetIconURL: AiPBoxInsetImage,
        description: (
            <FormattedMessage
                defaultMessage="Artificial Intelligence Primary Experiment Box"
                description="Artificial Intelligence Primary Experiment Box"
                id="gui.extension.AiPBox.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="AiMBox"
                description="Artificial Intelligence Intermediate Experiment Box"
                id="gui.extension.AiMBox.name"
            />
        ),
        extensionId: 'AiMBox',
        iconURL: AiMBoxImage,
        // insetIconURL: AiMBoxInsetImage,
        description: (
            <FormattedMessage
                defaultMessage="Artificial Intelligence Intermediate Experiment Box"
                description="Artificial Intelligence Intermediate Experiment Box"
                id="gui.extension.AiMBox.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="AiGBox"
                description="Artificial Intelligence Intermediate Experiment Box"
                id="gui.extension.AiGBox.name"
            />
        ),
        extensionId: 'AiGBox',
        iconURL: AiGBoxImage,
        // insetIconURL: AiGBoxInsetImage,
        description: (
            <FormattedMessage
                defaultMessage="Artificial Intelligence Intermediate Experiment Box"
                description="Artificial Intelligence Intermediate Experiment Box"
                id="gui.extension.AiGBox.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="k210"
                description="Artificial Intelligence Intermediate Experiment Box"
                id="gui.extension.k210.name"
            />
        ),
        extensionId: 'k210',
        iconURL: k210Image,
        // insetIconURL: k210InsetImage,
        description: (
            <FormattedMessage
                defaultMessage="Artificial Intelligence Intermediate Experiment Box"
                description="Artificial Intelligence Intermediate Experiment Box"
                id="gui.extension.k210.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="MarsRover"
                description="Artificial Intelligence Intermediate Experiment Box"
                id="gui.extension.MarsRover.name"
            />
        ),
        extensionId: 'MarsRover',
        iconURL: MarsRoverImage,
        // insetIconURL: k210InsetImage,
        description: (
            <FormattedMessage
                defaultMessage="Artificial Intelligence Intermediate Experiment Box"
                description="Artificial Intelligence Intermediate Experiment Box"
                id="gui.extension.MarsRover.description"
            />
        ),
        featured: true
    },
	{
        name: (
            <FormattedMessage
                defaultMessage="Face recognition"
                description="Recognize face data in images for processing"
                id="gui.extension.faceApi.name"
            />
        ),
        extensionId: 'faceApi',
        iconURL: faceApiImage,
        // insetIconURL: faceApiInsetImage,
        description: (
            <FormattedMessage
                defaultMessage="A series of related technologies for detecting human faces in images and performing facial recognition on them."
                description="A series of related technologies for detecting human faces in images and performing facial recognition on them."
                id="gui.extension.faceApi.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Image identification"
                description="Recognize image data in images for processing"
                id="gui.extension.imageIdentification.name"
            />
        ),
        extensionId: 'imageIdentification',
        iconURL: imageIdentification,
        // insetIconURL: imageIdentificationInsetImage,
        description: (
            <FormattedMessage
                defaultMessage="Detect and recognize text or objects in images."
                description="Detect and recognize text or objects in images."
                id="gui.extension.imageIdentification.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true
    },
	{
        name: (
            <FormattedMessage
                defaultMessage="NLP"
                description="Natural language processing"
                id="gui.extension.nlp.name"
            />
        ),
        extensionId: 'nlp',
        iconURL: nlpImage,
        // insetIconURL: nlpInsetImage,
        description: (
            <FormattedMessage
                defaultMessage="Perform lexical, emotional, similarity analysis or error correction on the text."
                description="Perform lexical, emotional, similarity analysis or error correction on the text."
                id="gui.extension.nlp.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true    },
    {
        name: (
            <FormattedMessage
                defaultMessage="imageProcessing"
                description="Science and technology board operation related modules"
                id="gui.extension.imageProcessing.name"
            />
        ),
        extensionId: 'imageProcessing',
        iconURL: imageProcessingImage,
        // insetIconURL: imageProcessingInsetImage,
        description: (
            <FormattedMessage
                defaultMessage="Process the uploaded image"
                description="Process the uploaded image"
                id="gui.extension.imageProcessing.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="voice technology"
                description="A range of voice technologies"
                id="gui.extension.voiceTechnology.name"
            />
        ),
        extensionId: 'voiceTechnology',
        iconURL: voiceTechnologyImage,
        // insetIconURL: voiceTechnologyInsetImage,
        description: (
            <FormattedMessage
                defaultMessage="A range of voice technologies"
                description="A range of voice technologies"
                id="gui.extension.voiceTechnology.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Music"
                description="Name for the 'Music' extension"
                id="gui.extension.music.name"
            />
        ),
        extensionId: 'music',
        iconURL: musicIconURL,
        // insetIconURL: musicInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Play instruments and drums."
                description="Description for the 'Music' extension"
                id="gui.extension.music.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Pen"
                description="Name for the 'Pen' extension"
                id="gui.extension.pen.name"
            />
        ),
        extensionId: 'pen',
        iconURL: penIconURL,
        // insetIconURL: penInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Draw with your sprites."
                description="Description for the 'Pen' extension"
                id="gui.extension.pen.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Video Sensing"
                description="Name for the 'Video Sensing' extension"
                id="gui.extension.videosensing.name"
            />
        ),
        extensionId: 'videoSensing',
        iconURL: videoSensingIconURL,
        // insetIconURL: videoSensingInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense motion with the camera."
                description="Description for the 'Video Sensing' extension"
                id="gui.extension.videosensing.description"
            />
        ),
        featured: true
    },
    // {
    //     name: (
    //         <FormattedMessage
    //             defaultMessage="Text to Speech"
    //             description="Name for the Text to Speech extension"
    //             id="gui.extension.text2speech.name"
    //         />
    //     ),
    //     extensionId: 'text2speech',
    //     collaborator: 'Amazon Web Services',
    //     iconURL: text2speechIconURL,
    //     insetIconURL: text2speechInsetIconURL,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="Make your projects talk."
    //             description="Description for the Text to speech extension"
    //             id="gui.extension.text2speech.description"
    //         />
    //     ),
    //     featured: true,
    //     internetConnectionRequired: true
    // },
    // {
    //     name: (
    //         <FormattedMessage
    //             defaultMessage="Translate"
    //             description="Name for the Translate extension"
    //             id="gui.extension.translate.name"
    //         />
    //     ),
    //     extensionId: 'translate',
    //     collaborator: 'Google',
    //     iconURL: translateIconURL,
    //     insetIconURL: translateInsetIconURL,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="Translate text into many languages."
    //             description="Description for the Translate extension"
    //             id="gui.extension.translate.description"
    //         />
    //     ),
    //     featured: true,
    //     internetConnectionRequired: true
    // },
    // {
    //     name: 'Makey Makey',
    //     extensionId: 'makeymakey',
    //     collaborator: 'JoyLabz',
    //     iconURL: makeymakeyIconURL,
    //     insetIconURL: makeymakeyInsetIconURL,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="Make anything into a key."
    //             description="Description for the 'Makey Makey' extension"
    //             id="gui.extension.makeymakey.description"
    //         />
    //     ),
    //     featured: true
    // },
    {
        name: 'micro:bit',
        extensionId: 'microbit',
        collaborator: 'micro:bit',
        iconURL: microbitIconURL,
        //insetIconURL: microbitInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Connect your projects with the world."
                description="Description for the 'micro:bit' extension"
                id="gui.extension.microbit.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: microbitConnectionIconURL,
        connectionSmallIconURL: microbitConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their micro:bit."
                id="gui.extension.microbit.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/microbit'
    },

    // {
    //     name: 'LEGO MINDSTORMS EV3',
    //     extensionId: 'ev3',
    //     collaborator: 'LEGO',
    //     iconURL: ev3IconURL,
    //     insetIconURL: ev3InsetIconURL,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="Build interactive robots and more."
    //             description="Description for the 'LEGO MINDSTORMS EV3' extension"
    //             id="gui.extension.ev3.description"
    //         />
    //     ),
    //     featured: true,
    //     disabled: false,
    //     bluetoothRequired: true,
    //     internetConnectionRequired: true,
    //     launchPeripheralConnectionFlow: true,
    //     useAutoScan: false,
    //     connectionIconURL: ev3ConnectionIconURL,
    //     connectionSmallIconURL: ev3ConnectionSmallIconURL,
    //     connectingMessage: (
    //         <FormattedMessage
    //             defaultMessage="Connecting. Make sure the pin on your EV3 is set to 1234."
    //             description="Message to help people connect to their EV3. Must note the PIN should be 1234."
    //             id="gui.extension.ev3.connectingMessage"
    //         />
    //     ),
    //     helpLink: 'https://scratch.mit.edu/ev3'
    // },
    // {
    //     name: 'LEGO BOOST',
    //     extensionId: 'boost',
    //     collaborator: 'LEGO',
    //     iconURL: boostIconURL,
    //     insetIconURL: boostInsetIconURL,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="Bring robotic creations to life."
    //             description="Description for the 'LEGO BOOST' extension"
    //             id="gui.extension.boost.description"
    //         />
    //     ),
    //     featured: true,
    //     disabled: false,
    //     bluetoothRequired: true,
    //     internetConnectionRequired: true,
    //     launchPeripheralConnectionFlow: true,
    //     useAutoScan: true,
    //     connectionIconURL: boostConnectionIconURL,
    //     connectionSmallIconURL: boostConnectionSmallIconURL,
    //     connectionTipIconURL: boostConnectionTipIconURL,
    //     connectingMessage: (
    //         <FormattedMessage
    //             defaultMessage="Connecting"
    //             description="Message to help people connect to their BOOST."
    //             id="gui.extension.boost.connectingMessage"
    //         />
    //     ),
    //     helpLink: 'https://scratch.mit.edu/boost'
    // },
    // {
    //     name: 'LEGO Education WeDo 2.0',
    //     extensionId: 'wedo2',
    //     collaborator: 'LEGO',
    //     iconURL: wedo2IconURL,
    //     insetIconURL: wedo2InsetIconURL,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="Build with motors and sensors."
    //             description="Description for the 'LEGO WeDo 2.0' extension"
    //             id="gui.extension.wedo2.description"
    //         />
    //     ),
    //     featured: true,
    //     disabled: false,
    //     bluetoothRequired: true,
    //     internetConnectionRequired: true,
    //     launchPeripheralConnectionFlow: true,
    //     useAutoScan: true,
    //     connectionIconURL: wedo2ConnectionIconURL,
    //     connectionSmallIconURL: wedo2ConnectionSmallIconURL,
    //     connectionTipIconURL: wedo2ConnectionTipIconURL,
    //     connectingMessage: (
    //         <FormattedMessage
    //             defaultMessage="Connecting"
    //             description="Message to help people connect to their WeDo."
    //             id="gui.extension.wedo2.connectingMessage"
    //         />
    //     ),
    //     helpLink: 'https://scratch.mit.edu/wedo'
    // },
    // {
    //     name: 'Go Direct Force & Acceleration',
    //     extensionId: 'gdxfor',
    //     collaborator: 'Vernier',
    //     iconURL: gdxforIconURL,
    //     insetIconURL: gdxforInsetIconURL,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="Sense push, pull, motion, and spin."
    //             description="Description for the Vernier Go Direct Force and Acceleration sensor extension"
    //             id="gui.extension.gdxfor.description"
    //         />
    //     ),
    //     featured: true,
    //     disabled: false,
    //     bluetoothRequired: true,
    //     internetConnectionRequired: true,
    //     launchPeripheralConnectionFlow: true,
    //     useAutoScan: false,
    //     connectionIconURL: gdxforConnectionIconURL,
    //     connectionSmallIconURL: gdxforConnectionSmallIconURL,
    //     connectingMessage: (
    //         <FormattedMessage
    //             defaultMessage="Connecting"
    //             description="Message to help people connect to their force and acceleration sensor."
    //             id="gui.extension.gdxfor.connectingMessage"
    //         />
    //     ),
    //     helpLink: 'https://scratch.mit.edu/vernier'
    // }
];
