import classNames from 'classnames';
import {connect} from 'react-redux';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import Box from '../box/box.jsx';
import ReactDOM from "react-dom";
import styles from './code-console.css';

class CodeConsole extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <Box className={styles.consoleDiv}>
                <div className={classNames(styles.codePadding)}>
                    <div className={classNames(styles.consoleBody)} id={'code_console'}/>
                </div>
            </Box>
        );
    }
}


export default CodeConsole;
