/*
* author : wlc
* time : 2020-6-16
* */
import {regQuotationMarks,replaceQuotationMarks, colorHexToRGB} from '../common.js'
String.prototype.colorRgb = function () {
    // 16进制颜色值的正则
    var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
    // 把颜色值变成小写
    var color = this.toLowerCase();
    if (reg.test(color)) {
        // 如果只有三位的值，需变成六位，如：#fff => #ffffff
        if (color.length === 4) {
            var colorNew = "#";
            for (var i = 1; i < 4; i += 1) {
                colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
            }
            color = colorNew;
        }
        // 处理六位的颜色值，转为RGB
        var colorChange = [];
        for (var i = 1; i < 7; i += 2) {
            colorChange.push(parseInt("0x" + color.slice(i, i + 2)));
        }
        return "(" + colorChange.join(",") + ")";
    } else {
        return color;
    }
};
export default function (Blockly) {

    // 打印
    Blockly.Python['scb_printText'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        return 'print(' + TEXT + ')\n';
    };



    // interface 下拉框
    Blockly.Python['scb_menu_INTERFACE_MENU'] = function(block) {
        let Interface = block.getFieldValue('INTERFACE_MENU');
        return [Interface];
    }
    // 模拟输出下拉框
    Blockly.Python['scb_menu_ANALOG_OUTPUT_PIN_MENU'] = function(block){
        let ANALOG_OUTPUT_PIN = block.getFieldValue('ANALOG_OUTPUT_PIN_MENU');
        return [ANALOG_OUTPUT_PIN];
    }
    // 模拟输入管脚号 下拉框
    Blockly.Python['scb_menu_ADC_RECEIVE_PIN_MENU'] = function(block) {
        let ADC = block.getFieldValue('ADC_RECEIVE_PIN_MENU');
        return [ADC];
    }
    // PWM管脚号 下拉框
    Blockly.Python['scb_menu_PWM_ANALOG_PIN_MENU'] = function(block) {
        let PWN_ANALOG_PIN = block.getFieldValue('PWM_ANALOG_PIN_MENU');
        return [PWN_ANALOG_PIN];
    }
    // RGB管脚号 下拉框
    Blockly.Python['scb_menu_RGB_PIN_MENU'] = function(block) {
        let RGB_PIN = block.getFieldValue('RGB_PIN_MENU');
        return [RGB_PIN];
    }

    // level下拉框
    Blockly.Python['scb_menu_LEVEL_MENU'] = function(block) {
        let level = block.getFieldValue('LEVEL_MENU');
        return [level];
    }

    // DHT下拉框
    Blockly.Python['scb_menu_DHT_MENU'] = function(block) {
        let DHT = block.getFieldValue('DHT_MENU');
        return [DHT];
    }

    // 灯颜色按钮下拉框
    Blockly.Python['scb_menu_SWITCH_MENU'] = function(block) {
        let sw = block.getFieldValue('SWITCH_MENU');
        return [sw];
    }

    // 音乐下拉框
    Blockly.Python['scb_menu_MUSIC_MENU'] = function(block) {
        let music = block.getFieldValue('MUSIC_MENU');
        return [music];
    }
    // 按钮下拉框
    Blockly.Python['scb_menu_BUTTON_MENU'] = function(block) {
        let button = block.getFieldValue('BUTTON_MENU');
        return [button];
    }
    // 三轴选择下拉框
    Blockly.Python['scb_menu_THREEAXIS_MENU'] = function(block) {
        let thr = block.getFieldValue('THREEAXIS_MENU');
        return [thr];
    }
    // 血氧获取数据下拉框
    Blockly.Python['scb_menu_HEARTBLOOD_MENU'] = function(block) {
        let hert = block.getFieldValue('HEARTBLOOD_MENU');
        return [hert];
    }
    // 红外接收引脚下拉框
    Blockly.Python['scb_menu_INFRARED_SEND_PIN_MENU'] = function(block) {
        let inf = block.getFieldValue('INFRARED_SEND_PIN_MENU');
        return [inf];
    }
    // 红外发送引脚下拉框
    Blockly.Python['scb_menu_INFRARED_RECEIVE_PIN_MENU'] = function(block) {
        let inf = block.getFieldValue('INFRARED_RECEIVE_PIN_MENU');
        return [inf];
    }
    // 红外发送引脚下拉框
    Blockly.Python['scb_menu_INFRARED_RECEIVE_DATA_TYPE_MENU'] = function(block) {
        let inf = block.getFieldValue('INFRARED_RECEIVE_DATA_TYPE_MENU');
        return [inf];
    }
    // wlan模式下拉框
    Blockly.Python['scb_menu_WLAN_IF_MENU'] = function(block) {
        let wlan = block.getFieldValue('WLAN_IF_MENU');
        return [wlan];
    }
    // wlan状态下拉框
    Blockly.Python['scb_menu_WLAN_ACTIVE_MENU'] = function(block) {
        let wlan = block.getFieldValue('WLAN_ACTIVE_MENU');
        return [wlan];
    }
    // wlan信息下拉框
    Blockly.Python['scb_menu_WLAN_WIFIINFO_MENU'] = function(block) {
        let wlan = block.getFieldValue('WLAN_WIFIINFO_MENU');
        return [wlan];
    }
    // wlan信息下拉框
    Blockly.Python['scb_menu_WIFIINFO_MENU'] = function(block) {
        let wlan = block.getFieldValue('WIFIINFO_MENU');
        return [wlan];
    }
    // wlan设置下拉框
    Blockly.Python['scb_menu_WLANWIFICONFIG_MENU'] = function(block) {
        let wlan = block.getFieldValue('WLANWIFICONFIG_MENU');
        return [wlan];
    }
    // 连接类型下拉框
    Blockly.Python['scb_menu_SOCKTYPE_MENU'] = function(block) {
        let wlan = block.getFieldValue('SOCKTYPE_MENU');
        return [wlan];
    }
    // 选择屏幕显示下拉框
    Blockly.Python['scb_menu_OLEDSL_MENU'] = function(block) {
        let wlan = block.getFieldValue('OLEDSL_MENU');
        return [wlan];
    }
    //  语音识别模块管脚
    Blockly.Python['scb_menu_UART_PIN_MENU'] = function(block){
        let UART_PIN = block.getFieldValue('UART_PIN_MENU');
        return [UART_PIN]
    }
    // 语音识别命令下拉框
    Blockly.Python['scb_menu_Speech_Recognition_MENU'] = function(block){
        let Recognition_CMD = block.getFieldValue('Speech_Recognition_MENU');
        return [Recognition_CMD]
    }
    // OLED显示类型下拉框
    Blockly.Python['scb_menu_OLED_SHOW_TYPE_MENU'] = function(block){
        let SHOW_TYPE = block.getFieldValue('OLED_SHOW_TYPE_MENU');
        return [SHOW_TYPE]
    }
    // OLED描点亮度下拉框
    Blockly.Python['scb_menu_OLED_LUMINANCE_MENU'] = function(block){
        let OLED_LUMINANCE = block.getFieldValue('OLED_LUMINANCE_MENU');
        return [OLED_LUMINANCE]
    }
    // OLED 动作下拉框
    Blockly.Python['scb_menu_OLED_ACTION_MENU'] = function(block){
        let OLED_ACTION = block.getFieldValue('OLED_ACTION_MENU');
        return [OLED_ACTION]
    }
    // OLED 方向下拉框
    Blockly.Python['scb_menu_OLED_DIRECTION_MENU'] = function(block){
        let OLED_DIRECTION = block.getFieldValue('OLED_DIRECTION_MENU');
        return [OLED_DIRECTION]
    }
    // OLED 矩形下拉框
    Blockly.Python['scb_menu_OLED_RECTANGLE_MENU'] = function(block){
        let OLED_RECTANGLE = block.getFieldValue('OLED_RECTANGLE_MENU');
        return [OLED_RECTANGLE]
    }
    // OLED 圆形下拉框
    Blockly.Python['scb_menu_OLED_CIRCULAR_MENU'] = function(block){
        let OLED_CIRCULAR = block.getFieldValue('OLED_CIRCULAR_MENU');
        return [OLED_CIRCULAR]
    }
    // OLED 三角形下拉框
    Blockly.Python['scb_menu_OLED_TRIANGLE_MENU'] = function(block){
        let OLED_TRIANGLE = block.getFieldValue('OLED_TRIANGLE_MENU');
        return [OLED_TRIANGLE]
    }
    // OLED 行号下拉框
    Blockly.Python['scb_menu_OLED_ROW_MENU'] = function(block){
        let OLED_ROW = block.getFieldValue('OLED_ROW_MENU');
        return [OLED_ROW]
    }
    // 运行时间下拉框
    Blockly.Python['scb_menu_RUN_TIME_MENU'] = function(block){
        let RUN_TIME = block.getFieldValue('RUN_TIME_MENU');
        return [RUN_TIME]
    }
    // 游戏模型下拉框
    Blockly.Python['scb_menu_GAME_MODEL_MENU'] = function(block){
        let GAME_MODEL = block.getFieldValue('GAME_MODEL_MENU');
        return [GAME_MODEL]
    }

    //设置数字输出
    Blockly.Python['scb_setDigitalOutput'] = function (block) {
        let INTERFACE = Blockly.Python.valueToCode(block, 'INTERFACE', Blockly.Python.ORDER_ATOMIC);
        let LEVEL = Blockly.Python.valueToCode(block, 'LEVEL', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return 'pin'+INTERFACE+' = machine.Pin('+INTERFACE+',machine.Pin.OUT)\n'+
            'pin'+INTERFACE+'.value('+LEVEL+')\n'
    }

    //读取数字量
    Blockly.Python['scb_loadDigitalOutput'] = function(block){
        let INTERFACE = Blockly.Python.valueToCode(block, 'INTERFACE', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let functionName = Blockly.Python.provideFunction_(
            'digitalRead', ['def '+ Blockly.Python.FUNCTION_NAME_PLACEHOLDER_+'(p):',
                '  pin0 = machine.Pin(p, machine.Pin.IN)',
                '  return pin0.value()']
        )
        let code = functionName+'('+INTERFACE+')'
        return [code]
    }

    //设置模拟输出
    Blockly.Python['scb_setAnalogOutput'] = function(block){
        let ANALOG_OUTPUT_PIN = Blockly.Python.valueToCode(block, 'ANALOG_OUTPUT_PIN', Blockly.Python.ORDER_ATOMIC);
        let NUM = Blockly.Python.valueToCode(block, 'NUM', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return'dac'+ANALOG_OUTPUT_PIN+' = machine.DAC(machine.Pin('+ANALOG_OUTPUT_PIN+'))\n' +
            'dac'+ANALOG_OUTPUT_PIN+'.write('+NUM+')\n'
    }

    //读取模拟量
    Blockly.Python['scb_loadAnalogOutput'] = function(block){
        let ADC = Blockly.Python.valueToCode(block, 'ADC', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let functionName = Blockly.Python.provideFunction_(
            'analogRead',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(p):',
                '  adc = machine.ADC(machine.Pin(p))',
                '  adc.atten(machine.ADC.ATTN_11DB)',
                '  return adc.read()']);
        let code = functionName+'('+ADC+')'
        return [code]
    }

    //读取光线传感器的值 36
    Blockly.Python['scb_loadLightSensorValue'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let functionName = Blockly.Python.provideFunction_(
            'lightSensorRead',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  adc36 = machine.ADC(machine.Pin(36))',
                '  adc36.atten(machine.ADC.ATTN_11DB)',
                '  return adc36.read()']);
        let code = functionName+'()'
        return [code]
    }

    //读取声音传感器的值 39
    Blockly.Python['scb_loadSoundSensorValue'] = function (block) {
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let functionName = Blockly.Python.provideFunction_(
            'soundSensorRead',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  adc39 = machine.ADC(machine.Pin(39))',
                '  adc39.atten(machine.ADC.ATTN_11DB)',
                '  return adc39.read()']);
        let code = functionName+'()'
        return [code]
    }

    //读取sht20温湿度传感器
    Blockly.Python['scb_loadTemperatureHumiditySensorValue'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_sht20'] = 'import dhtx';
        let INTERFACE = Blockly.Python.valueToCode(block, 'INTERFACE', Blockly.Python.ORDER_ATOMIC);
        let DHT = Blockly.Python.valueToCode(block, 'DHT');
        let code = 'dhtx.get_dht_'+DHT+'(\'dht11\', '+INTERFACE+')'
        return [code]
    }

    //播放音乐
    Blockly.Python['scb_playSound'] = function(block){
        Blockly.Python.definitions_['import_music'] = 'import music';
        let MUSIC = Blockly.Python.valueToCode(block, 'MUSIC', Blockly.Python.ORDER_ATOMIC);
        return 'music.play(music.'+MUSIC+', 25)\n'
    }
    //设置屏幕字母
    Blockly.Python['scb_setOLEDShowText'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC);
        let COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC);
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        return 'oled.text('+TEXT+','+COORDINATE_X+','+COORDINATE_Y+')\n'
    }
    //屏幕显示类型
    Blockly.Python['scb_setScreenShowType'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let SHOW_TYPE = Blockly.Python.valueToCode(block, 'SHOW_TYPE', Blockly.Python.ORDER_ATOMIC);
        return 'oled.'+SHOW_TYPE+' \n'
    }
    //设置描点
    Blockly.Python['scb_setScreenDrawDot'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC);
        let LUMINANCE = Blockly.Python.valueToCode(block, 'LUMINANCE', Blockly.Python.ORDER_ATOMIC);
        return 'oled.pixel('+COORDINATE_X+','+COORDINATE_Y+','+LUMINANCE+')\n'
    }
    //屏幕画线
    Blockly.Python['scb_setScreenDrawLine'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let COORDINATE_X1 = Blockly.Python.valueToCode(block, 'COORDINATE_X1', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y1 = Blockly.Python.valueToCode(block, 'COORDINATE_Y1', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_X2 = Blockly.Python.valueToCode(block, 'COORDINATE_X2', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y2 = Blockly.Python.valueToCode(block, 'COORDINATE_Y2', Blockly.Python.ORDER_ATOMIC);
        let ACTION = Blockly.Python.valueToCode(block, 'ACTION', Blockly.Python.ORDER_ATOMIC);
        return 'oled.line('+COORDINATE_X1+', '+COORDINATE_Y1+', '+COORDINATE_X2+ ', '+COORDINATE_Y2+ ', '+ACTION+') \n'
    }
    //屏幕画水平或垂直线
    Blockly.Python['scb_setScreenDrawDirectionLine'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC),
            DIRECTION = Blockly.Python.valueToCode(block, 'DIRECTION', Blockly.Python.ORDER_ATOMIC),
            ACTION = Blockly.Python.valueToCode(block, 'ACTION', Blockly.Python.ORDER_ATOMIC),
            LENGTH = Blockly.Python.valueToCode(block, 'LENGTH', Blockly.Python.ORDER_ATOMIC);
        return 'oled.'+DIRECTION+'('+COORDINATE_X+', '+COORDINATE_Y+', '+LENGTH+', '+ACTION+')\n'
    }
    //屏幕画矩形
    Blockly.Python['scb_setScreenDrawRectangle'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC),
            ACTION = Blockly.Python.valueToCode(block, 'ACTION', Blockly.Python.ORDER_ATOMIC),
            TYPE = Blockly.Python.valueToCode(block, 'TYPE', Blockly.Python.ORDER_ATOMIC),
            HEIGHT = Blockly.Python.valueToCode(block, 'HEIGHT', Blockly.Python.ORDER_ATOMIC),
            WIDTH = Blockly.Python.valueToCode(block, 'WIDTH', Blockly.Python.ORDER_ATOMIC);
        return 'oled.'+TYPE+'('+COORDINATE_X+', '+COORDINATE_Y+', '+WIDTH+', '+HEIGHT+', '+ACTION+')\n'
    }
    //屏幕画圆形
    Blockly.Python['scb_setScreenDrawCircular'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC),
            ACTION = Blockly.Python.valueToCode(block, 'ACTION', Blockly.Python.ORDER_ATOMIC),
            TYPE = Blockly.Python.valueToCode(block, 'TYPE', Blockly.Python.ORDER_ATOMIC),
            RADIUD = Blockly.Python.valueToCode(block, 'RADIUD', Blockly.Python.ORDER_ATOMIC);
        return 'oled.'+TYPE+'('+COORDINATE_X+', '+COORDINATE_Y+', '+RADIUD+', '+ACTION+')\n'
    }
    //屏幕画三角形
    Blockly.Python['scb_setScreenDrawTriangle'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let COORDINATE_X1 = Blockly.Python.valueToCode(block, 'COORDINATE_X1', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y1 = Blockly.Python.valueToCode(block, 'COORDINATE_Y1', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_X2 = Blockly.Python.valueToCode(block, 'COORDINATE_X2', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y2 = Blockly.Python.valueToCode(block, 'COORDINATE_Y2', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_X3 = Blockly.Python.valueToCode(block, 'COORDINATE_X3', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y3 = Blockly.Python.valueToCode(block, 'COORDINATE_Y3', Blockly.Python.ORDER_ATOMIC),
            ACTION = Blockly.Python.valueToCode(block, 'ACTION', Blockly.Python.ORDER_ATOMIC),
            TYPE = Blockly.Python.valueToCode(block, 'TYPE', Blockly.Python.ORDER_ATOMIC);
        return 'oled.'+TYPE+'('+COORDINATE_X1+', '+COORDINATE_Y1+', '+COORDINATE_X2+', '+COORDINATE_Y2+', '+COORDINATE_X3+', '+COORDINATE_Y3+', '+ACTION+')\n'
    }
    //屏幕显示内容
    Blockly.Python['scb_setOLEDScreenShow'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        return 'oled.show()\n'
    }

    //设置科创板按键事件
    Blockly.Python['scb_setButtonState'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_SCBoard'] = 'import SCBoard';
        Blockly.Python.definitions_['import_SCBoard_button'] = 'button = SCBoard.Button()';
        let BUTTON = Blockly.Python.valueToCode(block, 'BUTTON', Blockly.Python.ORDER_ATOMIC);
        return ['button.value("'+BUTTON+'")', Blockly.Python.ORDER_ATOMIC]
    }

    //获取三轴数据
    Blockly.Python['scb_loadThreeAxisValue'] = function(block){
        Blockly.Python.definitions_['import_SCBoard'] = 'import SCBoard';
        Blockly.Python.definitions_['import_SCBoard_sanzhou'] = 'sanzhou = SCBoard.MSA301()';
        let THREEAXIS = Blockly.Python.valueToCode(block, 'THREEAXIS', Blockly.Python.ORDER_ATOMIC);
        let code = 'sanzhou.get_'+THREEAXIS+'()'
        return [code]
    }

    //获取心率血氧数据
    Blockly.Python['scb_loadHeartBloodValue'] = function(block){
        Blockly.Python.definitions_['import_SCBoard'] = 'import Blood';
        Blockly.Python.definitions_['import_time'] = 'import time';
        Blockly.Python.definitions_['import_SCBoard_hert'] = 'xueyang = Blood.blood()\n';
        let HEARTBLOOD = Blockly.Python.valueToCode(block, 'HEARTBLOOD', Blockly.Python.ORDER_ATOMIC);
        let functionName = Blockly.Python.provideFunction_(
            'get'+HEARTBLOOD+'Value',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  xueyang.start()',
                '  time.sleep_ms(22)',
                '  return xueyang.get_'+HEARTBLOOD+'()']);
        return [functionName+'()']

    }
    //初始化红外发射引脚
    Blockly.Python['scb_setInfraredEmissionPin'] = function(block){
        Blockly.Python.definitions_['import_SCBoard'] = 'import SCBoard';
        let INFRARED_SEND_PIN = Blockly.Python.valueToCode(block, 'INFRARED_SEND_PIN', Blockly.Python.ORDER_ATOMIC);
        let code = 'infrared = SCBoard.infrared_set('+INFRARED_SEND_PIN+')\n'
        return code
    }

    // 红外引脚发送：：
    Blockly.Python['scb_setInfraredBinaryData'] = function(block){
        Blockly.Python.definitions_['import_SCBoard'] = 'import SCBoard';
        let BINARY_DATA = Blockly.Python.valueToCode(block, 'BINARY_DATA', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let code = 'infrared.inf_sendcode(b\''+BINARY_DATA+'\')\n'
        return code
    }

    // 初始化红外发送引脚
    Blockly.Python['scb_setInfraredReceivePin'] = function(block){
        Blockly.Python.definitions_['import_SCBoard'] = 'import SCBoard';
        let INFRARED_RECEIVE_PIN = Blockly.Python.valueToCode(block, 'INFRARED_RECEIVE_PIN', Blockly.Python.ORDER_ATOMIC);
        let code = 'infrared = SCBoard.infrared_get('+INFRARED_RECEIVE_PIN+')\n'
        return code
    }

    // 读取红外数据
    Blockly.Python['scb_loadInfraredReceiveData'] = function(block){
        let INFRARED_RECEIVE_DATA_TYPE = Blockly.Python.valueToCode(block, 'INFRARED_RECEIVE_DATA_TYPE', Blockly.Python.ORDER_ATOMIC);
        let code = 'infrared.get_inf_'+INFRARED_RECEIVE_DATA_TYPE+'()'
        return [code]

    }

    // 红外数据清空
    Blockly.Python['scb_clearInfraredData'] = function(block){
        let code = 'infrared.set_inf_zero()\n'
        return code
    }

    // 初始化pwm对象
    Blockly.Python['scb_initPWMAnalogOutput'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let PWM_PIN = Blockly.Python.valueToCode(block, 'PWM_PIN', Blockly.Python.ORDER_ATOMIC);
        return 'pwm'+PWM_PIN+' = machine.PWM(machine.Pin('+PWM_PIN+'))\n'
    }

    // pwm对象设置数值
    Blockly.Python['scb_setPWMAnalogOutputData'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let PWM_PIN = Blockly.Python.valueToCode(block, 'PWM_PIN', Blockly.Python.ORDER_ATOMIC);
        let NUM = Blockly.Python.valueToCode(block, 'NUM', Blockly.Python.ORDER_ATOMIC);
        return 'pwm'+PWM_PIN+'.duty('+NUM+')\n'
    }

    // pwm对象设置使用频率
    Blockly.Python['scb_setPWMAnalogOutputFrequency'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let PWM_PIN = Blockly.Python.valueToCode(block, 'PWM_PIN', Blockly.Python.ORDER_ATOMIC);
        let FREQUENCY = Blockly.Python.valueToCode(block, 'FREQUENCY', Blockly.Python.ORDER_ATOMIC);
        return 'pwm'+PWM_PIN+'.freq('+FREQUENCY+')\n'
    }




    // LM35温度传感器
    Blockly.Python['scb_ADCgetLM35temp'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_lm35'] = 'import lm35';
        let ADC_RECEIVE_PIN = Blockly.Python.valueToCode(block, 'ADC_RECEIVE_PIN', Blockly.Python.ORDER_ATOMIC);
        let code = 'lm35.get_LM35_temperature('+ADC_RECEIVE_PIN+')'
        return [code]
    }

    // DS18x20温度传感器
    Blockly.Python['scb_DS18x20xgettemp'] = function(block){
        Blockly.Python.definitions_['import_ds18x20x'] = 'import ds18x20x';
        let INTERFACE = Blockly.Python.valueToCode(block, 'INTERFACE', Blockly.Python.ORDER_ATOMIC);
        let code = 'ds18x20x.get_ds18x20_temperature('+INTERFACE+')'
        return [code]
    }

    // 超声波测距
    Blockly.Python['scb_sonargetdist'] = function(block){
        Blockly.Python.definitions_['import_sonar'] = 'import sonar';
        let TRIG = Blockly.Python.valueToCode(block, 'TRIG', Blockly.Python.ORDER_ATOMIC);
        let ECHO = Blockly.Python.valueToCode(block, 'ECHO', Blockly.Python.ORDER_ATOMIC);
        let code = 'sonar.Sonar('+TRIG+', '+ECHO+').checkdist()'
        return [code]
    }

    // RGB彩色灯ws2812
    Blockly.Python['scb_neopixelInitRGBlight'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_neopixel'] = 'import neopixel';
        let RGB = Blockly.Python.valueToCode(block, 'RGB', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let RGB_PIN = Blockly.Python.valueToCode(block, 'RGB_PIN', Blockly.Python.ORDER_ATOMIC);
        let NUM = Blockly.Python.valueToCode(block, 'NUM', Blockly.Python.ORDER_ATOMIC);
        return RGB+' = neopixel.NeoPixel(machine.Pin('+RGB_PIN+'), '+NUM+', timing = True)\n'
    }

    // RGB彩色灯 设置颜色
    Blockly.Python['scb_neopixelsetRGBlight'] = function(block){
        let RGB = Blockly.Python.valueToCode(block, 'RGB', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let NUM = Blockly.Python.valueToCode(block, 'NUM', Blockly.Python.ORDER_ATOMIC);
        let color = Blockly.Python.valueToCode(block, "COLOR", Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return RGB+'['+NUM+'] = '+color.colorRgb()+'\n'
    }

    // RGB彩色灯 设置颜色
    Blockly.Python['scb_neopixelTakeRGBlight'] = function(block){
        let RGB = Blockly.Python.valueToCode(block, 'RGB', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return RGB+'.write()\n'
    }

    // 一键式连接wifi
    Blockly.Python['scb_oneClickConnectToWifi'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_utime'] = 'import utime';
        let NAME = Blockly.Python.valueToCode(block, 'WIFI_NAME', Blockly.Python.ORDER_ATOMIC);
        let PASSWORD = Blockly.Python.valueToCode(block, 'WIFI_PASSWORD', Blockly.Python.ORDER_ATOMIC);
        console.log(NAME, PASSWORD)
        let functionName = Blockly.Python.provideFunction_(
            'connectToWifi',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  wlan = network.WLAN(network.STA_IF)',
                '  wlan.active(True)',
                '  wlan.connect('+NAME+','+PASSWORD+')',
                '  for i in range(60):',
                '    if wlan.isconnected():',
                '      print("连接到wifi成功")',
                '      break;',
                '    utime.sleep(1)',
                '    print("正在连接wifi...")',
                '  if not wlan.isconnected():',
                '    print("连接wifi失败！")'
            ]);
        return [functionName+'()']
    }


    // 初始化wlan设置wifi模式为
    Blockly.Python['scb_wlanInit'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let WLANIF = Blockly.Python.valueToCode(block, 'WLANIF', Blockly.Python.ORDER_ATOMIC);
        return 'wlan = network.WLAN(network.'+WLANIF+')\n'
    }

    // wlan设置状态
    Blockly.Python['scb_wlanSetActive'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let ACTIVE = Blockly.Python.valueToCode(block, 'ACTIVE', Blockly.Python.ORDER_ATOMIC);
        return 'wlan.active('+ACTIVE+')\n'
    }
    // wlan获取连接状态
    Blockly.Python['scb_wlanGetActive'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        return ['wlan.active()', Blockly.Python.ORDER_ATOMIC]
    }
    // wlan扫描可用wifi
    Blockly.Python['scb_wlanGetUsableWifi'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let WLANINFO = Blockly.Python.valueToCode(block, 'WLANINFO', Blockly.Python.ORDER_ATOMIC).replace(/\s*/g,'');
        return ['wlan.scan()'+WLANINFO]
    }

    // wlan连接wifi
    Blockly.Python['scb_wlanConnectWifi'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let WIFINAME = Blockly.Python.valueToCode(block, 'WIFINAME', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let WIFIPASSWORD = Blockly.Python.valueToCode(block, 'WIFIPASSWORD', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return 'wlan.connect("'+WIFINAME+'","'+WIFIPASSWORD+'")\n'
    }

    // 获取wifi连接状态
    Blockly.Python['scb_wlanWifiGetActive'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        return ['wlan.isconnected()', Blockly.Python.ORDER_ATOMIC]
    }

    // 获取wifi的信息
    Blockly.Python['scb_wlanWifigetInfo'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let WIFIINFO = Blockly.Python.valueToCode(block, 'WIFIINFO', Blockly.Python.ORDER_ATOMIC);
        let code = 'wlan.ifconfig()['+WIFIINFO+']'
        return [code]
    }

    // 获取wifi的配置信息
    Blockly.Python['scb_wlanWifigetConfigInfo'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let WLANWIFICONFIG = Blockly.Python.valueToCode(block, 'WLANWIFICONFIG', Blockly.Python.ORDER_ATOMIC);
        let code = 'wlan.config(\"'+WLANWIFICONFIG+'\")'
        return [code]

    }

    // wifi断开连接
    Blockly.Python['scb_wlanDisconnectWifi'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        return 'wlan.disconnect()\n'

    }
    //获取wifi接入点名称或通道
    Blockly.Python['scb_wlanSetConfigt'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let ESSID = Blockly.Python.valueToCode(block, 'ESSID', Blockly.Python.ORDER_ATOMIC);
        let CHANNEL = Blockly.Python.valueToCode(block, 'CHANNEL', Blockly.Python.ORDER_ATOMIC);
        return 'wlan.config(essid = '+ESSID+', channel = '+CHANNEL+')\n'

    }

    // socket创建套接字
    Blockly.Python['scb_socketCreat'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let SOCK = Blockly.Python.valueToCode(block, 'SOCK', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let SOCKTYPE = Blockly.Python.valueToCode(block, 'SOCKTYPE', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return SOCK+' = socket.socket(socket.'+SOCKTYPE+')\n'

    }
    // 将套接字绑定地址
    Blockly.Python['scb_socketBindAddress'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let SOCK = Blockly.Python.valueToCode(block, 'SOCK', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let ADDRESS = Blockly.Python.valueToCode(block, 'ADDRESS', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let PORT = Blockly.Python.valueToCode(block, 'PORT', Blockly.Python.ORDER_ATOMIC);
        return SOCK+'.bind(("'+ADDRESS+'", '+PORT+'))\n'

    }
    // 将套接字连接地址
    Blockly.Python['scb_socketConnectAddress'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let SOCK = Blockly.Python.valueToCode(block, 'SOCK', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let ADDRESS = Blockly.Python.valueToCode(block, 'ADDRESS', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let PORT = Blockly.Python.valueToCode(block, 'PORT', Blockly.Python.ORDER_ATOMIC);
        return SOCK+'.connect(("'+ADDRESS+'", '+PORT+'))\n'

    }
    // 开始监听传入连接
    Blockly.Python['scb_socketListen'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let SOCK = Blockly.Python.valueToCode(block, 'SOCK', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let NUM = Blockly.Python.valueToCode(block, 'NUM', Blockly.Python.ORDER_ATOMIC);
        return SOCK+'.listen('+NUM+')\n'

    }
    // 接受连接并返回套接字对象
    Blockly.Python['scb_socketaccept'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let SOCK = Blockly.Python.valueToCode(block, 'SOCK', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return [SOCK+'.accept()']

    }
    // 接受套接字的数据TCP
    Blockly.Python['scb_socketTCPConnRecv'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let CONN = Blockly.Python.valueToCode(block, 'CONN', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let COUNT = Blockly.Python.valueToCode(block, 'COUNT', Blockly.Python.ORDER_ATOMIC);
        return [CONN+'.recv('+COUNT+')']

    }

    // [CONN] 发送数据TCP
    Blockly.Python['scb_socketTCPSend'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let CONN = Blockly.Python.valueToCode(block, 'CONN', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let DATA = Blockly.Python.valueToCode(block, 'DATA', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return CONN+'.send('+DATA+')\n'

    }

    // 接受套接字的数据UDP
    Blockly.Python['scb_socketUDPConnRecv'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let CONN = Blockly.Python.valueToCode(block, 'CONN', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let COUNT = Blockly.Python.valueToCode(block, 'COUNT', Blockly.Python.ORDER_ATOMIC);
        return [CONN+'.recvfrom('+COUNT+')']

    }

    // [CONN] 发送数据UDP
    Blockly.Python['scb_socketTXPSend'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let CONN = Blockly.Python.valueToCode(block, 'CONN', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let DATA = Blockly.Python.valueToCode(block, 'DATA', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let ADDRESS = Blockly.Python.valueToCode(block, 'ADDRESS', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let PORT = Blockly.Python.valueToCode(block, 'PORT', Blockly.Python.ORDER_ATOMIC);
        return CONN+'.sendto('+DATA+',("'+ADDRESS+'", '+PORT+'))\n'

    }

    // conn关闭连接
    Blockly.Python['scb_socketclose'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let CONN = Blockly.Python.valueToCode(block, 'CONN', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return CONN+'.close()\n'

    }

    //初始化mqtt客户端
    Blockly.Python['scb_initMqttServer'] = function(block){
        Blockly.Python.definitions_['import_SCBoard_app'] = 'import SCBoard_app';
        let WIFI_NAME = Blockly.Python.valueToCode(block, 'WIFI_NAME', Blockly.Python.ORDER_ATOMIC),
            WIFI_PASSWORD = Blockly.Python.valueToCode(block, 'WIFI_PASSWORD', Blockly.Python.ORDER_ATOMIC),
            DEVICE_ID = Blockly.Python.valueToCode(block, 'DEVICE_ID', Blockly.Python.ORDER_ATOMIC),
            NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC)),
            password = '  print('+NAME+')\n' +
                '  global board_app\n',
            SUBSTACK = Blockly.Python.statementToCode(block, 'SUBSTACK'),
            pass = password+Blockly.Python.addLoopTrap(SUBSTACK, block.id) || password;
        return 'def app_con_data('+NAME+'):\n' +pass+'\n'+'board_app = SCBoard_app.SCBoard_app('+WIFI_NAME+','+WIFI_PASSWORD+','+DEVICE_ID+')\n' +
            'board_app.call_app_data(app_con_data)\n'
    }
    // mqtt 发送消息
    Blockly.Python['scb_MqttSendMessage'] = function(block){
        let MESSAGE =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MESSAGE', Blockly.Python.ORDER_ATOMIC));
        let MSG_NAME =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MSG_NAME', Blockly.Python.ORDER_ATOMIC));
        return 'board_app.app_senddata('+MSG_NAME+', '+MESSAGE+')\n'
    }
    // 串口 uart初始化
    Blockly.Python['scb_initUart'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let RX_Pin =  Blockly.Python.valueToCode(block, 'RX_Pin', Blockly.Python.ORDER_ATOMIC);
        let TX_Pin =  Blockly.Python.valueToCode(block, 'TX_Pin', Blockly.Python.ORDER_ATOMIC);
        return 'uart = machine.UART(2, baudrate=9600, rx='+RX_Pin+',tx='+TX_Pin+',timeout=10)\n'
    }
    // 串口 存在数据
    Blockly.Python['scb_whileUartAny'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return ['uart.any()', Blockly.Python.ORDER_ATOMIC]
    }
    // 串口读取一行数据
    Blockly.Python['scb_uartReadline'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return 'bin_data = uart.readline()\n'
    }
    // 语音识别模块
    Blockly.Python['scb_speechRecognition'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let Speech_Recognition =  Blockly.Python.valueToCode(block, 'Speech_Recognition', Blockly.Python.ORDER_ATOMIC);
        let SUBSTACK = Blockly.Python.statementToCode(block, 'SUBSTACK'),
            pass = Blockly.Python.addLoopTrap(SUBSTACK, block.id) || '  pass';
        let pass_format = pass.split('\n');
        let func_body='';
        for(let i = 0;i< pass_format.length;i++){
            func_body += ''+pass_format[i]+'\n'
        }
        return "if len(bin_data)> 4 and bin_data[4] == "+Speech_Recognition+":\n" +
            func_body+'\n'
    }
    // 串口写数据
    Blockly.Python['scb_uartWrite'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let BYTES =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'BYTES', Blockly.Python.ORDER_ATOMIC));
        return 'uart.write('+BYTES+')\n'
    }
    // OLED 清屏
    Blockly.Python['scb_clearOLED'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        return 'oled.show_fill(0)\n'
    }
    // OLED 显示第几行
    Blockly.Python['scb_setOLEDShowRows'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let ROW = Blockly.Python.valueToCode(block, 'ROW', Blockly.Python.ORDER_ATOMIC);
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        return 'oled.text('+TEXT+',0,'+ROW+')\n'
    }
    // OLED 清除第几行
    Blockly.Python['scb_setOLEDClearRows'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let ROW = Blockly.Python.valueToCode(block, 'ROW', Blockly.Python.ORDER_ATOMIC);
        return 'oled.fill_rect(0,'+ROW+',128,16,0)\n'
    }
    // 获取系统运行时间
    Blockly.Python['scb_getSystemRunTime'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let TIME = Blockly.Python.valueToCode(block, 'TIME', Blockly.Python.ORDER_ATOMIC);
        return ['time.'+[TIME]+'()']
    }
    // OLED 创建模型
    Blockly.Python['scb_createOLEDModel'] = function(block){
        let VARIABLE = Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let WIDTH = Blockly.Python.valueToCode(block, 'WIDTH', Blockly.Python.ORDER_ATOMIC);
        let HEIGHT = Blockly.Python.valueToCode(block, 'HEIGHT', Blockly.Python.ORDER_ATOMIC);
        let MODEL = Blockly.Python.valueToCode(block, 'MODEL', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        // Blockly.Python.definitions_['import_SCBoard'] = 'import SCBoard';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        // Blockly.Python.definitions_['import_FrameBuffer'] = 'funs = SCBoard.Funs()\nfrom framebuf import FrameBuffer as FB\n';
        return VARIABLE+'='+[MODEL]+'.replace(" ", "")\n'+[VARIABLE]+'= FB(bytearray([int(\'0x\'+'+[VARIABLE]+'[i+2:i+4]) for i in range(0,len('+[VARIABLE]+'),5)]), '+[WIDTH]+', '+[HEIGHT]+', 3)\n'
    }
    // OLED 系统游戏模型
    Blockly.Python['scb_systemGameOLEDModel'] = function(block){
        let VARIABLE = Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let MODEL = Blockly.Python.valueToCode(block, 'MODEL', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        // Blockly.Python.definitions_['import_SCBoard'] = 'import SCBoard';
        Blockly.Python.definitions_['import_SCBoard_text'] = 'import SCBoard_text';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        // Blockly.Python.definitions_['import_FrameBuffer'] = 'funs = SCBoard.Funs()\nfrom framebuf import FrameBuffer as FB\n';
        Blockly.Python.definitions_['import_SCBoard_gamedemo'] = 'gamedemo = SCBoard_text.Game_demo()';
        return VARIABLE+'=gamedemo.get_game_ico("'+[MODEL]+'")\n'
    }
    // OLED 系统天气模型
    Blockly.Python['scb_getSystemOLEDWeather'] = function(block){
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        // Blockly.Python.definitions_['import_SCBoard'] = 'import SCBoard';
        Blockly.Python.definitions_['import_SCBoard_text'] = 'import SCBoard_weather_text';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        // Blockly.Python.definitions_['import_FrameBuffer'] = 'funs = SCBoard.Funs()\nfrom framebuf import FrameBuffer as FB\n';
        Blockly.Python.definitions_['import_SCBoard_weather_text'] = 'aweather = SCBoard_weather_text.Font()';
        return ['aweather.get_img('+[TEXT]+')']
    }
    // OLED 系统数字模型
    Blockly.Python['scb_getSystemOLEDNumber'] = function(block){
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        // Blockly.Python.definitions_['import_SCBoard'] = 'import SCBoard';
        Blockly.Python.definitions_['import_SCBoard_text'] = 'import SCBoard_text';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        // Blockly.Python.definitions_['import_FrameBuffer'] = 'funs = SCBoard.Funs()\nfrom framebuf import FrameBuffer as FB\n';
        Blockly.Python.definitions_['import_SCBoard_SCBoard_text'] = 'tnumber = SCBoard_text.Font()';
        return ['tnumber.get_24_text('+[TEXT]+')']
    }
    // OLED 加载模型
    Blockly.Python['scb_loadOLEDModel'] = function(block){
        let VARIABLE = Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC);
        let COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        // Blockly.Python.definitions_['import_SCBoard'] = 'import SCBoard';
        Blockly.Python.definitions_['import_SCBoard_oled'] = 'i2c = machine.I2C(scl = machine.Pin(15), sda = machine.Pin(4), freq = 100000)\noled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        // Blockly.Python.definitions_['import_FrameBuffer'] = 'funs = SCBoard.Funs()\nfrom framebuf import FrameBuffer as FB\n';
        return 'oled.framebuf.blit('+[VARIABLE]+','+[COORDINATE_X]+','+[COORDINATE_Y]+')\n'
    }
    // 初始化get请求
    Blockly.Python['scb_initGet'] = function(block){
        let VARIABLE = Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let LINK = Blockly.Python.valueToCode(block, 'LINK', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_json'] = 'import json';
        Blockly.Python.definitions_['import_SCBoard_app'] = 'import SCBoard_app';
        return ['SCBoard_app.weather('+[VARIABLE]+','+[LINK]+')']
    }
    // 获取get请数据
        Blockly.Python['scb_getData'] = function(block){
        let VARIABLE = Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return [VARIABLE+'.get_data()']
    }
    // mqtt发送消息字符串
    Blockly.Python['scb_MqttSendMessageStr'] = function(block){
        let MESSAGE =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MESSAGE', Blockly.Python.ORDER_ATOMIC));
        return 'board_app.app_senddata_sendmsg('+MESSAGE+')\n'
    }
    // 科创板演示程序
    Blockly.Python['scb_demoProgram'] = function(block){
        Blockly.Python.definitions_['import_zkspsimplev20'] = 'import zkspsimplev20';
        return 'scbDemo = zkspsimplev20.SCBord()\nscbDemo.start()\n'
    }
    Blockly.Python['scb_addMqttTheme'] = function(block){
        let NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC));
        let FUNCTION = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'FUNCTION', Blockly.Python.ORDER_ATOMIC));
        let MSG = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MSG', Blockly.Python.ORDER_ATOMIC));
        let SUBSTACK = Blockly.Python.statementToCode(block, 'SUBSTACK');
        let pass = Blockly.Python.addLoopTrap(SUBSTACK, block.id);
        return 'def '+FUNCTION+'(topic,'+MSG+'):\n' +
            '  print("'+FUNCTION+'  topic:" + str(topic) + "  msg:" + str('+MSG+'))\n' +pass+'\n'+
            'board_app.mqtt_subscribe('+NAME+','+FUNCTION+')\n'
    }
    Blockly.Python['scb_MqttSendCustomMessage'] = function(block){
        let MSG_NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MSG_NAME', Blockly.Python.ORDER_ATOMIC));
        let MESSAGE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MESSAGE', Blockly.Python.ORDER_ATOMIC));
        return 'board_app.app_senddata_self('+MSG_NAME+','+MESSAGE+')\n'
    }
    Blockly.Python['IoTb_MqttIsConnect'] = function(block){
        return ['board_app.state']
    }

}
