/*
* author : wlc
* time : 2020-6-17
* */

export default function (Blockly) {
    Blockly.Arduino['math_positive_number'] = function(block){
        let second = block.getFieldValue('NUM');
        return [second]

    }

    Blockly.Arduino['control_wait'] = function (block) {
        let second = Blockly.Arduino.valueToCode(block, 'DURATION', Blockly.Arduino.ORDER_ATOMIC);
        if (second === 'NaN'){
            second = 0
        }
        else{
            second = second*1000;
        }
        return 'delay(' + second + ');\n';
    };

    Blockly.Arduino['math_whole_number'] = function (block) {
        let times = parseInt(block.getFieldValue('NUM'));
        return [times]
    }


    Blockly.Arduino['control_repeat'] = function (block) {
        let repeats = Blockly.Arduino.valueToCode(block, 'TIMES',
            Blockly.Arduino.ORDER_UNARY_POSTFIX);
        let branch = Blockly.Arduino.statementToCode(block, 'SUBSTACK');
        branch = Blockly.Arduino.addLoopTrap(branch, block.id);

        let code = "for(int i=0; i<" + repeats + "; i++){\n";
        code += branch;
        code += "}\n";
        return code;
    }

    Blockly.Arduino['control_forever'] = function (block) {
        let branch = Blockly.Arduino.statementToCode(block, 'SUBSTACK');
        branch = Blockly.Arduino.addLoopTrap(branch, block.id);

        let code = "while(1){\n";
        code += branch;
        code += Blockly.Arduino.INDENT + "_loop();\n}\n";
        return code;
    }

    Blockly.Arduino['control_if'] = function (block) {
        let argument = Blockly.Arduino.valueToCode(block, 'CONDITION',
            Blockly.Arduino.ORDER_NONE) || 'false';
        let branch = Blockly.Arduino.statementToCode(block, 'SUBSTACK');
        branch = Blockly.Arduino.addLoopTrap(branch, block.id);

        return "if(" + argument + "){\n"+branch+"}\n";
    }

    Blockly.Arduino['control_if_else'] = function (block) {
        let argument = Blockly.Arduino.valueToCode(block, 'CONDITION',
            Blockly.Arduino.ORDER_NONE) || 'false';
        let branch = Blockly.Arduino.statementToCode(block, 'SUBSTACK');
        branch = Blockly.Arduino.addLoopTrap(branch, block.id);
        let branch2 = Blockly.Arduino.statementToCode(block, 'SUBSTACK2');
        branch2 = Blockly.Arduino.addLoopTrap(branch2, block.id);

        let code = "if(" + argument + "){\n";
        code += branch;
        code += "}\nelse{\n";
        code += branch2;
        code += "}\n";
        return code;
    }

    Blockly.Arduino['control_wait_until'] = function (block) {
        let argument = Blockly.Arduino.valueToCode(block, 'CONDITION',
            Blockly.Arduino.ORDER_UNARY_POSTFIX) || 'false';
        let code = "while(!" + argument + ") {\n";
        code += "}\n";
        return code;
    }

    Blockly.Arduino['control_repeat_until'] = function (block) {
        let argument = Blockly.Arduino.valueToCode(block, 'CONDITION',
            Blockly.Arduino.ORDER_UNARY_POSTFIX) || 'false';

        let branch = Blockly.Arduino.statementToCode(block, 'SUBSTACK');
        branch = Blockly.Arduino.addLoopTrap(branch, block.id);

        let code = "while(!" + argument + ") {\n";
        code += branch;
        code += "}\n";
        return code;
    }
}
