/*
* author : wlc
* time : 2020-8-28
* */
import {regQuotationMarks,replaceQuotationMarks, colorHexToRGB} from '../common.js'

export default function (Blockly) {
    // 按钮下拉框
    Blockly.Python['AiMBox_menu_BUTTON_MENU'] = function(block) {
        let button = block.getFieldValue('BUTTON_MENU');
        return [button];
    }
    // 血氧获取数据下拉框
    Blockly.Python['AiMBox_menu_HEARTBLOOD_MENU'] = function(block) {
        let hert = block.getFieldValue('HEARTBLOOD_MENU');
        return [hert];
    }

    // interface 下拉框
    Blockly.Python['AiMBox_menu_Digital_Input_MENU'] = function(block) {
        let Digital_Input_PIN = block.getFieldValue('Digital_Input_MENU');
        return [Digital_Input_PIN];
    }
    // 模拟输出下拉框
    Blockly.Python['AiMBox_menu_Digital_Output_MENU'] = function(block){
        let Digital_Output_PIN = block.getFieldValue('Digital_Output_MENU');
        return [Digital_Output_PIN];
    }
    // 模拟输入管脚号 下拉框
    Blockly.Python['AiMBox_menu_Analog_Input_MENU'] = function(block) {
        let Analog_Input_PIN = block.getFieldValue('Analog_Input_MENU');
        return [Analog_Input_PIN];
    }
    // 模拟输出管脚号 下拉框
    Blockly.Python['AiMBox_menu_Analog_Output_MENU'] = function(block) {
        let Analog_Output_PIN = block.getFieldValue('Analog_Output_MENU');
        return [Analog_Output_PIN];
    }
    //高低电平
    Blockly.Python['AiMBox_menu_LEVEL_MENU'] = function(block) {
        let LEVEL = block.getFieldValue('LEVEL_MENU');
        return [LEVEL];
    }

    // 开关下拉框
    Blockly.Python['AiMBox_menu_SWITCH_MENU'] = function(block) {
        let SWITCH = block.getFieldValue('SWITCH_MENU');
        return [SWITCH];
    }
    // wlan模式下拉框
    Blockly.Python['AiMBox_menu_WLAN_IF_MENU'] = function(block) {
        let wlan = block.getFieldValue('WLAN_IF_MENU');
        return [wlan];
    }
    // wlan状态下拉框
    Blockly.Python['AiMBox_menu_WLAN_ACTIVE_MENU'] = function(block) {
        let wlan = block.getFieldValue('WLAN_ACTIVE_MENU');
        return [wlan];
    }
    // wlan信息下拉框
    Blockly.Python['AiMBox_menu_WLAN_WIFIINFO_MENU'] = function(block) {
        let wlan = block.getFieldValue('WLAN_WIFIINFO_MENU');
        return [wlan];
    }
    // wlan信息下拉框
    Blockly.Python['AiMBox_menu_WIFIINFO_MENU'] = function(block) {
        let wlan = block.getFieldValue('WIFIINFO_MENU');
        return [wlan];
    }
    // wlan设置下拉框
    Blockly.Python['AiMBox_menu_WLANWIFICONFIG_MENU'] = function(block) {
        let wlan = block.getFieldValue('WLANWIFICONFIG_MENU');
        return [wlan];
    }
    // 连接类型下拉框
    Blockly.Python['AiMBox_menu_SOCKTYPE_MENU'] = function(block) {
        let wlan = block.getFieldValue('SOCKTYPE_MENU');
        return [wlan];
    }

    // 温湿度下拉框
    Blockly.Python['AiMBox_menu_TemperatureHumidity_MENU'] = function(block) {
        let TemperatureHumidity = block.getFieldValue('TemperatureHumidity_MENU');
        return [TemperatureHumidity];
    }
    // 各种传感器下拉框
    Blockly.Python['AiMBox_menu_SENSOR_MENU'] = function(block) {
        let Sensor = block.getFieldValue('SENSOR_MENU');
        return [Sensor];
    }
    // 语音指令
    Blockly.Python['AiMBox_menu_Speech_Recognition_MENU'] = function(block) {
        let Speech_Recognition = block.getFieldValue('Speech_Recognition_MENU');
        return [Speech_Recognition];
    }
    // 选择屏幕显示下拉框
    Blockly.Python['AiMBox_menu_OLEDSL_MENU'] = function(block) {
        let wlan = block.getFieldValue('OLEDSL_MENU');
        return [wlan];
    }
    //  语音识别模块管脚
    Blockly.Python['AiMBox_menu_UART_PIN_MENU'] = function(block){
        let UART_PIN = block.getFieldValue('UART_PIN_MENU');
        return [UART_PIN]
    }
    // 语音识别命令下拉框
    Blockly.Python['AiMBox_menu_Speech_Recognition_MENU'] = function(block){
        let Recognition_CMD = block.getFieldValue('Speech_Recognition_MENU');
        return [Recognition_CMD]
    }
    // OLED显示类型下拉框
    Blockly.Python['AiMBox_menu_OLED_SHOW_TYPE_MENU'] = function(block){
        let SHOW_TYPE = block.getFieldValue('OLED_SHOW_TYPE_MENU');
        return [SHOW_TYPE]
    }
    // OLED描点亮度下拉框
    Blockly.Python['AiMBox_menu_OLED_LUMINANCE_MENU'] = function(block){
        let OLED_LUMINANCE = block.getFieldValue('OLED_LUMINANCE_MENU');
        return [OLED_LUMINANCE]
    }
    // OLED 动作下拉框
    Blockly.Python['AiMBox_menu_OLED_ACTION_MENU'] = function(block){
        let OLED_ACTION = block.getFieldValue('OLED_ACTION_MENU');
        return [OLED_ACTION]
    }
    // OLED 方向下拉框
    Blockly.Python['AiMBox_menu_OLED_DIRECTION_MENU'] = function(block){
        let OLED_DIRECTION = block.getFieldValue('OLED_DIRECTION_MENU');
        return [OLED_DIRECTION]
    }
    // OLED 矩形下拉框
    Blockly.Python['AiMBox_menu_OLED_RECTANGLE_MENU'] = function(block){
        let OLED_RECTANGLE = block.getFieldValue('OLED_RECTANGLE_MENU');
        return [OLED_RECTANGLE]
    }
    // OLED 圆形下拉框
    Blockly.Python['AiMBox_menu_OLED_CIRCULAR_MENU'] = function(block){
        let OLED_CIRCULAR = block.getFieldValue('OLED_CIRCULAR_MENU');
        return [OLED_CIRCULAR]
    }
    // OLED 三角形下拉框
    Blockly.Python['AiMBox_menu_OLED_TRIANGLE_MENU'] = function(block){
        let OLED_TRIANGLE = block.getFieldValue('OLED_TRIANGLE_MENU');
        return [OLED_TRIANGLE]
    }
    // OLED 行号下拉框
    Blockly.Python['AiMBox_menu_OLED_ROW_MENU'] = function(block){
        let OLED_ROW = block.getFieldValue('OLED_ROW_MENU');
        return [OLED_ROW]
    }

    // 超声波测距
    Blockly.Python['AiMBox_loadUltrasonicSensor'] = function(block){
        Blockly.Python.definitions_['import_sonar'] = 'import sonar';
        return ['sonar.Sonar(16, 17).checkdist()']
    }
    // 直流电机
    Blockly.Python['AiMBox_setMotorSpeed'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let SWITCH = Blockly.Python.valueToCode(block, 'SWITCH', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['init_motor'] = 'pin_motor = machine.Pin(14, machine.Pin.OUT)\n'
        return 'pin_motor.value('+SWITCH+')\n'
    }

    //舵机
    Blockly.Python['AiMBox_setServoAngle'] = function(block){
        Blockly.Python.definitions_['import_servo'] = 'import servo';
        let ANGLE = Blockly.Python.valueToCode(block, 'ANGLE', Blockly.Python.ORDER_ATOMIC);
        return 'servo.servo_write_angle(2,'+ANGLE+')\n'
    }
    // 光线传感器
    Blockly.Python['AiMBox_loadLightSensorValue'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let ANALOG_INPUT_PIN = 36;
        Blockly.Python.definitions_['import_light_sensor'+ANALOG_INPUT_PIN] = 'adc'+ANALOG_INPUT_PIN+' = machine.ADC(machine.Pin('+ANALOG_INPUT_PIN+'))\n' +
            'adc'+ANALOG_INPUT_PIN+'.atten(machine.ADC.ATTN_11DB)\n';
        return ['adc'+ANALOG_INPUT_PIN+'.read()']
    }
    //蜂鸣器
    Blockly.Python['AiMBox_setBuzzer'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_utime'] = 'import utime';
        let DIGITAL_OUTPUT_PIN = 4,
            FREQ = Blockly.Python.valueToCode(block, 'FREQ', Blockly.Python.ORDER_ATOMIC),
            TIME = Blockly.Python.valueToCode(block, 'TIME', Blockly.Python.ORDER_ATOMIC)
        return 'pwm'+DIGITAL_OUTPUT_PIN+' = machine.PWM(machine.Pin('+DIGITAL_OUTPUT_PIN+'))\n'+
            'pwm'+DIGITAL_OUTPUT_PIN+'.freq('+FREQ+')\n'+
            'utime.sleep('+TIME+')\n'+
            'pwm'+DIGITAL_OUTPUT_PIN+'.deinit()\n'
    }
    //获取心率血氧数据
    Blockly.Python['AiMBox_loadHeartBloodValue'] = function(block){
        Blockly.Python.definitions_['import_SCBoard'] = 'import Blood';
        Blockly.Python.definitions_['import_time'] = 'import time';
        Blockly.Python.definitions_['import_SCBoard_hert'] = 'xueyang = Blood.blood()\n';
        let HEARTBLOOD = Blockly.Python.valueToCode(block, 'HEARTBLOOD', Blockly.Python.ORDER_ATOMIC);
        let functionName = Blockly.Python.provideFunction_(
            'get'+HEARTBLOOD+'Value',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  xueyang.start()',
                '  time.sleep_ms(22)',
                '  return xueyang.get_'+HEARTBLOOD+'()']);
        return [functionName+'()']
    }

    // 按键
    Blockly.Python['AiMBox_setButtonState'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['init_button'] = 'adc36 = machine.ADC(machine.Pin(36))\n' +
            'adc36.atten(machine.ADC.ATTN_11DB)\n' +
            'pin_up = machine.Pin(39, machine.Pin.IN)\n' +
            'pin_down = machine.Pin(15, machine.Pin.IN)\n' +
            'pin_left = machine.Pin(12, machine.Pin.IN)\n' +
            'pin_right = machine.Pin(34, machine.Pin.IN)\n'
            // 'pin_confirm = machine.Pin(13, machine.Pin.IN)\n'
        let BUTTON = Blockly.Python.valueToCode(block, 'BUTTON', Blockly.Python.ORDER_ATOMIC);
        let functionName = Blockly.Python.provideFunction_(
            'getButtonValue',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(pin_button):',
                '  return pin_button.value()'])

        return [functionName+'('+BUTTON+')', Blockly.Python.ORDER_ATOMIC]
    }

    // 人体红外传感器
    Blockly.Python['AiMBox_loadHumanInfrared'] = function (block) {
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['init_human_infrared'] = 'human_infrared = machine.Pin(25, machine.Pin.IN)\n'
        return ['human_infrared.value()']
    }

    // 读取rfid
    Blockly.Python['AiMBox_readRFID'] = function (block) {
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_mfrc522'] = 'import mfrc522';
        let SUBSTACK = Blockly.Python.statementToCode(block, 'SUBSTACK'),
            pass = Blockly.Python.addLoopTrap(SUBSTACK, block.id);
        Blockly.Python.definitions_['rfid_init'] = 'ESP32_HSPI_CLOCK = 18 \n' +
            'ESP32_HSPI_SLAVE_SELECT = 5\n' +
            'ESP32_HSPI_MISO         = 19\n' +
            'ESP32_HSPI_MOSI         = 23\n' +
            'ESP32_MFRC522_RST       = 27\n' +
            'rdr = mfrc522.MFRC522(ESP32_HSPI_CLOCK, ESP32_HSPI_MOSI, ESP32_HSPI_MISO, ESP32_MFRC522_RST, ESP32_HSPI_SLAVE_SELECT)\n';
        return '(stat, tag_type) = rdr.request(rdr.REQIDL)\n'+
            'if stat == rdr.OK:\n'+
            pass
    }
    Blockly.Python['AiMBox_getRFIDCardId'] = function (block) {
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_mfrc522'] = 'import mfrc522';
        Blockly.Python.definitions_['rfid_init'] = 'ESP32_HSPI_CLOCK = 18 \n' +
            'ESP32_HSPI_SLAVE_SELECT = 5\n' +
            'ESP32_HSPI_MISO         = 19\n' +
            'ESP32_HSPI_MOSI         = 23\n' +
            'ESP32_MFRC522_RST       = 27\n' +
            'rdr = mfrc522.MFRC522(ESP32_HSPI_CLOCK, ESP32_HSPI_MOSI, ESP32_HSPI_MISO, ESP32_MFRC522_RST, ESP32_HSPI_SLAVE_SELECT)\n';
        let functionName = Blockly.Python.provideFunction_(
            'getRFIDCardId',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  card_id = \'\'',
                '  stat, raw_uid = rdr.anticoll()',
                '  for i in raw_uid:',
                '    card_id += hex(i)',
                '  return card_id']);
        return [functionName+'()']

    }

    // 温湿度传感器
    Blockly.Python['AiMBox_readTemperatureAndHumidity'] = function (block) {
        Blockly.Python.definitions_['import_sht20'] = 'import sht20';
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['rfid_init'] = 'i2c = machine.I2C(scl = machine.Pin(22), sda = machine.Pin(21), freq = 400000)\n' +
            'sht = sht20.SHT20(i2c)\n';
        let TemperatureHumidity = Blockly.Python.valueToCode(block, 'TemperatureHumidity', Blockly.Python.ORDER_ATOMIC);
        return ['sht.get_'+TemperatureHumidity+'()']
    }

    // 读取传感器的值
    Blockly.Python['AiMBox_readSensorValue'] = function (block) {
        let Sensor = Blockly.Python.valueToCode(block, 'Sensor', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        if(Sensor === 'Ultrasound'){
            Blockly.Python.definitions_['import_sonar'] = 'import sonar';
            return ['sonar.Sonar(16, 17).checkdist()']
        }
        else if (Sensor === 'Light'){
            let ANALOG_INPUT_PIN = 36;
            Blockly.Python.definitions_['import_light_sensor'+ANALOG_INPUT_PIN] = 'adc'+ANALOG_INPUT_PIN+' = machine.ADC(machine.Pin('+ANALOG_INPUT_PIN+'))\n' +
                'adc'+ANALOG_INPUT_PIN+'.atten(machine.ADC.ATTN_11DB)\n';
            return ['adc'+ANALOG_INPUT_PIN+'.read()']
        }else if (Sensor === 'Infrared'){
            Blockly.Python.definitions_['import_machine'] = 'import machine';
            Blockly.Python.definitions_['init_human_infrared'] = 'human_infrared = machine.Pin(25, machine.Pin.IN)\n'
            return ['human_infrared.value()']
        }
    }

    // 打印
    Blockly.Python['AiMBox_printText'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        return 'print(' + TEXT + ')\n';
    };

    // 初始化串口
    Blockly.Python['AiMBox_initUart'] = function (block) {
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return 'uart = machine.UART(2, baudrate=9600, rx=13,tx=12,timeout=10)\n'
    };
    // 串口读取数据
    Blockly.Python['AiMBox_whileUartAny'] = function (block) {
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return ['uart.any()', Blockly.Python.ORDER_ATOMIC]
    };
    // 串口读取一行数据
    Blockly.Python['AiMBox_uartReadline'] = function (block) {
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return 'bin_data = uart.readline()\n'
    };
    // 语音识别
    Blockly.Python['AiMBox_speechRecognition'] = function (block) {
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let Speech_Recognition =Blockly.Python.valueToCode(block, 'Speech_Recognition', Blockly.Python.ORDER_ATOMIC);

        let SUBSTACK = Blockly.Python.statementToCode(block, 'SUBSTACK'),
            pass = Blockly.Python.addLoopTrap(SUBSTACK, block.id) || '  pass';
        let pass_format = pass.split('\n');
        let func_body='';
        for(let i = 0;i< pass_format.length;i++){
            func_body += pass_format[i]+'\n'
        }
        return "if len(bin_data)> 4 and bin_data[4] == "+Speech_Recognition+":\n" +
            func_body+'\n'
    };
    //设置屏幕字母
    Blockly.Python['AiMBox_setOLEDShowText'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_AiMBox_oled'] = 'i2c = machine.I2C(scl = machine.Pin(22), sda = machine.Pin(21), freq = 100000)\n' +
            'pin26 = machine.Pin(26, machine.Pin.OUT)\n' +
            'pin26.value(0)\n'+
            'pin26.value(1)\n'+
            'oled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC);
        let COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC);
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        return 'oled.text('+TEXT+','+COORDINATE_X+','+COORDINATE_Y+')\n'
    }
    //屏幕显示类型
    Blockly.Python['AiMBox_setScreenShowType'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_AiMBox_oled'] = 'i2c = machine.I2C(scl = machine.Pin(22), sda = machine.Pin(21), freq = 100000)\n' +
            'pin26 = machine.Pin(26, machine.Pin.OUT)\n' +
            'pin26.value(0)\n'+
            'pin26.value(1)\n'+
            'oled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let SHOW_TYPE = Blockly.Python.valueToCode(block, 'SHOW_TYPE', Blockly.Python.ORDER_ATOMIC);
        return 'oled.'+SHOW_TYPE+' \n'
    }
    //设置描点
    Blockly.Python['AiMBox_setScreenDrawDot'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_AiMBox_oled'] = 'i2c = machine.I2C(scl = machine.Pin(22), sda = machine.Pin(21), freq = 100000)\n' +
            'pin26 = machine.Pin(26, machine.Pin.OUT)\n' +
            'pin26.value(0)\n'+
            'pin26.value(1)\n'+
            'oled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC);
        let LUMINANCE = Blockly.Python.valueToCode(block, 'LUMINANCE', Blockly.Python.ORDER_ATOMIC);
        return 'oled.pixel('+COORDINATE_X+','+COORDINATE_Y+','+LUMINANCE+')\n'
    }
    //屏幕画线
    Blockly.Python['AiMBox_setScreenDrawLine'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_AiMBox_oled'] = 'i2c = machine.I2C(scl = machine.Pin(22), sda = machine.Pin(21), freq = 100000)\n' +
            'pin26 = machine.Pin(26, machine.Pin.OUT)\n' +
            'pin26.value(0)\n'+
            'pin26.value(1)\n'+
            'oled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let COORDINATE_X1 = Blockly.Python.valueToCode(block, 'COORDINATE_X1', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y1 = Blockly.Python.valueToCode(block, 'COORDINATE_Y1', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_X2 = Blockly.Python.valueToCode(block, 'COORDINATE_X2', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y2 = Blockly.Python.valueToCode(block, 'COORDINATE_Y2', Blockly.Python.ORDER_ATOMIC);
        let ACTION = Blockly.Python.valueToCode(block, 'ACTION', Blockly.Python.ORDER_ATOMIC);
        return 'oled.line('+COORDINATE_X1+', '+COORDINATE_Y1+', '+COORDINATE_X2+ ', '+COORDINATE_Y2+ ', '+ACTION+') \n'
    }
    //屏幕画水平或垂直线
    Blockly.Python['AiMBox_setScreenDrawDirectionLine'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_AiMBox_oled'] = 'i2c = machine.I2C(scl = machine.Pin(22), sda = machine.Pin(21), freq = 100000)\n' +
            'pin26 = machine.Pin(26, machine.Pin.OUT)\n' +
            'pin26.value(0)\n'+
            'pin26.value(1)\n'+
            'oled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC),
            DIRECTION = Blockly.Python.valueToCode(block, 'DIRECTION', Blockly.Python.ORDER_ATOMIC),
            ACTION = Blockly.Python.valueToCode(block, 'ACTION', Blockly.Python.ORDER_ATOMIC),
            LENGTH = Blockly.Python.valueToCode(block, 'LENGTH', Blockly.Python.ORDER_ATOMIC);
        return 'oled.'+DIRECTION+'('+COORDINATE_X+', '+COORDINATE_Y+', '+LENGTH+', '+ACTION+')\n'
    }
    //屏幕画矩形
    Blockly.Python['AiMBox_setScreenDrawRectangle'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_AiMBox_oled'] = 'i2c = machine.I2C(scl = machine.Pin(22), sda = machine.Pin(21), freq = 100000)\n' +
            'pin26 = machine.Pin(26, machine.Pin.OUT)\n' +
            'pin26.value(0)\n'+
            'pin26.value(1)\n'+
            'oled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC),
            ACTION = Blockly.Python.valueToCode(block, 'ACTION', Blockly.Python.ORDER_ATOMIC),
            TYPE = Blockly.Python.valueToCode(block, 'TYPE', Blockly.Python.ORDER_ATOMIC),
            HEIGHT = Blockly.Python.valueToCode(block, 'HEIGHT', Blockly.Python.ORDER_ATOMIC),
            WIDTH = Blockly.Python.valueToCode(block, 'WIDTH', Blockly.Python.ORDER_ATOMIC);
        return 'oled.'+TYPE+'('+COORDINATE_X+', '+COORDINATE_Y+', '+WIDTH+', '+HEIGHT+', '+ACTION+')\n'
    }
    //屏幕画圆形
    Blockly.Python['AiMBox_setScreenDrawCircular'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_AiMBox_oled'] = 'i2c = machine.I2C(scl = machine.Pin(22), sda = machine.Pin(21), freq = 100000)\n' +
            'pin26 = machine.Pin(26, machine.Pin.OUT)\n' +
            'pin26.value(0)\n'+
            'pin26.value(1)\n'+
            'oled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC),
            ACTION = Blockly.Python.valueToCode(block, 'ACTION', Blockly.Python.ORDER_ATOMIC),
            TYPE = Blockly.Python.valueToCode(block, 'TYPE', Blockly.Python.ORDER_ATOMIC),
            RADIUD = Blockly.Python.valueToCode(block, 'RADIUD', Blockly.Python.ORDER_ATOMIC);
        return 'oled.'+TYPE+'('+COORDINATE_X+', '+COORDINATE_Y+', '+RADIUD+', '+ACTION+')\n'
    }
    //屏幕画三角形
    Blockly.Python['AiMBox_setScreenDrawTriangle'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_AiMBox_oled'] = 'i2c = machine.I2C(scl = machine.Pin(22), sda = machine.Pin(21), freq = 100000)\n' +
            'pin26 = machine.Pin(26, machine.Pin.OUT)\n' +
            'pin26.value(0)\n'+
            'pin26.value(1)\n'+
            'oled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let COORDINATE_X1 = Blockly.Python.valueToCode(block, 'COORDINATE_X1', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y1 = Blockly.Python.valueToCode(block, 'COORDINATE_Y1', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_X2 = Blockly.Python.valueToCode(block, 'COORDINATE_X2', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y2 = Blockly.Python.valueToCode(block, 'COORDINATE_Y2', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_X3 = Blockly.Python.valueToCode(block, 'COORDINATE_X3', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y3 = Blockly.Python.valueToCode(block, 'COORDINATE_Y3', Blockly.Python.ORDER_ATOMIC),
            ACTION = Blockly.Python.valueToCode(block, 'ACTION', Blockly.Python.ORDER_ATOMIC),
            TYPE = Blockly.Python.valueToCode(block, 'TYPE', Blockly.Python.ORDER_ATOMIC);
        return 'oled.'+TYPE+'('+COORDINATE_X1+', '+COORDINATE_Y1+', '+COORDINATE_X2+', '+COORDINATE_Y2+', '+COORDINATE_X3+', '+COORDINATE_Y3+', '+ACTION+')\n'
    }
    // 屏幕显示内容
    Blockly.Python['AiMBox_setOLEDScreenShow'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_AiMBox_oled'] = 'i2c = machine.I2C(scl = machine.Pin(22), sda = machine.Pin(21), freq = 100000)\n' +
            'pin26 = machine.Pin(26, machine.Pin.OUT)\n' +
            'pin26.value(0)\n'+
            'pin26.value(1)\n'+
            'oled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        return 'oled.show()\n'
    }
    // 设置三色LED灯
    Blockly.Python['AiMBox_setRGBLight'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let color = Blockly.Python.valueToCode(block, "COLOR", Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let PIN_arr = ['12','13','15'], pwm_init_str = '';
        let RGB_arr = colorHexToRGB(color).slice(1,-1).split(',');
        for (let i=0;i<PIN_arr.length;i++){
            pwm_init_str += 'pwm'+PIN_arr[i]+' = machine.PWM(machine.Pin('+PIN_arr[i]+'))\n' +
                'pwm'+PIN_arr[i]+'.duty('+RGB_arr[i]+')\n';
                // 'utime.sleep(0.1)\n';
        }
        return pwm_init_str
    }

    //设置数字输出
    Blockly.Python['AiMBox_setDigitalOutput'] = function (block) {
        let Digital_Output = Blockly.Python.valueToCode(block, 'Digital_Output', Blockly.Python.ORDER_ATOMIC);
        let LEVEL = Blockly.Python.valueToCode(block, 'LEVEL', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return 'pin'+Digital_Output+' = machine.Pin('+Digital_Output+',machine.Pin.OUT)\n'+
            'pin'+Digital_Output+'.value('+LEVEL+')\n'
    }

    //读取数字量
    Blockly.Python['AiMBox_loadDigitalInput'] = function(block){
        let Digital_Input = Blockly.Python.valueToCode(block, 'Digital_Input', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let functionName = Blockly.Python.provideFunction_(
            'digitalRead', ['def '+ Blockly.Python.FUNCTION_NAME_PLACEHOLDER_+'(p):',
                '  pin0 = machine.Pin(p, machine.Pin.IN)',
                '  return pin0.value()']
        )
        let code = functionName+'('+Digital_Input+')'
        return [code]
    }

    //设置模拟输出
    Blockly.Python['AiMBox_setAnalogOutput'] = function(block){
        let Analog_Output = Blockly.Python.valueToCode(block, 'Analog_Output', Blockly.Python.ORDER_ATOMIC);
        let NUM = Blockly.Python.valueToCode(block, 'NUM', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return'dac'+Analog_Output+' = machine.DAC(machine.Pin('+Analog_Output+'))\n' +
            'dac'+Analog_Output+'.write('+NUM+')\n'
    }

    //读取模拟量
    Blockly.Python['AiMBox_loadAnalogInput'] = function(block){
        let Analog_Input = Blockly.Python.valueToCode(block, 'Analog_Input', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let functionName = Blockly.Python.provideFunction_(
            'analogRead',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(p):',
                '  adc = machine.ADC(machine.Pin(p))',
                '  adc.atten(machine.ADC.ATTN_11DB)',
                '  return adc.read()']);
        let code = functionName+'('+Analog_Input+')'
        return [code]
    }

    //init mqtt server
    Blockly.Python['AiMBox_initMqttServer'] = function(block){
        Blockly.Python.definitions_['import_SCBoard_app'] = 'import SCBoard_app';
        let WIFI_NAME = Blockly.Python.valueToCode(block, 'WIFI_NAME', Blockly.Python.ORDER_ATOMIC),
            WIFI_PASSWORD = Blockly.Python.valueToCode(block, 'WIFI_PASSWORD', Blockly.Python.ORDER_ATOMIC),
            DEVICE_ID = Blockly.Python.valueToCode(block, 'DEVICE_ID', Blockly.Python.ORDER_ATOMIC),
            NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC)),
            password = '  print('+NAME+')\n' +
                '  global board_app\n',
            SUBSTACK = Blockly.Python.statementToCode(block, 'SUBSTACK'),
            pass = password+Blockly.Python.addLoopTrap(SUBSTACK, block.id) || password;
        return 'def app_con_data('+NAME+'):\n' +pass+'\n'+'board_app = SCBoard_app.SCBoard_app('+WIFI_NAME+','+WIFI_PASSWORD+','+DEVICE_ID+')\n' +
            'board_app.call_app_data(app_con_data)\n'
    }

    // 一键式连接wifi
    Blockly.Python['AiMBox_oneClickConnectToWifi'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_utime'] = 'import utime';
        let NAME = Blockly.Python.valueToCode(block, 'WIFI_NAME', Blockly.Python.ORDER_ATOMIC);
        let PASSWORD = Blockly.Python.valueToCode(block, 'WIFI_PASSWORD', Blockly.Python.ORDER_ATOMIC);
        let functionName = Blockly.Python.provideFunction_(
            'connectToWifi',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  wlan = network.WLAN(network.STA_IF)',
                '  wlan.active(True)',
                '  wlan.connect('+NAME+','+PASSWORD+')',
                '  for i in range(60):',
                '    if wlan.isconnected():',
                '      print("连接到wifi成功")',
                '      break;',
                '    utime.sleep(1)',
                '    print("正在连接wifi...")',
                '  if not wlan.isconnected():',
                '    print("连接wifi失败！")'
            ]);
        return [functionName+'()\n']
    }


    // 初始化wlan设置wifi模式为
    Blockly.Python['AiMBox_wlanInit'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let WLANIF = Blockly.Python.valueToCode(block, 'WLANIF', Blockly.Python.ORDER_ATOMIC);
        console.log(WLANIF)
        return 'wlan = network.WLAN(network.'+WLANIF+')\n'
    }

    // wlan设置状态
    Blockly.Python['AiMBox_wlanSetActive'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let ACTIVE = Blockly.Python.valueToCode(block, 'ACTIVE', Blockly.Python.ORDER_ATOMIC);
        return 'wlan.active('+ACTIVE+')\n'
    }
    // wlan获取连接状态
    Blockly.Python['AiMBox_wlanGetActive'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        return ['wlan.active()', Blockly.Python.ORDER_ATOMIC]
    }
    // wlan扫描可用wifi
    Blockly.Python['AiMBox_wlanGetUsableWifi'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let WLANINFO = Blockly.Python.valueToCode(block, 'WLANINFO', Blockly.Python.ORDER_ATOMIC).replace(/\s*/g,'');
        return ['wlan.scan()'+WLANINFO]
    }

    // wlan连接wifi
    Blockly.Python['AiMBox_wlanConnectWifi'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let WIFINAME = Blockly.Python.valueToCode(block, 'WIFINAME', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let WIFIPASSWORD = Blockly.Python.valueToCode(block, 'WIFIPASSWORD', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return 'wlan.connect("'+WIFINAME+'","'+WIFIPASSWORD+'")\n'
    }

    // 获取wifi连接状态
    Blockly.Python['AiMBox_wlanWifiGetActive'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        return ['wlan.isconnected()', Blockly.Python.ORDER_ATOMIC]
    }

    // 获取wifi的信息
    Blockly.Python['AiMBox_wlanWifigetInfo'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let WIFIINFO = Blockly.Python.valueToCode(block, 'WIFIINFO', Blockly.Python.ORDER_ATOMIC);
        let code = 'wlan.ifconfig()['+WIFIINFO+']'
        return [code]
    }

    // 获取wifi的配置信息
    Blockly.Python['AiMBox_wlanWifigetConfigInfo'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let WLANWIFICONFIG = Blockly.Python.valueToCode(block, 'WLANWIFICONFIG', Blockly.Python.ORDER_ATOMIC);
        let code = 'wlan.config(\"'+WLANWIFICONFIG+'\")'
        return [code]

    }

    // wifi断开连接
    Blockly.Python['AiMBox_wlanDisconnectWifi'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        return 'wlan.disconnect()\n'

    }
    //获取wifi接入点名称或通道
    Blockly.Python['AiMBox_wlanSetConfigt'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let ESSID = Blockly.Python.valueToCode(block, 'ESSID', Blockly.Python.ORDER_ATOMIC);
        let CHANNEL = Blockly.Python.valueToCode(block, 'CHANNEL', Blockly.Python.ORDER_ATOMIC);
        return 'wlan.config(essid = '+ESSID+', channel = '+CHANNEL+')\n'

    }

    // socket创建套接字
    Blockly.Python['AiMBox_socketCreat'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let SOCK = Blockly.Python.valueToCode(block, 'SOCK', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let SOCKTYPE = Blockly.Python.valueToCode(block, 'SOCKTYPE', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return SOCK+' = socket.socket(socket.'+SOCKTYPE+')\n'

    }
    // 将套接字绑定地址
    Blockly.Python['AiMBox_socketBindAddress'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let SOCK = Blockly.Python.valueToCode(block, 'SOCK', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let ADDRESS = Blockly.Python.valueToCode(block, 'ADDRESS', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let PORT = Blockly.Python.valueToCode(block, 'PORT', Blockly.Python.ORDER_ATOMIC);
        return SOCK+'.bind(("'+ADDRESS+'", '+PORT+'))\n'

    }
    // 将套接字连接地址
    Blockly.Python['AiMBox_socketConnectAddress'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let SOCK = Blockly.Python.valueToCode(block, 'SOCK', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let ADDRESS = Blockly.Python.valueToCode(block, 'ADDRESS', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let PORT = Blockly.Python.valueToCode(block, 'PORT', Blockly.Python.ORDER_ATOMIC);
        return SOCK+'.connect(("'+ADDRESS+'", '+PORT+'))\n'

    }
    // 开始监听传入连接
    Blockly.Python['AiMBox_socketListen'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let SOCK = Blockly.Python.valueToCode(block, 'SOCK', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let NUM = Blockly.Python.valueToCode(block, 'NUM', Blockly.Python.ORDER_ATOMIC);
        return SOCK+'.listen('+NUM+')\n'

    }
    // 接受连接并返回套接字对象
    Blockly.Python['AiMBox_socketaccept'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let SOCK = Blockly.Python.valueToCode(block, 'SOCK', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return [SOCK+'.accept()']

    }
    // 接受套接字的数据TCP
    Blockly.Python['AiMBox_socketTCPConnRecv'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let CONN = Blockly.Python.valueToCode(block, 'CONN', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let COUNT = Blockly.Python.valueToCode(block, 'COUNT', Blockly.Python.ORDER_ATOMIC);
        return [CONN+'.recv('+COUNT+')']

    }

    // [CONN] 发送数据TCP
    Blockly.Python['AiMBox_socketTCPSend'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let CONN = Blockly.Python.valueToCode(block, 'CONN', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let DATA = Blockly.Python.valueToCode(block, 'DATA', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return CONN+'.send('+DATA+')\n'

    }

    // 接受套接字的数据UDP
    Blockly.Python['AiMBox_socketUDPConnRecv'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let CONN = Blockly.Python.valueToCode(block, 'CONN', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let COUNT = Blockly.Python.valueToCode(block, 'COUNT', Blockly.Python.ORDER_ATOMIC);
        return [CONN+'.recvfrom('+COUNT+')']

    }

    // [CONN] 发送数据UDP
    Blockly.Python['AiMBox_socketTXPSend'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let CONN = Blockly.Python.valueToCode(block, 'CONN', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let DATA = Blockly.Python.valueToCode(block, 'DATA', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let ADDRESS = Blockly.Python.valueToCode(block, 'ADDRESS', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let PORT = Blockly.Python.valueToCode(block, 'PORT', Blockly.Python.ORDER_ATOMIC);
        return CONN+'.sendto('+DATA+',("'+ADDRESS+'", '+PORT+'))'

    }

    // conn关闭连接
    Blockly.Python['AiMBox_socketclose'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let CONN = Blockly.Python.valueToCode(block, 'CONN', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return CONN+'.close()'
    }

    // mqtt 发送消息
    Blockly.Python['AiMBox_MqttSendMessage'] = function(block){
        let MESSAGE =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MESSAGE', Blockly.Python.ORDER_ATOMIC));
        let MSG_NAME =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MSG_NAME', Blockly.Python.ORDER_ATOMIC));
        return 'board_app.app_senddata('+MSG_NAME+', '+MESSAGE+')\n'
    }


    // OLED 清屏
    Blockly.Python['AiMBox_clearOLED'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_AiMBox_oled'] = 'i2c = machine.I2C(scl = machine.Pin(22), sda = machine.Pin(21), freq = 100000)\n' +
            'pin26 = machine.Pin(26, machine.Pin.OUT)\n' +
            'pin26.value(0)\n'+
            'pin26.value(1)\n'+
            'oled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        return 'oled.show_fill(0)\n'
    }

    // 定义变量
    Blockly.Python['AiMBox_setVariable'] = function (block) {
        let NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC));
        let VALUE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC));
        return NAME+' = '+VALUE+'\n';
    };
    // 设置全局变量
    Blockly.Python['AiMBox_setVariableGlobal'] = function (block) {
        let NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC));
        return 'global '+NAME+'\n';
    };
    // jsonLoadsValue
    Blockly.Python['AiMBox_jsonLoadsValue'] = function (block) {
        Blockly.Python.definitions_['import_json'] = 'import json';
        let VALUE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC));
        let NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC));
        return NAME+' = json.loads('+VALUE+')\n';
    };
    // getDictionaryValue
    Blockly.Python['AiMBox_getDictionaryValue'] = function (block) {
        let DICTIONARY = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'DICTIONARY', Blockly.Python.ORDER_ATOMIC));
        let KEY = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'KEY', Blockly.Python.ORDER_ATOMIC));
        return [DICTIONARY+'['+KEY+']'];
    };
    // 获取变量
    Blockly.Python['AiMBox_getVariableName'] = function(block){
        return [Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"")];
    }
    // 变量转字符串
    Blockly.Python['AiMBox_changeVariableToString'] = function(block){
        let Variable =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC));
        return ['str('+Variable+')']
    }
    // 风扇电机速度
    Blockly.Python['AiMBox_setMotorFreqDuty'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let DUTY =  Blockly.Python.valueToCode(block, 'DUTY', Blockly.Python.ORDER_ATOMIC),
            FREQ =  Blockly.Python.valueToCode(block, 'FREQ', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_fan_module'] = 'pwm14 = machine.PWM(machine.Pin(14), freq='+FREQ+')\n';
        return 'pwm14.duty('+DUTY+')\n'
    }

    //约束取值范围
    Blockly.Python['AiMBox_constraintVariable'] = function(block){
        let VALUE =  Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC),
            MIN =  Blockly.Python.valueToCode(block, 'MIN', Blockly.Python.ORDER_ATOMIC),
            MAX =  Blockly.Python.valueToCode(block, 'MAX', Blockly.Python.ORDER_ATOMIC);
        return ['min(max('+VALUE+', '+MIN+'), '+MAX+')']
    }
    //字符串分割
    Blockly.Python['AiMBox_textSplit'] = function(block){
        let TEXT =  Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        let DELIMITER =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'DELIMITER', Blockly.Python.ORDER_ATOMIC));
        let index = Blockly.Python.valueToCode(block, 'INDEX', Blockly.Python.ORDER_ATOMIC);
        return [TEXT+'.split('+DELIMITER+')['+index+']']
    }
    // 串口写数据
    Blockly.Python['AiMBox_uartWrite'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let BYTES =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'BYTES', Blockly.Python.ORDER_ATOMIC));
        return 'uart.write('+BYTES+')\n'
    }
    // OLED 显示第几行
    Blockly.Python['AiMBox_setOLEDShowRows'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_AiMBox_oled'] = 'i2c = machine.I2C(scl = machine.Pin(22), sda = machine.Pin(21), freq = 100000)\n' +
            'pin26 = machine.Pin(26, machine.Pin.OUT)\n' +
            'pin26.value(0)\n'+
            'pin26.value(1)\n'+
            'oled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let ROW = Blockly.Python.valueToCode(block, 'ROW', Blockly.Python.ORDER_ATOMIC);
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        return 'oled.text('+TEXT+',0,'+ROW+')\n'
    }
    // OLED 清除第几行
    Blockly.Python['AiMBox_setOLEDClearRows'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        Blockly.Python.definitions_['import_AiMBox_oled'] = 'i2c = machine.I2C(scl = machine.Pin(22), sda = machine.Pin(21), freq = 100000)\n' +
            'pin26 = machine.Pin(26, machine.Pin.OUT)\n' +
            'pin26.value(0)\n'+
            'pin26.value(1)\n'+
            'oled = ssd1306.SSD1306_I2C(128, 64, i2c)\n';
        let ROW = Blockly.Python.valueToCode(block, 'ROW', Blockly.Python.ORDER_ATOMIC);
        return 'oled.fill_rect(0,'+ROW+',128,16,0)\n'
    }
    // mqtt发送消息字符串
    Blockly.Python['AiMBox_MqttSendMessageStr'] = function(block){
        let MESSAGE =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MESSAGE', Blockly.Python.ORDER_ATOMIC));
        return 'board_app.app_senddata_sendmsg('+MESSAGE+')\n'
    }
    Blockly.Python['AiMBox_addMqttTheme'] = function(block){
        let NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC));
        let FUNCTION = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'FUNCTION', Blockly.Python.ORDER_ATOMIC));
        let MSG = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MSG', Blockly.Python.ORDER_ATOMIC));
        let SUBSTACK = Blockly.Python.statementToCode(block, 'SUBSTACK');
        let pass = Blockly.Python.addLoopTrap(SUBSTACK, block.id);
        return 'def '+FUNCTION+'(topic,'+MSG+'):\n' +
            '  print("'+FUNCTION+'  topic:" + str(topic) + "  msg:" + str('+MSG+'))\n' +pass+'\n'+
            'board_app.mqtt_subscribe('+NAME+','+FUNCTION+')\n'
    }
    Blockly.Python['AiMBox_MqttSendCustomMessage'] = function(block){
        let MSG_NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MSG_NAME', Blockly.Python.ORDER_ATOMIC));
        let MESSAGE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MESSAGE', Blockly.Python.ORDER_ATOMIC));
        return 'board_app.app_senddata_self('+MSG_NAME+','+MESSAGE+')\n'
    }
    Blockly.Python['IoTb_MqttIsConnect'] = function(block){
        return ['board_app.state']
    }

}
