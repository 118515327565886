exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n.button_outlined-button_2f510 {\r\n    cursor: pointer;\r\n    border-radius: calc(0.5rem / 2);\r\n    font-weight: bold;\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-orient: horizontal;\r\n    -webkit-box-direction: normal;\r\n    -webkit-flex-direction: row;\r\n        -ms-flex-direction: row;\r\n            flex-direction: row;\r\n    -webkit-box-align: center;\r\n    -webkit-align-items: center;\r\n        -ms-flex-align: center;\r\n            align-items: center;\r\n    padding-left: .75rem;\r\n    padding-right: .75rem;\r\n    -webkit-user-select: none;\r\n       -moz-user-select: none;\r\n        -ms-user-select: none;\r\n            user-select: none;\r\n}\n\n.button_icon_JhCuM {\r\n    height: 1.5rem;\r\n}\n\n[dir=\"ltr\"] .button_icon_JhCuM {\r\n    margin-right: .5rem;\r\n}\n\n[dir=\"rtl\"] .button_icon_JhCuM {\r\n    margin-left: .5rem;\r\n}\n\n.button_content_3y79K {\r\n    white-space: nowrap;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"outlined-button": "button_outlined-button_2f510",
	"outlinedButton": "button_outlined-button_2f510",
	"icon": "button_icon_JhCuM",
	"content": "button_content_3y79K"
};