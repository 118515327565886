exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".player_stage-only_3sowo {\r\n    width: calc(480px + 1rem);\r\n}\r\n\r\n.player_editor_pHkoy {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    height: 100%;\r\n    width: 100%;\r\n}\r\n\r\n.player_stage-only_3sowo * {\r\n    -webkit-box-sizing: border-box;\r\n            box-sizing: border-box;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"stage-only": "player_stage-only_3sowo",
	"stageOnly": "player_stage-only_3sowo",
	"editor": "player_editor_pHkoy"
};