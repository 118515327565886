exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*$ui-primary: hsla(215, 100%, 95%, 1); !* #E5F0FF *!*/\n/* #E5F0FF */\n/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #FF4254 主题颜色 */\n/* #3373CC */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #E53C4C */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.turbo-mode_turbo-container_1qQFO {\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-align: center;\r\n    -webkit-align-items: center;\r\n        -ms-flex-align: center;\r\n            align-items: center;\r\n    padding: 0.25rem;\r\n    -webkit-user-select: none;\r\n       -moz-user-select: none;\r\n        -ms-user-select: none;\r\n            user-select: none;\r\n}\n.turbo-mode_turbo-icon_A_vJA {\r\n    margin: 0.25rem;\r\n}\n.turbo-mode_turbo-label_1v23d {\r\n    font-size: 0.625rem;\r\n    font-weight: bold;\r\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\r\n    color: hsla(38, 100%, 55%, 1);\r\n    white-space: nowrap;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"turbo-container": "turbo-mode_turbo-container_1qQFO",
	"turboContainer": "turbo-mode_turbo-container_1qQFO",
	"turbo-icon": "turbo-mode_turbo-icon_A_vJA",
	"turboIcon": "turbo-mode_turbo-icon_A_vJA",
	"turbo-label": "turbo-mode_turbo-label_1v23d",
	"turboLabel": "turbo-mode_turbo-label_1v23d"
};