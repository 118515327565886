import styles from "../components/menu-bar/menu-bar.css";
import loadingGif from "../components/menu-bar/loading.gif";
import ReactDOM from "react-dom";
import React from 'react';

const showLoadingProcess = tip => {
    var loadingProgressElement = document.getElementById('loadingSpin')
    loadingProgressElement.style.display = 'table-cell';
    let LoadingSpin = <div className={styles.loadingProcessBar}>
        <img src={loadingGif}/>
        <p className={styles.loadingText}>{tip}</p>
    </div>;

    ReactDOM.render(LoadingSpin, loadingProgressElement);
    document.querySelectorAll("button").forEach(e=>{
        e.disabled = true;
    })
    document.querySelectorAll("li").forEach(e=>{
        e.style.pointerEvents = 'none'
    })
    let disableElementArray = ['menuSerial','menuFirmware']
    disableElementArray.forEach(e=>{
        let eObject = document.getElementById(e)
        eObject.style.pointerEvents = 'none'
        eObject.style.color = '#e9e7ef'
    })
}

const clearLoadingProcess = () =>{
    document.getElementById('loadingSpin').style.display = 'none';
    document.querySelectorAll("button").forEach(e=>{
        e.disabled = false;
    })
    document.querySelectorAll("li").forEach(e=>{
        e.style.pointerEvents = 'auto'
    })
    let disableElementArray = ['menuSerial','menuFirmware']
    disableElementArray.forEach(e=>{
        var eObject = document.getElementById(e)
        eObject.style.pointerEvents = 'auto'
        eObject.style.color = '#FFFFFF'
    })
}

export {
    showLoadingProcess,
    clearLoadingProcess
}
