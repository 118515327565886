// by Asan 2020/12/1
import {replaceQuotationMarks} from '../common.js'

export default function (Blockly) {
    Blockly.Python['procedures_definition'] = function(block) {
        let procCode = block.getInput('custom_block').connection.targetBlock().procCode_;
        let displayNames_ = block.getInput('custom_block').connection.targetBlock().displayNames_;
        let funcName = procCode.split('%')[0].replace(/(^\s*)|(\s*$)/g, "");
        let defStr = 'def '+funcName+'(';
        for(let i=0;i<displayNames_.length;i++){
            if (i === displayNames_.length-1){
                defStr += displayNames_[i]
            }else{
                defStr += displayNames_[i]+','
            }
        }
        defStr += '):\n'
        defStr = Blockly.Python.scrub_(block, defStr);
        let defStrArr = defStr.split('\n');
        let code = '';
        for (let i = 0; i<defStrArr.length;i++){
            code += defStrArr[i]+'\n  '
        }
        Blockly.Python.definitions_['def'+funcName] = code
        return null
    };
    Blockly.Python['argument_reporter_boolean'] = function(block) {
        return block.getFieldValue('VALUE');
    };
    Blockly.Python['argument_reporter_string_number'] = function(block) {
        let value = block.getFieldValue('VALUE');
        return [value];
    };
    Blockly.Python['argument_editor_string_number'] = function(block) {
        let text = block.getFieldValue('TEXT');
        return [text];
    };
    Blockly.Python['argument_editor_boolean'] = function(block) {
        return block.getFieldValue('TEXT');
    };
    Blockly.Python['procedures_call'] = function(block) {
        let procComponents = this.procCode_.split(/(?=[^\\]%[nbs])/);
        procComponents = procComponents.map(function(c) {
            return c.trim(); // 去掉空格
        });
        let argumentTypeArray = []
        for(let i = 0, component; i<procComponents.length-1,component = procComponents[i]; i++){
            if (component.substring(0, 1) === '%') {
                let argumentType = component.substring(1,2)
                if (argumentType === 'n' || argumentType === 'b' || argumentType === 's'){
                    argumentTypeArray.push(argumentType)
                }
            }
        }
        let defStr = this.procCode_.split('%')[0].replace(/(^\s*)|(\s*$)/g, "")+'('
        for (let i = 0; i < block.argumentIds_.length; i++) {
            let arg;
            if (argumentTypeArray[i] === 'b'){
                arg = Blockly.Python.statementToCode(block, block.argumentIds_[i]) || `''`
            }else{
                arg = replaceQuotationMarks(Blockly.Python.valueToCode(block, block.argumentIds_[i], Blockly.Python.ORDER_NONE) )|| `''`;
            }
            if (i === block.argumentIds_.length-1){
                defStr += arg
            }else {
                defStr += arg+','
            }
        }
        defStr += ')\n'
        return defStr
    };
}
