const UPDATE_CODE = 'scratch-gui/generator/UPDATE_CODE';

const initialState = {
    code:''
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case UPDATE_CODE:
            return Object.assign({}, state, {
                code: action.code
            });
        default:
            return state;
    }
};

const updateBlockCode = function (code) {
    return {
        type: UPDATE_CODE,
        code: code
    };
};

export {
    reducer as default,
    initialState as generatorCodeInitialState,
    updateBlockCode
};
