import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './menu.css';

const MenuComponent = ({
    className = '',
    children,
    componentRef,
    place = 'right'
}) => (
    <ul
        className={classNames(
            styles.menu,
            className,
            {
                [styles.left]: place === 'left',
                [styles.right]: place === 'right'
            }
        )}
        ref={componentRef}
    >
        {children}
    </ul>
);

MenuComponent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    componentRef: PropTypes.func,
    place: PropTypes.oneOf(['left', 'right'])
};


const MenuItem = ({
    children,
    className,
    onClick,
}) => (
    <li
        className={classNames(
            styles.menuItem,
            styles.hoverable,
            className
        )}
        onClick={onClick}
    >
        {children}
    </li>
);

MenuItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func
};
// 是否是web版
window.isWebVersion = 1

class SerialSelectMenu extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            promptWord: '搜索连接程序',
        }
    }

    // 循环遍历端口状态(global.comState) 并将 其他非点击的端口显示状态 对号去掉
    onPortClick(option){
        // option 为选中的串口 COM号
        let command = option;
        for (let port in global.comState){
            if (option === port){
                // 如果重复点击 则关闭该串口
                if (global.comState[option] === true){
                    command = 'close';
                    global.comState[option] = false;
                }
                break;
            }
        }
        // console.log('port click', option, global.comState)
        // 端口点击后将状态存入global.comState
        this.props.onConnectSerial(command);
        this.props.onMenuClose()
    }
    // websocket 连接测试
    linkConnect(){
        if (window.ws_client != 1){
            window.ws_client = new WebSocket('ws://localhost:21322');
        }
        let that = this;
        this.setState({
            promptWord: '连接程序搜索中'
        })
        setTimeout(function () {
            console.log('window.ws_client.readyState :', window.ws_client.readyState )
            if (window.ws_client.readyState === 0){
                alert('连接不到串口助手！')
            }else{
                location.reload();
            }
            that.props.onMenuClose();
        },1000)
    }

    render(){
        // 没有串口列表显示暂无可用串口
        if (global.COMPorts.length === 0){
            if (window.isWebVersion)
            return <div>

                <li className={[`${styles.menuItem}`,`${styles.hoverable}`].join(' ')}
                    onClick={(e)=>this.linkConnect(e)}
                >
                    {this.state.promptWord}
                </li>
            </div>
            else
                return <div>
                    <li className={[`${styles.menuItem}`,`${styles.hoverable}`].join(' ')}
                    >
                        暂无可用串口
                    </li>
                </div>
        }
        return <div>
            {global.COMPorts.map((option,index) => (
                <li
                    className={[`${styles.menuItem}`,`${styles.hoverable}`].join(' ')}
                    key={index}
                    value={option}
                    onClick={(e)=>this.onPortClick(option,e)}
                >{option}
                    <div style={{float:'right', color:'#F1F1F1'}}>{global.comState[option] === true ? '✓': null}</div>
                </li>
            ))} </div>
    }
}


const addDividerClassToFirstChild = (child, id) => (
    child && React.cloneElement(child, {
        className: classNames(
            child.className,
            {[styles.menuSection]: id === 0}
        ),
        key: id
    })
);

const MenuSection = ({children}) => (
    <React.Fragment>{
        React.Children.map(children, addDividerClassToFirstChild)
    }</React.Fragment>
);

MenuSection.propTypes = {
    children: PropTypes.node
};

export {
    MenuComponent as default,
    MenuItem,
    MenuSection,
    SerialSelectMenu,
};
