import React from 'react'
import classNames from 'classnames';
import styles from './modal.css';
import bindAll from 'lodash.bindall';
import closeImg from "../code-editor-wrap/close.svg";
import versionInfo from './version-info';
var items = [];
for (var i = 0; i < versionInfo.length; i++) {
    items.push(<p className={styles.pLine} style={{fontSize:15}} key={i}>{versionInfo[i]}</p>);
}

class VersionInfoModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'closeModal',
        ]);
        this.state = {
            showModal: false,  // 用于设置模态框的显示隐藏
        };
    }

    closeModal(){
        document.getElementById('version-info-modal').style.display = 'none'
    }

    componentDidMount(){
        // this.props.onRef(this);
    }

    render() {
        return (
            <div id="version-info-modal" className={styles.manualDialog}>
                <div className={styles.modalTitle}>
                    <div className={styles.headerLeft}>
                        <label className={styles.modalHeaderLab}>版本信息</label>
                    </div>

                    <div className={styles.headerRight}>
                        <button className={styles.closeBtn} onClick={this.closeModal}>
                            <img src={closeImg}/>
                        </button>
                    </div>
                </div>
                <div className={styles.manualContent}>
                    <div className={styles.textContent}>
                        <p className={styles.pLine}>当前版本更新内容：</p>
                        {items}
                    </div>
                </div>
            </div>
        );
    }
}

export default VersionInfoModal;
