import {regQuotationMarks,replaceQuotationMarks, colorHexToRGB} from '../common.js'

export default function (Blockly) {
    // 开关下拉框
    Blockly.Python['AiGBox_menu_SWITCH_MENU'] = function(block) {
        let SWITCH = block.getFieldValue('SWITCH_MENU');
        return [SWITCH];
    }
    // 发音人下拉框
    Blockly.Python['AiGBox_menu_PER_MENU'] = function(block) {
        let PER = block.getFieldValue('PER_MENU');
        return [PER];
    }
    // 语速下拉框
    Blockly.Python['AiGBox_menu_SPD_MENU'] = function(block) {
        let SPD = block.getFieldValue('SPD_MENU');
        return [SPD];
    }
    // 音调下拉框
    Blockly.Python['AiGBox_menu_PIT_MENU'] = function(block) {
        let PIT = block.getFieldValue('PIT_MENU');
        return [PIT];
    }
    // 音量下拉框
    Blockly.Python['AiGBox_menu_VOL_MENU'] = function(block) {
        let VOL = block.getFieldValue('VOL_MENU');
        return [VOL];
    }
    // 格式下拉框
    Blockly.Python['AiGBox_menu_AUE_MENU'] = function(block) {
        let AUE = block.getFieldValue('AUE_MENU');
        return [AUE];
    }

    // 设备初始化 连接MQTT
    Blockly.Python['AiGBox_deviceInit'] = function (block) {
        let DEVICE_ID = Blockly.Python.valueToCode(block, 'DEVICE_ID', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_AiGBox_init'] = 'import AIGBOX'+DEVICE_ID;
        return '';
    };

    // 打印
    Blockly.Python['AiGBox_printText'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        return 'print(' + TEXT + ')\n';
    };
    // LED灯
    Blockly.Python['AiGBox_LEDLight'] = function (block) {
        let SWITCH = Blockly.Python.valueToCode(block, 'SWITCH', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_RPi.GPIO'] = 'import RPi.GPIO as GPIO';
        Blockly.Python.definitions_['GPIO_init'] = 'GPIO.setwarnings(False)\n' +
            'GPIO.setmode(GPIO.BCM)\n';
        Blockly.Python.definitions_['LED_channel_init'] = 'led_channel =5\n'+
        'GPIO.setup(led_channel, GPIO.OUT)\n';
        return 'GPIO.output(led_channel, '+SWITCH+')\n';
    };
    // 风扇
    Blockly.Python['AiGBox_setMotor'] = function (block) {
        let SWITCH = Blockly.Python.valueToCode(block, 'SWITCH', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_RPi.GPIO'] = 'import RPi.GPIO as GPIO';
        Blockly.Python.definitions_['GPIO_init'] = 'GPIO.setwarnings(False)\n' +
            'GPIO.setmode(GPIO.BCM)\n';
        Blockly.Python.definitions_['motor_channel_init'] = 'motor_channel =23\n'+
            'GPIO.setup(motor_channel, GPIO.OUT)\n';
        return 'GPIO.output(motor_channel, '+SWITCH+')\n';
    };
    //舵机
    Blockly.Python['AiGBox_setServoAngle'] = function (block) {
        let ANGLE = Blockly.Python.valueToCode(block, 'ANGLE', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_sleep'] = 'from time import sleep';
        Blockly.Python.definitions_['import_RPi.GPIO'] = 'import RPi.GPIO as GPIO';
        Blockly.Python.definitions_['GPIO_init'] = 'GPIO.setwarnings(False)\n' +
            'GPIO.setmode(GPIO.BCM)\n';
        Blockly.Python.definitions_['servo_channel_init'] = 'servo_channel =27\n'+
            'GPIO.setup(servo_channel, GPIO.OUT)\n';
        let functionName = Blockly.Python.provideFunction_(
            'setServoAngle',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  assert '+ANGLE+' >=0 and '+ANGLE+' <= 360',
                '  pwm = GPIO.PWM(servo_channel, 50)',
                '  pwm.start(8)',
                '  dutyCycle = '+ANGLE+' / 18. + 3.',
                '  pwm.ChangeDutyCycle(dutyCycle)',
                '  sleep(0.3)',
                '  pwm.stop()',
            ]);
        return functionName+'()\n';
    };

    //光敏
    Blockly.Python['AiGBox_loadLightSensorValue'] = function (block) {
        Blockly.Python.definitions_['import_RPi.GPIO'] = 'import RPi.GPIO as GPIO';
        Blockly.Python.definitions_['import_time'] = 'import time';
        Blockly.Python.definitions_['GPIO_init'] = 'GPIO.setwarnings(False)\n' +
            'GPIO.setmode(GPIO.BCM)\n';
        Blockly.Python.definitions_['time_sleep'] = 'time.sleep(1)\n';
        Blockly.Python.definitions_['TLC549_DOUT'] = 'TLC549_DOUT=16\n'+
            'TLC549_CLK=13\n'+'TLC549_CS=12\n';
        Blockly.Python.definitions_['TLC549_CLK'] = 'GPIO.setup(TLC549_CLK, GPIO.OUT)\n'+
            'GPIO.setup(TLC549_CS, GPIO.OUT)\n'+'GPIO.setup(TLC549_DOUT, GPIO.IN)\n';
        let functionName = Blockly.Python.provideFunction_(
            'loadLightSensorValue',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  GPIO.output(TLC549_CS, GPIO.HIGH)',
                '  time.sleep(0.05)',
                '  GPIO.output(TLC549_CS, GPIO.LOW)',
                '  time.sleep(0.05)',
                '  value=0',
                '  for i in range(0,7):',
                '    GPIO.output(TLC549_CLK, GPIO.HIGH)',
                '    value=value+GPIO.input(TLC549_DOUT)',
                '    value=value*10',
                '    GPIO.output(TLC549_CLK, GPIO.LOW)',
                '  value<<1',
                '  GPIO.output(TLC549_CLK, GPIO.HIGH)',
                '  GPIO.output(TLC549_CLK, GPIO.LOW)',
                '  return value',

            ]);
        let code=functionName+'()';
        return [code];

    };
    //温度
    Blockly.Python['AiGBox_loadTempSensorValue'] = function (block) {
        Blockly.Python.definitions_['import_time'] = 'import time';
        Blockly.Python.definitions_['import_device'] = 'import device';
        Blockly.Python.definitions_['device_file'] = 'device_file =device.device_file';
        let read_temp_raw = Blockly.Python.provideFunction_(
            'read_temp_raw',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  f = open(device_file,\'r\')',
                '  lines = f.readlines()',
                '  f.close()',
                '  return lines',
            ]);
        let read_temp = Blockly.Python.provideFunction_(
            'read_temp',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  lines = '+read_temp_raw+'()',
                '  while lines[0].strip()[-3:] != \'YES\':',
                '    time.sleep(0.2)',
                '    lines = '+read_temp_raw+'()',
                '  equals_pos = lines[1].find(\'t=\')',
                '  if equals_pos != -1:',
                '    temp_string = lines[1][equals_pos+2:]\n',
                '    temp_c = float(temp_string)/1000.0',
                '  return temp_c'
            ]);
        let code =read_temp+'()';
        return [code];
    };
    //湿度
    Blockly.Python['AiGBox_loadHumiditySensor'] = function (block) {
        Blockly.Python.definitions_['import_RPi.GPIO'] = 'import RPi.GPIO as GPIO';
        Blockly.Python.definitions_['import_time'] = 'import time';
        let functionName = Blockly.Python.provideFunction_(
            'loadHumiditySensor',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  channel =24',
                '  data = []',
                '  j = 0',
                '  GPIO.setmode(GPIO.BCM)',
                '  time.sleep(1)',
                '  GPIO.setup(channel, GPIO.OUT)',
                '  GPIO.output(channel, GPIO.LOW)',
                '  time.sleep(0.02)',
                '  GPIO.output(channel, GPIO.HIGH)',
                '  GPIO.setup(channel, GPIO.IN)',
                '  while GPIO.input(channel) == GPIO.LOW:',
                '    continue',
                '  while GPIO.input(channel) == GPIO.HIGH:',
                '    continue',
                '  while j < 40:',
                '    k = 0',
                '    while GPIO.input(channel) == GPIO.LOW:',
                '      continue',
                '    while GPIO.input(channel) == GPIO.HIGH:',
                '      k += 1',
                '      if k > 100:',
                '        break',
                '    if k < 8:',
                '      data.append(0)',
                '    else:',
                '      data.append(1)',
                '    j += 1',
                '  humidity_bit = data[0:8]',
                '  humidity_point_bit = data[8:16]',
                '  temperature_bit = data[16:24]',
                '  temperature_point_bit = data[24:32]',
                '  check_bit = data[32:40]',
                '  humidity = 0',
                '  humidity_point = 0',
                '  temperature = 0',
                '  temperature_point = 0',
                '  check = 0',
                '  for i in range(8):',
                '    humidity += humidity_bit[i] * 2 ** (7-i)',
                '    humidity_point += humidity_point_bit[i] * 2 ** (7-i)',
                '    temperature += temperature_bit[i] * 2 ** (7-i)',
                '    temperature_point += temperature_point_bit[i] * 2 ** (7-i)',
                '    check += check_bit[i] * 2 ** (7-i)',
                '  tmp = humidity + humidity_point + temperature + temperature_point',
                '  if check == tmp:',
                '    return humidity',
                '  else:',
                '    return "wrong"',
                '  GPIO.cleanup()',
            ]);
        let code=functionName+'()';
        return [code];
    };
    //硬件
    Blockly.Python['AiGBox_getHardware'] = function (block) {
        Blockly.Python.definitions_['import_os'] = 'import os';
        let getCPUtemperature = Blockly.Python.provideFunction_(
            'getCPUtemperature',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  res = os.popen(\'vcgencmd measure_temp\').readline()',
                '  return(res.replace("temp=","").replace("\'C\\n",""))',
            ]);
        let getRAMinfo = Blockly.Python.provideFunction_(
            'getRAMinfo',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  p = os.popen(\'free\')',
                '  i = 0',
                '  while 1:',
                '    i = i + 1',
                '    line = p.readline()',
                '    if i==2:',
                '      return(line.split()[1:4])',
            ]);
        let getCPUuse = Blockly.Python.provideFunction_(
            'getCPUuse',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  return(str(os.popen("top -n1 | awk \'/Cpu\\(s\\):/ {print $2}\'").readline().strip()))',
            ]);
        let getDiskSpace = Blockly.Python.provideFunction_(
            'getDiskSpace',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  p = os.popen("df -h /")',
                '  i = 0',
                '  while 1:',
                '    i = i + 1',
                '    line = p.readline()',
                '    if i==2:',
                '      return(line.split()[1:5])',
            ]);
        Blockly.Python.definitions_['CPU'] = 'CPU_temp = getCPUtemperature()\n'+
            'CPU_usage = getCPUuse()\n';
        Blockly.Python.definitions_['RAM'] = 'RAM_stats = getRAMinfo()\n'+
            'RAM_total = round(int(RAM_stats[0]) / 1000,1)\n'+
            'RAM_used = round(int(RAM_stats[1]) / 1000,1)\n'+
            'RAM_free = round(int(RAM_stats[2]) / 1000,1)\n'
        Blockly.Python.definitions_['DISK'] = 'DISK_stats = getDiskSpace()\n'+
            'DISK_total = DISK_stats[0]\n'+
            'DISK_used = DISK_stats[1]\n'+
            'DISK_perc = DISK_stats[3]\n'
        Blockly.Python.definitions_['print'] = 'if __name__ == \'__main__\':\n'+
            '  print(\'\')\n'+
            '  print(\'CPU Temperature = \'+CPU_temp)\n'+
            '  print(\'CPU Use = \'+CPU_usage)\n'+
            '  print(\'\')\n'+
            '  print(\'RAM Total = \'+str(RAM_total)+\' MB\')\n'+
            '  print(\'RAM Used = \'+str(RAM_used)+\' MB\')\n'+
            '  print(\'RAM Free = \'+str(RAM_free)+\' MB\')\n'+
            '  print(\'\')\n'+
            '  print(\'DISK Total Space = \'+str(DISK_total)+\'B\')\n'+
            '  print(\'DISK Used Space = \'+str(DISK_used)+\'B\')\n'+
            '  print(\'DISK Used Percentage = \'+str(DISK_perc))\n'
    };
    // 摄像头
    Blockly.Python['AiGBox_setCamera'] = function (block) {
        let SWITCH = Blockly.Python.valueToCode(block, 'SWITCH', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_picamera'] = 'from picamera import PiCamera';
        Blockly.Python.definitions_['camera'] = 'camera = PiCamera()\n';
        if (SWITCH === 'GPIO.HIGH'){
            return 'camera.start_preview()\n'
        }
        return 'camera.stop_preview()\n'
    };
    // 人体红外
    Blockly.Python['AiGBox_getInfrared'] = function (block) {
        Blockly.Python.definitions_['import_RPi.GPIO'] = 'import RPi.GPIO as GPIO';
        Blockly.Python.definitions_['GPIO_init'] = 'GPIO.setwarnings(False)\n' +
            'GPIO.setmode(GPIO.BCM)\n';
        Blockly.Python.definitions_['Infrared_init'] = 'Infrared=4\n'+
            'GPIO.setup(Infrared, GPIO.IN)\n';
        let code ='GPIO.input(Infrared)';
        return [code];
    };
    // 血氧
    Blockly.Python['AiGBox_loadHeartBloodValue'] = function (block) {
        Blockly.Python.definitions_['import_hrcalc'] = 'import hrcalc';
        Blockly.Python.definitions_['open'] = 'ir = []\n'+
            'with open("ir.log", "r") as f:\n'+
            '  for line in f:\n'+
            '    ir.append(int(line))\n'+
            'red = []\n'+
            'with open("red.log", "r") as f:\n'+
            '  for line in f:\n'+
            '    red.append(int(line))\n'
        let code ='hrcalc.calc_hr_and_spo2(ir[25*count:25*count+100], red[25*count:25*count+100])';
        return [code];
    };
    //RFID
    Blockly.Python['AiGBox_loadRFIDValue'] = function (block) {
        Blockly.Python.definitions_['import_logging'] = 'import logging';
        Blockly.Python.definitions_['import_signal'] = 'import signal';
        Blockly.Python.definitions_['import_sys'] = 'import sys\n';
        Blockly.Python.definitions_['import_time'] = 'from time import sleep';
        Blockly.Python.definitions_['import_MFRC522'] = 'from mfrc522 import MFRC522, PICC_Type, PICC_Command, MIFARE_Key, StatusCode, format_hex';
        Blockly.Python.definitions_['logging'] = 'logging.basicConfig(level=logging.INFO)';
        Blockly.Python.definitions_['run'] = 'run = True';
        let end = Blockly.Python.provideFunction_(
            'end',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(signal, frame):',
                '  global run',
                '  run = False',
                '  sys.exit()',
            ]);
        Blockly.Python.definitions_['rfid_init'] = 'signal.signal(signal.SIGINT, end)\n'+
            'rfid = MFRC522()\n'+
            'key = MIFARE_Key()\n'+
            'rfid.pcd_init()\n'+
            'rfid.pcd_dump_version_to_serial()\n';
        let read_main = Blockly.Python.provideFunction_(
            'read_main',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  if not rfid.picc_is_new_card_present():',
                '    log="没有检测到卡"',
                '    return log',
                '  result, uid = rfid.picc_read_card_serial()',
                '  card_uid = format(format_hex(uid.uid()))',
                '  return card_uid',
                '  if not result:',
                '    return',
            ]);
        let code=read_main+'()';
        return [code];
    };
    //开始录音
    Blockly.Python['AiGBox_openRecording'] = function (block) {
        let TIME = Blockly.Python.valueToCode(block, 'TIME', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_pyaudio'] = 'import pyaudio';
        Blockly.Python.definitions_['import_wave'] = 'import wave';
        let functionName = Blockly.Python.provideFunction_(
            'recording',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(record_seconds=3, file_name= \'myVoice\'):',
                '  WAVE_OUTPUT_FILENAME = \'source/\'+file_name+\'.wav\'',
                '  p = pyaudio.PyAudio()',
                '  FORMAT = pyaudio.paInt16',
                '  stream = p.open(format=FORMAT,channels=1,rate=16000,input=True,frames_per_buffer=1024)',
                '  print("start recording : please speak Chinese!")',
                '  frames = []',
                '  for i in range(0, int(16000 / 1024 * record_seconds)):',
                '    data = stream.read(1024,exception_on_overflow=False)',
                '    frames.append(data)',
                '  print("Finish recording")',
                '  stream.stop_stream()',
                '  stream.close()',
                '  p.terminate()',
                '  wf = wave.open(WAVE_OUTPUT_FILENAME, \'wb\')',
                '  wf.setnchannels(1)',
                '  wf.setsampwidth(p.get_sample_size(FORMAT))',
                '  wf.setframerate(16000)',
                '  wf.writeframes(b\'\'.join(frames))',
                '  wf.close()',
            ]);
        return functionName+'('+TIME+')\n';
    };
    //播放录音
    Blockly.Python['AiGBox_playAudio'] = function (block) {
        Blockly.Python.definitions_['import_os'] = 'import os';
        let functionName = Blockly.Python.provideFunction_(
            'play_audio',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  file_path = \'source/myVoice.wav\'',
                '  cmd = "aplay -D \'plughw:seeed2micvoicec\' "+str(file_path)',
                '  os.system(cmd)',
            ]);
        return functionName+'()\n';
    };
    //识别人脸数目
    Blockly.Python['AiGBox_checkFacenumImage'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_faceconfig'] = 'from AIFace import faceconfig';
        let functionName = Blockly.Python.provideFunction_(
            'Facenum',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(filePath):',
                '  options = {}',
                '  options["max_face_num"] = 8',
                '  options["face_type"] = "LIVE"',
                '  image_result = faceconfig.Fcee_client.detect(faceconfig.getbase64image(filePath),"BASE64",options)',
                '  if (image_result[\'result\'][\'face_num\'] < 1):',
                '    print("识别错误")',
                '    return',
                '  else:',
                '    print(image_result[\'result\'][\'face_num\'])',
                '    return'
            ]);
        return functionName+'('+TEXT+')\n';
    };
    //识别人脸年龄
    Blockly.Python['AiGBox_checkFaceageImage'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_faceconfig'] = 'from AIFace import faceconfig';
        let functionName = Blockly.Python.provideFunction_(
            'Faceage',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(filePath):',
                '  options = {}',
                '  options["face_field"] = "age"',
                '  options["max_face_num"] = 8',
                '  options["face_type"] = "LIVE"',
                '  image_result = faceconfig.Fcee_client.detect(faceconfig.getbase64image(filePath),"BASE64",options)',
                '  if (image_result[\'result\'][\'face_num\'] < 1):',
                '    print("识别错误")',
                '    return',
                '  else:',
                '    print(image_result[\'result\'][\'face_list\'][0][\'age\'])',
                '    return'
            ]);
        return functionName+'('+TEXT+')\n';
    };
    //识别人脸颜值
    Blockly.Python['AiGBox_checkFacebeautyImage'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_faceconfig'] = 'from AIFace import faceconfig';
        let functionName = Blockly.Python.provideFunction_(
            'Facebeauty',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(filePath):',
                '  options = {}',
                '  options["face_field"] = "beauty"',
                '  options["max_face_num"] = 8',
                '  options["face_type"] = "LIVE"',
                '  image_result = faceconfig.Fcee_client.detect(faceconfig.getbase64image(filePath),"BASE64",options)',
                '  if (image_result[\'result\'][\'face_num\'] < 1):',
                '    print("识别错误")',
                '    return',
                '  else:',
                '    print(image_result[\'result\'][\'face_list\'][0][\'beauty\'])',
                '    return'
            ]);
        return functionName+'('+TEXT+')\n';
    };
    //识别人脸种族
    Blockly.Python['AiGBox_checkFaceraceImage'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_faceconfig'] = 'from AIFace import faceconfig';
        let functionName = Blockly.Python.provideFunction_(
            'Facerace',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(filePath):',
                '  options = {}',
                '  options["face_field"] = "race"',
                '  options["max_face_num"] = 8',
                '  options["face_type"] = "LIVE"',
                '  image_result = faceconfig.Fcee_client.detect(faceconfig.getbase64image(filePath),"BASE64",options)',
                '  if (image_result[\'result\'][\'face_num\'] < 1):',
                '    print("识别错误")',
                '    return',
                '  else:',
                '    print(image_result[\'result\'][\'face_list\'][0][\'race\'][\'type\'])',
                '    return'
            ]);
        return functionName+'('+TEXT+')\n';
    };
    //识别人脸种族
    Blockly.Python['AiGBox_checkFacegenderImage'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_faceconfig'] = 'from AIFace import faceconfig';
        let functionName = Blockly.Python.provideFunction_(
            'Facegender',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(filePath):',
                '  options = {}',
                '  options["face_field"] = "gender"',
                '  options["max_face_num"] = 8',
                '  options["face_type"] = "LIVE"',
                '  image_result = faceconfig.Fcee_client.detect(faceconfig.getbase64image(filePath),"BASE64",options)',
                '  if (image_result[\'result\'][\'face_num\'] < 1):',
                '    print("识别错误")',
                '    return',
                '  else:',
                '    print(image_result[\'result\'][\'face_list\'][0][\'gender\'][\'type\'])',
                '    return'
            ]);
        return functionName+'('+TEXT+')\n';
    };
    //识别人脸种族
    Blockly.Python['AiGBox_checkFaceemotionImage'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_faceconfig'] = 'from AIFace import faceconfig';
        let functionName = Blockly.Python.provideFunction_(
            'Faceemotion',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(filePath):',
                '  options = {}',
                '  options["face_field"] = "emotion"',
                '  options["max_face_num"] = 8',
                '  options["face_type"] = "LIVE"',
                '  image_result = faceconfig.Fcee_client.detect(faceconfig.getbase64image(filePath),"BASE64",options)',
                '  if (image_result[\'result\'][\'face_num\'] < 1):',
                '    print("识别错误")',
                '    return',
                '  else:',
                '    print(image_result[\'result\'][\'face_list\'][0][\'emotion\'][\'type\'])',
                '    return'
            ]);
        return functionName+'('+TEXT+')\n';
    };
    //识别通用图片
    Blockly.Python['AiGBox_checkGeneralImage'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_imgconfig'] = 'from AIImage import imgconfig';
        let functionName = Blockly.Python.provideFunction_(
            'General',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(filePath):',
                '  options = {}',
                '  options["top_num"] = 1',
                '  options["baike_num"] = 1',
                '  image_result = imgconfig.Image_client.advancedGeneral(imgconfig.get_file_content(filePath),options)',
                '  if (image_result[\'result_num\'] == 0):',
                '    print("识别错误")',
                '    return',
                '  else:',
                '    print(image_result[\'result\'][0][\'keyword\'])',
                '    return'
            ]);
        return functionName+'('+TEXT+')\n';
    };
    //识别植物图片
    Blockly.Python['AiGBox_checkPlantImage'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_imgconfig'] = 'from AIImage import imgconfig';
        let functionName = Blockly.Python.provideFunction_(
            'Plant',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(filePath):',
                '  options = {}',
                '  options["top_num"] = 1',
                '  options["baike_num"] = 1',
                '  image_result = imgconfig.Image_client.plantDetect(imgconfig.get_file_content(filePath),options)',
                '  if (image_result[\'result\'][0][\'name\'] == \'非植物\'):',
                '    print("识别错误")',
                '    return',
                '  else:',
                '    print(image_result[\'result\'][0][\'name\'])',
                '    return'
            ]);
        return functionName+'('+TEXT+')\n';
    };
    //识别动物图片
    Blockly.Python['AiGBox_checkAnimalImage'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_imgconfig'] = 'from AIImage import imgconfig';
        let functionName = Blockly.Python.provideFunction_(
            'Animal',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(filePath):',
                '  options = {}',
                '  options["top_num"] = 1',
                '  options["baike_num"] = 1',
                '  image_result = imgconfig.Image_client.animalDetect(imgconfig.get_file_content(filePath),options)',
                '  if (image_result[\'result\'][0][\'name\'] == \'非动物\'):',
                '    print("识别错误")',
                '    return',
                '  else:',
                '    print(image_result[\'result\'][0][\'name\'])',
                '    return'
            ]);
        return functionName+'('+TEXT+')\n';
    };
    //识别汽车图片
    Blockly.Python['AiGBox_checkCarImage'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_imgconfig'] = 'from AIImage import imgconfig';
        let functionName = Blockly.Python.provideFunction_(
            'Car',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(filePath):',
                '  options = {}',
                '  options["top_num"] = 1',
                '  options["baike_num"] = 1',
                '  image_result = imgconfig.Image_client.carDetect(imgconfig.get_file_content(filePath),options)',
                '  if (image_result[\'result\'][0][\'name\'] == \'非车类\'):',
                '    print("识别错误")',
                '    return',
                '  else:',
                '    print(image_result[\'result\'][0][\'name\'])',
                '    return'
            ]);
        return functionName+'('+TEXT+')\n';
    };
    //识别手势图片
    Blockly.Python['AiGBox_checkGestureImage'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_imgconfig'] = 'from AIImage import imgconfig';
        let functionName = Blockly.Python.provideFunction_(
            'Gesture',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(filePath):',
                '  options = {}',
                '  options["top_num"] = 1',
                '  options["baike_num"] = 1',
                '  image_result = imgconfig.Body_client.gesture(imgconfig.get_file_content(filePath),options)',
                '  if (image_result[\'result\'][0][\'classname\'] == \'Face\'):',
                '    print("识别错误")',
                '    return',
                '  else:',
                '    print(image_result[\'result\'][0][\'classname\'])',
                '    return'
            ]);
        return functionName+'('+TEXT+')\n';
    };
    //识别颜色图片
    Blockly.Python['AiGBox_checkGetcolorImage'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_cv2'] = 'import cv2';
        Blockly.Python.definitions_['import_colorList'] = 'from AIImage import colorList';
        let functionName = Blockly.Python.provideFunction_(
            'Getcolor',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(filename):',
                '  frame = cv2.imread(filename)',
                '  hsv = cv2.cvtColor(frame,cv2.COLOR_BGR2HSV)',
                '  maxsum = -100',
                '  color = None',
                '  color_dict = colorList.getColorList()',
                '  for d in color_dict:',
                '    mask = cv2.inRange(hsv,color_dict[d][0],color_dict[d][1])',
                '    binary = cv2.threshold(mask, 127, 255, cv2.THRESH_BINARY)[1]',
                '    binary = cv2.dilate(binary,None,iterations=2)',
                '    cnts, hiera = cv2.findContours(binary.copy(),cv2.RETR_EXTERNAL,cv2.CHAIN_APPROX_SIMPLE)',
                '    sum = 0',
                '    for c in cnts:',
                '      sum+=cv2.contourArea(c)',
                '    if sum > maxsum :',
                '      maxsum = sum',
                '      color = d',
                '  print(color)',
                '  return',

            ]);
        return functionName+'('+TEXT+')\n';
    };
    //识别颜色图片
    Blockly.Python['AiGBox_textIntoVoice'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        let PER = Blockly.Python.valueToCode(block, 'PER', Blockly.Python.ORDER_ATOMIC);
        let SPD = Blockly.Python.valueToCode(block, 'SPD', Blockly.Python.ORDER_ATOMIC);
        let PIT = Blockly.Python.valueToCode(block, 'PIT', Blockly.Python.ORDER_ATOMIC);
        let VOL = Blockly.Python.valueToCode(block, 'VOL', Blockly.Python.ORDER_ATOMIC);
        let AUE = Blockly.Python.valueToCode(block, 'AUE', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_os'] = 'import os';
        Blockly.Python.definitions_['import_urllib'] = 'import urllib.request';
        Blockly.Python.definitions_['import_URLError'] = 'from urllib.error import URLError';
        Blockly.Python.definitions_['import_urlencode'] = 'from urllib.parse import urlencode';
        Blockly.Python.definitions_['import_quote_plus'] = 'from urllib.parse import quote_plus';
        let load_config = Blockly.Python.provideFunction_(
            'load_config',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  with open(\'/home/pi/graphical_program/config/config.txt\', \'r\') as f:',
                '    config_str = f.read()',
                '    try:',
                '      config_dic = eval(config_str)',
                '    except Exception as e:',
                '      print(\'error: load config.txt error! \\nerror msg: \' + str(e))',
                '    f.close()',
                '    return config_dic'
            ]);
        Blockly.Python.definitions_['import_config'] = 'config_dic = load_config()\n'+
            'BotId = config_dic[\'BotId\']\n' +
            'APPId = config_dic[\'APPId\']\n' +
            'APIKey = config_dic[\'APIKey\']\n' +
            'SecretKey = config_dic[\'SecretKey\']\n' +
            'AccessToken = config_dic[\'AccessToken\']\n'+
            'FORMATS = {3: "mp3", 4: "pcm", 5: "pcm", 6: "wav"}\n' +
            'FORMAT = FORMATS['+AUE+']\n' +
            'ttsUrl = \'http://tsn.baidu.com/text2audio\'';
        let text_into_voice = Blockly.Python.provideFunction_(
            'text_into_voice',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(text=\'欢迎使用小白机器人\',file_name=\'reply\'):',
                '  headers = {\'Content-Type\': \'application/json\'}',
                '  text = quote_plus('+TEXT+')',
                '  params = {\'tok\': AccessToken,\n',
                '            \'tex\': text,\n' +
                '            \'per\': '+PER+',\n' +
                '            \'spd\': '+SPD+',\n' +
                '            \'pit\': '+PIT+',\n' +
                '            \'vol\': '+VOL+',\n' +
                '            \'aue\': '+AUE+',\n' +
                '            \'cuid\': \'12138\',\n' +
                '            \'lan\': \'zh\',\n' +
                '            \'ctp\': 1}',
                '  params = urlencode(params).encode(\'utf-8\')',
                '  http_post = urllib.request.Request(ttsUrl, data=params, headers=headers)',
                '  has_error = False',
                '  try:',
                '    response = urllib.request.urlopen(http_post)',
                '    result_str = response.read()',
                '    headers = dict((name.lower(), value) for name, value in response.headers.items())',
                '    has_error = (\'content-type\' not in headers.keys() or headers[\'content-type\'].find(\'audio/\') < 0)',
                '  except URLError as err:',
                '    print(\'asr http response http code : \' + str(err))',
                '    result_str = err',
                '    has_error = True',
                '  save_file = "source/error.txt" if has_error else \'source/\'+file_name+\'.\'+ FORMAT',
                '  with open(save_file, \'wb\') as of:',
                '    of.write(result_str)',
                '  if has_error:',
                '    result_str = str(result_str, \'utf-8\')',
                '    print("tts api  error:" + result_str)',
                '    return 0',
                '  else:',
                '    print("result saved as :" + save_file)',
                '    return os.path.abspath(save_file)',
            ]);
        return text_into_voice+'('+TEXT+')\n';
    };
    //音频识别
    Blockly.Python['AiGBox_distASRFile'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_voiceconfig'] = 'from AIVoice import voiceconfig';
        let functionName = Blockly.Python.provideFunction_(
            'ASR_file',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(filePath):',
                '  try:',
                '    asr_result = voiceconfig.Voice_client.asr(voiceconfig.get_file_content(filePath), \'wav\', 16000,{',
                '      \'dev_pid\': 1536,',
                '    })',
                '  except Exception as result:',
                '    print(result)',
                '  else:',
                '    print(asr_result)',
                '    if (asr_result[\'err_no\'] == 0):',
                '      if type(asr_result[\'result\']):',
                '        text = asr_result[\'result\']',
                '        print(text)',
                '        return',
                '      elif isinstance(asr_result[\'result\'], list):',
                '        text = "".join(asr_result[\'result\'])',
                '        print(text)',
                '        return',
                '      else:',
                '        print("error")',
                '    else:',
                '      return 0'
            ]);
        return functionName+'('+TEXT+')\n';
    };
    //录音识别
    Blockly.Python['AiGBox_distASRRecord'] = function (block) {
        let NUM = Blockly.Python.valueToCode(block, 'NUM', Blockly.Python.ORDER_ATOMIC);
        // Blockly.Python.definitions_['import_types'] = 'import types';
        Blockly.Python.definitions_['import_voiceconfig'] = 'from AIVoice import voiceconfig';
        Blockly.Python.definitions_['import_recording'] = 'from Recording import recording';
        let functionName = Blockly.Python.provideFunction_(
            'ASR_Record',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(datatime):',
                '  recording(datatime)',
                '  try:',
                '    asr_result = voiceconfig.Voice_client.asr(voiceconfig.get_file_content(\'record.wav\'), \'wav\', 16000,{',
                '      \'dev_pid\': 1536,',
                '    })',
                '  except Exception as error:',
                '    print(error)',
                '  else:',
                '    print(asr_result)',
                '    if (asr_result[\'err_no\'] == 0):',
                '      if (asr_result[\'result\']):',
                '        text = asr_result[\'result\']',
                '        print(text)',
                '        return',
                '      elif isinstance(asr_result[\'result\'], list):',
                '        text = "".join(asr_result[\'result\'])',
                '        print(text)',
                '        return',
                '      else:',
                '        print("error")',
                '    else:',
                '      return 0'
            ]);
        return functionName+'('+NUM+')\n';
    };
}
