/*
* author : wlc
* time : 2020-6-17
* */
import {replaceQuotationMarks} from '../common.js'
export default function (Blockly) {
    Blockly.Python['math_positive_number'] = function(block){
        let second = parseInt(block.getFieldValue('NUM'));
        return [second]

    }

    Blockly.Python['control_wait'] = function (block) {
        let second = Blockly.Python.valueToCode(block, 'DURATION', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_time'] = 'import time';
        return 'time.sleep(' + second+ ')\n';
    };

    Blockly.Python['math_whole_number'] = function (block) {
        let times = parseInt(block.getFieldValue('NUM'));
        return [times]
    }


    Blockly.Python['control_repeat'] = function(block) {
        var repeats = Blockly.Python.valueToCode(block, 'TIMES',
            Blockly.Python.ORDER_FUNCTION_CALL);
        var branch = Blockly.Python.statementToCode(block, 'SUBSTACK');
        branch = Blockly.Python.addLoopTrap(branch, block.id);

        var code = "for count in range(" + repeats + "):\n";
        if (branch) {
            code += branch;
        } else {
            code += Blockly.Python.INDENT + "pass\n";
        }
        code += "\n";
        return code;
    };

    Blockly.Python['control_forever'] = function(block) {
        var branch = Blockly.Python.statementToCode(block, 'SUBSTACK');
        branch = Blockly.Python.addLoopTrap(branch, block.id);

        var code = "while True:\n";
        if (branch) {
            code += branch;
        } else {
            code += Blockly.Python.INDENT + "pass\n";
        }
        code += "\n";
        return code;
    };

    Blockly.Python['control_if'] = function(block) {
        var argument = Blockly.Python.valueToCode(block, 'CONDITION',
            Blockly.Python.ORDER_NONE) || 'False';
        var branch = Blockly.Python.statementToCode(block, 'SUBSTACK');
        branch = Blockly.Python.addLoopTrap(branch, block.id);

        var code = "if " + argument + ":\n";
        if (branch) {
            code += branch;
        } else {
            code += Blockly.Python.INDENT + "pass\n";
        }
        code += "\n";
        return code;
    };

    Blockly.Python['control_if_else'] = function(block) {
        var argument = Blockly.Python.valueToCode(block, 'CONDITION',
            Blockly.Python.ORDER_NONE) || 'False';
        var branch = Blockly.Python.statementToCode(block, 'SUBSTACK');
        branch = Blockly.Python.addLoopTrap(branch, block.id);
        var branch2 = Blockly.Python.statementToCode(block, 'SUBSTACK2');
        branch2 = Blockly.Python.addLoopTrap(branch2, block.id);

        var code = "if " + argument + ":\n";
        if (branch) {
            code += branch;
        } else {
            code += Blockly.Python.INDENT + "pass\n";
        }
        code += "else:\n";
        if (branch2) {
            code += branch2;
        } else {
            code += Blockly.Python.INDENT + "pass\n";
        }
        code += "\n";
        return code;
    };

    Blockly.Python['control_wait_until'] = function(block) {
        var argument = Blockly.Python.valueToCode(block, 'CONDITION',
            Blockly.Python.ORDER_UNARY_POSTFIX) || 'False';
        var code = "while not " + argument + ":\n";
        code += Blockly.Python.INDENT + "pass\n";
        code += "\n";
        return code;
    };

    Blockly.Python['control_repeat_until'] = function(block) {
        var argument = Blockly.Python.valueToCode(block, 'CONDITION',
            Blockly.Python.ORDER_UNARY_POSTFIX) || 'False';

        var branch = Blockly.Python.statementToCode(block, 'SUBSTACK');
        branch = Blockly.Python.addLoopTrap(branch, block.id);

        var code = "while not " + argument + ":\n";
        if (branch) {
            code += branch;
        } else {
            code += Blockly.Python.INDENT + "pass\n";
        }
        code += "\n";
        return code;
    };
    Blockly.Python['control_stop_while'] = function (block) {
        return 'break\n';
    }
    Blockly.Python['control_stop_this_while'] = function (block) {
        return 'continue\n';
    }
    Blockly.Python['control_iterate_sequence'] = function (block) {
        let sequence = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'SEQUENCE', Blockly.Python.ORDER_ATOMIC));
        let iteration = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ITERATION', Blockly.Python.ORDER_ATOMIC));
        let SUBSTACK = Blockly.Python.statementToCode(block, "SUBSTACK"),
            pass = Blockly.Python.addLoopTrap(SUBSTACK, block.id) || Blockly.Python.PASS;
        return 'for '+iteration+' in '+sequence+':\n' + pass;
    }
    Blockly.Python['control_repeat_step'] = function (block) {
        let iteration = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ITERATION', Blockly.Python.ORDER_ATOMIC));
        let start = Blockly.Python.valueToCode(block, 'START', Blockly.Python.ORDER_ATOMIC);
        let end = Blockly.Python.valueToCode(block, 'END', Blockly.Python.ORDER_ATOMIC);
        let step = Blockly.Python.valueToCode(block, 'STEP', Blockly.Python.ORDER_ATOMIC);
        let SUBSTACK = Blockly.Python.statementToCode(block, "SUBSTACK"),
            pass = Blockly.Python.addLoopTrap(SUBSTACK, block.id) || Blockly.Python.PASS;
        return 'for '+iteration+' in range('+start+','+end+','+step+'):\n' + pass;
    }
}
