import keyMirror from 'keymirror';
import cookie from 'react-cookies'
import axios from 'axios'
const Base64 = require('js-base64').Base64

const UserState = keyMirror({
    NOT_LOGINED: null,
    LOGINED: null
});
const UserStates = Object.keys(UserState)
const DONE_USER_LOGOUT = 'DONE_USER_LOGOUT'; // 标识登出过程成功完成
const DONE_USER_LOGIN = 'DONE_USER_LOGIN'
const ERROR_USER_LOGIN = 'ERROR_USER_LOGIN'
const logoutSuccess = () => ({type: DONE_USER_LOGOUT});

const logout = dispatch => {
    // console.log('localStorage:', localStorage)
    // localStorage.removeItem('token');
    let allCookies = ['access_token', 'sessionid', 'username', 'is_admin', 'nickname','avatar']
    axios.post('/edu/user/logout').then(res=>{
        console.log('/edu/user/logout::', res)
        if (res.data.ret){
            for (var i=0;i<allCookies.length;i++){
                cookie.remove(allCookies[i], { path: '/' })

            }
            dispatch(logoutSuccess());
        }else {
            console.log('登出失败！')
        }
    })
};
const checkLoginState = ()=>{
    let ck = cookie.loadAll()
    let accessToken = cookie.load('access_token')
    let username = cookie.load('username')
    console.log('ck accessToken:', accessToken)
    console.log('ck username:', username)
    return ck;
}

let userData = {
    username: cookie.load('username'),
    avatar: cookie.load('avatar'),
    nickname: cookie.load('nickname') ? Base64.decode(cookie.load('nickname')): ''
}

const initialState = {
    error: null,
    userData:userData,
    token: cookie.load('access_token'),
    loginState: cookie.load('access_token') ? UserState.LOGINED: UserState.NOT_LOGINED,
    // loginState: UserState.LOGINED

};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState
    // console.log('action:', action)
    switch (action.type) {
    case DONE_USER_LOGIN:
        console.log('DONE_USER_LOGIN:', userData)
        return Object.assign({}, state,{
            loginState: UserState.LOGINED,
            token: action.data.token,
            userData: userData
        })
    case DONE_USER_LOGOUT:
        console.log('DONE_USER_LOGOUT******')
        return Object.assign({}, state,{
            loginState: UserState.NOT_LOGINED,
            token: '',
            userData: null
        })
    case ERROR_USER_LOGIN:
        return Object.assign({}, state,{
            loginState: UserState.NOT_LOGINED,
            token: '',
            userData: null,
            error:action.data
        })
    default:
        return state
    }
}

const getIsLogined = loginState => (
    loginState === UserState.LOGINED
);

export {
    reducer as default,
    initialState as userStateInitialState,
    UserState,
    UserStates,
    getIsLogined,
    logout
};
