import Modal from '../../containers/modal.jsx';
import styles from './login-modal.css';
import React from 'react';
import PropTypes from 'prop-types';
import Box from '../box/box.jsx';
import SubmitLoginButton from './submit-login-button.jsx';
import connect from 'react-redux/es/connect/connect';
import {closeLoginModal} from '../../reducers/modals.js';
import bindAll from 'lodash.bindall';
import axios from 'axios';
import {userStateInitialState} from '../../reducers/user-state.js'

import cookie from 'react-cookies'

// const LoginModal = props => (
//     <Modal
//         className={styles.modalContent}
//         contentLabel={props.title}
//         id="loginModal"
//         onRequestClose={props.onCancel}
//     >
//         <br/>
//         <Box>
//             <input
//                 className={styles.minInput}
//                 name="account"
//                 placeholder="账号"
//                 type="text"
//                 value={props.username}
//             /><br />
//             <input
//                 className={styles.minInput}
//                 name="password"
//                 placeholder="密码"
//                 type="password"
//                 value={props.password}
//             /><br />
//             <SubmitLoginButton className={styles.btnSubmit} onClick={props.}/>
//         </Box>
//     </Modal>
// );
class LoginModal extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            username: null,
            password: null
        }
        bindAll(this, [
            'handleSubmitLogin',
        ])
    }
    /*
    * 处理登录
    * */
    handleSubmitLogin(e){
        console.log('handleSubmitLogin:', this.state.username, this.state.password)
        axios.post('/edu/user/login', {
            username: this.state.username,
            password: this.state.password
        }).then(res=>{
            console.log('handleSubmitLogin::', res.data)
            if(res.data.ret){
                console.log('handleSubmitLogin userStateInitialState:', userStateInitialState)
                // cookie.save('username', res.data.username)
                // cookie.save('is_admin', res.data.is_admin)
                // cookie.save('nickname', res.data.nickname)
                // cookie.save('avatar', res.data.avatar)
                document.execCommand('Refresh')
                location.reload()
                // location.replace(location)
                // window.navigate(location)
                // this.props.loginState
                // document.getElementById('account_div').setAttribute('key', res.data.name)
                // console.log('keyyyyy:', document.getElementById('account_div').getAttribute('key'))
                // this.forceUpdate()
            }else{
                console.log(res.data)
            }
        })
        this.props.onCancel()
    }
    usernameInputChange(){
        this.setState({
            username: this.refs.username.value
        })
    }
    passwordInputChange(){
        this.setState({
            password: this.refs.password.value
        })
    }

    render () {
        return (<Modal
            className={styles.modalContent}
            contentLabel={this.props.title}
            id="loginModal"
            onRequestClose={this.props.onCancel}
        >
            <br/>
            <Box>
                <input
                    className={styles.minInput}
                    name="username"
                    placeholder="账号"
                    type="text"
                    onChange={()=>this.usernameInputChange()}
                    ref={'username'}
                /><br />
                <input
                    className={styles.minInput}
                    name="password"
                    placeholder="密码"
                    type="password"
                    onChange={()=>this.passwordInputChange()}
                    ref={'password'}
                /><br />
                <SubmitLoginButton className={styles.btnSubmit} onClick={this.handleSubmitLogin}/>
            </Box>
        </Modal>);
    }
}


LoginModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
}

const mapStateToProps = state =>({
    loginError:state.scratchGui.userState.error,
    loginState: state.scratchGui.userState.loginState
})
const mapDispatchToProps = dispatch =>({
    onCancel:()=>dispatch(closeLoginModal())
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginModal);
