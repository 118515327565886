import {clearLoadingProcess} from './process.js'
const OPEN_MENU = 'scratch-gui/menus/OPEN_MENU';
const CLOSE_MENU = 'scratch-gui/menus/CLOSE_MENU';
const MENU_ACCOUNT = 'accountMenu';
const MENU_FILE = 'fileMenu';
const MENU_EDIT = 'editMenu';
const MENU_LANGUAGE = 'languageMenu';
const MENU_LOGIN = 'loginMenu';
const MENU_SERIAL = 'serialMenu';
const MENU_FIRMWARE = 'firmwareMenu';
const MENU_DRIVER = 'driverMenu'
const MENU_HELP = 'driverHelp'

global.comState = {};


const initialState = {
    [MENU_ACCOUNT]: false,
    [MENU_FILE]: false,
    [MENU_EDIT]: false,
    [MENU_LANGUAGE]: false,
    [MENU_LOGIN]: false,
    [MENU_SERIAL]: false,
    [MENU_FIRMWARE]: false,
    [MENU_DRIVER]: false,
    [MENU_HELP]: false,
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case OPEN_MENU:
            return Object.assign({}, state, {
                [action.menu]: true
            });
        case CLOSE_MENU:
            return Object.assign({}, state, {
                [action.menu]: false
            });

        default:
            return state;
    }
};
const openMenu = menu => ({
    type: OPEN_MENU,
    menu: menu
});
const closeMenu = menu => ({
    type: CLOSE_MENU,
    menu: menu
});
global.COMPorts = []
let ports = [];
// let ws = new WebSocket('ws://localhost:21322');
window.ws_client = new WebSocket('ws://localhost:21322');
window.ws_client.onmessage = function(evt) {
    try {
        let resData = JSON.parse(evt.data);
        let msg = resData.msg;
        let consoleDiv = document.getElementById('code_console')
        let preSerialConsole = document.getElementById('serial-console')
        let div = document.createElement('div');
        let serialModalContentObj = document.getElementById('serial-modal-content')
        let arduinoConsoleDiv = document.getElementById('arduino_code_console');
        switch (resData.opt) {
            case 'ports':
                ports = msg;
                global.comState = ports
                global.COMPorts = []
                for (let portx in global.comState){
                    global.COMPorts.push(portx)
                }
                break;
            case 'arduino_console':
                div.innerHTML = '<span style="font-size: 14px;color:#818591;">'+msg+'</span>';
                if (arduinoConsoleDiv){
                    arduinoConsoleDiv.appendChild(div);
                    arduinoConsoleDiv.scrollTop = arduinoConsoleDiv.scrollHeight; // 滚动条保持在最低端
                }
                if (msg === '烧写交互固件成功！'|| msg === '烧录交互固件失败！'){
                    clearLoadingProcess();
                    alert(msg)
                }
                break;
            case 'serial_monitor':
                if (preSerialConsole) preSerialConsole.append(msg);
                if (document.getElementById('ckb_scroll_console').checked){
                    serialModalContentObj.scrollTop = serialModalContentObj.scrollHeight; // 滚动条保持在最低端
                }
                break;
            case 'tips':
                clearLoadingProcess()
                if (msg) alert(msg);
                break
            case 'log_console':
                div.innerHTML = '<span style="font-size: 14px;color:#818591;">'+msg+'</span>'
                if (consoleDiv) consoleDiv.appendChild(div);
                else return;
                consoleDiv.scrollTop = consoleDiv.scrollHeight; // 滚动条保持在最低端
                if (msg === '>>>>>>program uploaded successfully>>>>>>'|| msg === '初始化K210固件成功！'||
                    msg === '初始化ESP32固件成功！' || msg === '初始化固件失败！'|| msg.startsWith('Error')){
                    clearLoadingProcess()
                }
                break;
            }
        }catch (e) {
            console.log(e)
        }
    };
window.ws_client.onclose = function() {
    global.COMPorts = []
}

window.ws_client.onerror = function () {
    console.log('连接异常')
}

function getSerialPort() {
    if (window.ws_client && window.ws_client.readyState === 1){
        window.ws_client.send(JSON.stringify({"command":'list_serials'}));
    }
    return {type: OPEN_MENU}
}

function connectSerial(port) {
    let message;
    if (port ==='close'){
        message = {"command":'close_port', 'data':port};
    }
    else {
        message = {"command":'connect_port', 'data':port};
    }
    window.ws_client.send(JSON.stringify(message));
    return {type: CLOSE_MENU}
}
// 发送消息到后台
function sendMessageToSerial(message) {
    if (window.ws_client.readyState === 1){
        window.ws_client.send(JSON.stringify(message))
    }
}

function writeMicroPythonFirmware(type) {
    if (window.ws_client.readyState === 1){
        let hasCom = false
        for (let port in global.comState){
            if (global.comState[port] === true){
                window.ws_client.send(JSON.stringify({'command':'flash_firmware', 'data':port,'type':type}))
                hasCom = true
            }
        }
        if (!hasCom){
            alert('请先选择要烧写固件的串口！')
            clearLoadingProcess()
        }
    }
}

/*将字符串中的中文转为unicode编码*/
function strToUnicode(data)
{
    let re=/[^\u4E00-\u9FA5]/
    let str ='';
    for(let i=0;i<data.length;i++)
    {
        if (!re.test(data[i])){
            str+="\\u"+data[i].charCodeAt(0).toString(16);
        }else{
            str+=data[i]
        }
    }
    return str;
}

const openAccountMenu = () => openMenu(MENU_ACCOUNT);
const closeAccountMenu = () => closeMenu(MENU_ACCOUNT);
const accountMenuOpen = state => state.scratchGui.menus[MENU_ACCOUNT];
const openFileMenu = () => openMenu(MENU_FILE);
const closeFileMenu = () => closeMenu(MENU_FILE);
const fileMenuOpen = state => state.scratchGui.menus[MENU_FILE];
const openEditMenu = () => openMenu(MENU_EDIT);
const closeEditMenu = () => closeMenu(MENU_EDIT);
const editMenuOpen = state => state.scratchGui.menus[MENU_EDIT];
const openLanguageMenu = () => openMenu(MENU_LANGUAGE);
const closeLanguageMenu = () => closeMenu(MENU_LANGUAGE);
const languageMenuOpen = state => state.scratchGui.menus[MENU_LANGUAGE];
const openLoginMenu = () => openMenu(MENU_LOGIN);
const closeLoginMenu = () => closeMenu(MENU_LOGIN);
const loginMenuOpen = state => state.scratchGui.menus[MENU_LOGIN];
const openSerialMenu = ()=> openMenu(MENU_SERIAL);
const closeSerialMenu = ()=> closeMenu(MENU_SERIAL);
const serialMenuOpen = state => state.scratchGui.menus[MENU_SERIAL]; //串口菜单打开
const getSerials = ()=> getSerialPort();
const onConnectSerial = port => connectSerial(port);
const toSerialSendMessage = message=>sendMessageToSerial(message)
const firmwareMenuOpen = state => state.scratchGui.menus[MENU_FIRMWARE]; //固件菜单
const closeFirmwareMenu = () => closeMenu(MENU_FIRMWARE);
const openFirmwareMenu = ()=> openMenu(MENU_FIRMWARE);
const driverMenuOpen = state => state.scratchGui.menus[MENU_DRIVER]; // 驱动下载菜单
const openDriverMenu = () => openMenu(MENU_DRIVER);
const closeDriverMenu = () => closeMenu(MENU_DRIVER);
const helpMenuOpen = state => state.scratchGui.menus[MENU_HELP]; // 驱动下载菜单
const openHelpMenu = () => openMenu(MENU_HELP);
const closeHelpMenu = () => closeMenu(MENU_HELP);

export {
    reducer as default,
    initialState as menuInitialState,
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen,
    openSerialMenu,
    closeSerialMenu,
    serialMenuOpen,
    getSerials,
    onConnectSerial,
    toSerialSendMessage,
    writeMicroPythonFirmware,
    firmwareMenuOpen,
    closeFirmwareMenu,
    openFirmwareMenu,
    driverMenuOpen,
    openDriverMenu,
    closeDriverMenu,
    strToUnicode,
    helpMenuOpen,
    openHelpMenu,
    closeHelpMenu
};
