/*
* author : wlc
* time : 2020-6-17
* */
import {regQuotationMarks,replaceQuotationMarks, colorHexToRGB} from '../common.js'
function judgeNum(num,divide=false) {
    num = replaceQuotationMarks(num)
    if (num === 'NaN' || num === ''){
        if (divide){
            return '1'
        }
        return '0'
    }
    return num
}

function format2args(block, Blockly, arg1, arg2, divide =false) {
    let num1 = judgeNum(Blockly.Python.valueToCode(block,arg1, Blockly.Python.ORDER_ATOMIC)) ,
        num2 = judgeNum(Blockly.Python.valueToCode(block,arg2, Blockly.Python.ORDER_ATOMIC), divide);
    return [num1,num2];
}

export default function (Blockly) {
    Blockly.Python['operator_add'] =function (block) {
        let num = format2args(block, Blockly, 'NUM1', 'NUM2')
        return [num[0]+' + '+num[1],Blockly.Python.ORDER_NONE]
    }
    Blockly.Python['operator_subtract'] =function (block) {
        let num = format2args(block, Blockly, 'NUM1', 'NUM2')
        return [num[0]+' - '+num[1],Blockly.Python.ORDER_NONE]
    }
    Blockly.Python['operator_multiply'] =function (block) {
        let num = format2args(block, Blockly, 'NUM1', 'NUM2')
        return [num[0]+' * '+num[1],Blockly.Python.ORDER_NONE]
    }
    Blockly.Python['operator_divide'] =function (block) {
        let num = format2args(block, Blockly, 'NUM1', 'NUM2', true)
        return [num[0]+' / '+num[1],Blockly.Python.ORDER_NONE]
    }

    Blockly.Python['operator_random'] = function (block) {
        let args = format2args(block, Blockly, 'FROM', 'TO')
        Blockly.Python.definitions_['import_random'] = 'import random';
        return ['random.randint('+args[0]+','+args[1]+')',Blockly.Python.ORDER_NONE]
    }

    Blockly.Python['operator_compare'] = function(block) {
        var oplist = {
            "operator_gt": " > ",
            "operator_equals": " == ",
            "operator_lt": " < "
        };
        var order = Blockly.Python.ORDER_RELATIONAL;
        var arg0 = Blockly.Python.valueToCode(block, 'OPERAND1', order);
        var arg1 = Blockly.Python.valueToCode(block, 'OPERAND2', order);

        arg0 = replaceQuotationMarks(arg0);
        arg1 = replaceQuotationMarks(arg1);
        var op = oplist[block.type];
        var code = arg0 + op + arg1;
        return [code, order];
    };

    Blockly.Python['operator_gt'] = Blockly.Python['operator_compare'];
    Blockly.Python['operator_equals'] = Blockly.Python['operator_compare'];
    Blockly.Python['operator_lt'] = Blockly.Python['operator_compare'];

    Blockly.Python['operator_operation'] = function(block) {
        var oplist = {
            "operator_and": " and ",
            "operator_or": " or "
        };
        var order = (block.type == "operator_and") ? Blockly.Python.ORDER_LOGICAL_AND :
            Blockly.Python.ORDER_LOGICAL_OR;
        var arg0 = Blockly.Python.valueToCode(block, 'OPERAND1', order) || '0';
        var arg1 = Blockly.Python.valueToCode(block, 'OPERAND2', order) || '0';
        var op = oplist[block.type];
        var code = arg0 + op + arg1;
        return [code, order];
    };

    Blockly.Python['operator_and'] = Blockly.Python['operator_operation'];
    Blockly.Python['operator_or'] = Blockly.Python['operator_operation'];

    Blockly.Python['operator_not'] = function(block) {
        // Negation.
        var order = Blockly.Python.ORDER_LOGICAL_NOT;
        var arg0 = Blockly.Python.valueToCode(block, 'OPERAND', order) || 'false';
        var code = 'not ' + arg0;
        return [code, order];
    };

    Blockly.Python['operator_join'] = function (block) {
        let str1 = Blockly.Python.valueToCode(block, 'STRING1', Blockly.Python.ORDER_ATOMIC),
            str2 = Blockly.Python.valueToCode(block, 'STRING2', Blockly.Python.ORDER_ATOMIC);
        return ['str('+str1+') + '+'str('+str2+')']
    }

    Blockly.Python['operator_letter_of'] = function (block) {
        let letter = Blockly.Python.valueToCode(block, 'LETTER', Blockly.Python.ORDER_ATOMIC),
            str = Blockly.Python.valueToCode(block, 'STRING', Blockly.Python.ORDER_ATOMIC);
        return [str+'['+letter+']']
    }

    Blockly.Python['operator_length'] = function (block) {
        let str = Blockly.Python.valueToCode(block, 'STRING', Blockly.Python.ORDER_ATOMIC);
        return ['len('+str+')']
    }

    Blockly.Python['operator_contains'] = function(block) {
        var order = Blockly.Python.ORDER_FUNCTION_CALL;
        var arg0 = Blockly.Python.valueToCode(block, 'STRING1', order) || '\'\'';
        var arg1 = Blockly.Python.valueToCode(block, 'STRING2', order) || '0';
        var code = 'str(' + arg0 + ').find(str(' + arg1 + ')) > -1';
        return [code, Blockly.Python.ORDER_RELATIONAL];
    };

    Blockly.Python['operator_mod'] = function (block) {
        let num = format2args(block, Blockly, 'NUM1', 'NUM2', true)
        return [num[0]+' % '+num[1], Blockly.Python.ORDER_FUNCTION_CALL]
    }

    Blockly.Python['operator_round'] = function (block) {
        let num = Blockly.Python.valueToCode(block, 'NUM', Blockly.Python.ORDER_ATOMIC);
        return ['round('+num+')']
    }
    Blockly.Python['operator_mathop'] = function (block) {
        let num = judgeNum(Blockly.Python.valueToCode(block, 'NUM', Blockly.Python.ORDER_ATOMIC)),
            operate = block.getField('OPERATOR').value_, code;
        Blockly.Python.definitions_['import_math'] = 'import math';
        switch (operate) {
            case 'abs': //绝对值
                code = 'math.fabs('+num+')';
                break;
            case 'floor': //向下取整
                code = 'math.floor('+num+')'
                break;
            case 'ceiling':  //向上取整
                code = 'math.ceil('+num+')'
                break;
            case 'sqrt':  //平方根
                code = 'math.sqrt('+num+')'
                break;
            case 'sin':  //sin
                code = 'math.sin('+num+' / 180.0 * math.pi)'
                break;
            case 'cos':  //cos
                code = 'math.cos('+num+' / 180.0 * math.pi)'
                break;
            case 'tan':  //tan
                code = 'math.tan('+num+' / 180.0 * math.pi)'
                break;
            case 'asin':  //asin
                code = 'math.asin('+num+') / math.pi * 180'
                break;
            case 'acos':  //acos
                code = 'math.acos('+num+') / math.pi * 180'
                break;
            case 'atan':  //atan
                code = 'math.atan('+num+') / math.pi * 180'
                break;
            case 'ln':  //ln
                code = 'math.log('+num+')'
                break;
            case 'log':  //atan
                code = 'math.log10('+num+')'
                break;
            case 'e ^':  //atan
                code = 'math.exp('+num+')'
                break;
            case '10 ^': //10 ^
                code = 'math.pow(10, '+num+')'
                break;
        }
        return [code,Blockly.Python.ORDER_FUNCTION_CALL]
    }

    Blockly.Python['operator_constraintVariable'] = function (block) {
        let VALUE =  judgeNum(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC)),
            MIN =  judgeNum(Blockly.Python.valueToCode(block, 'MIN', Blockly.Python.ORDER_ATOMIC)),
            MAX =  judgeNum(Blockly.Python.valueToCode(block, 'MAX', Blockly.Python.ORDER_ATOMIC));
        return ['min(max('+VALUE+', '+MIN+'), '+MAX+')']
    }

    Blockly.Python['operator_string_intercept'] = function (block) {
        let text =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'STRING', Blockly.Python.ORDER_ATOMIC)),
            index1 =  Blockly.Python.valueToCode(block, 'INDEX1', Blockly.Python.ORDER_ATOMIC).replace( /[\\(\\)]/g, ''),
            index2 =  Blockly.Python.valueToCode(block, 'INDEX2', Blockly.Python.ORDER_ATOMIC).replace( /[\\(\\)]/g, '');
        index1 = judgeNum(index1)
        index2 = judgeNum(index2)
        return [text+'['+index1+':'+index2+']']
    }

    Blockly.Python['operator_mapping'] = function (block) {
        Blockly.Python.definitions_['def_math_map'] = 'def math_map(v, al, ah, bl, bh):\n' +
            '    return bl +  (bh - bl) * (v - al) / (ah - al)\n';
        let VALUE =  judgeNum(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC)),
            AL =  judgeNum(Blockly.Python.valueToCode(block, 'AL', Blockly.Python.ORDER_ATOMIC)),
            AH =  judgeNum(Blockly.Python.valueToCode(block, 'AH', Blockly.Python.ORDER_ATOMIC)),
            BL =  judgeNum(Blockly.Python.valueToCode(block, 'BL', Blockly.Python.ORDER_ATOMIC)),
            BH =  judgeNum(Blockly.Python.valueToCode(block, 'BH', Blockly.Python.ORDER_ATOMIC));
        return ['math_map('+VALUE+','+AL+','+AH+','+BL+',' +BH+')']
    }
}
