exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*$ui-primary: hsla(215, 100%, 95%, 1); !* #E5F0FF *!*/\n/* #E5F0FF */\n/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #FF4254 主题颜色 */\n/* #3373CC */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #E53C4C */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n/* layout contants from `layout-constants.js` */\n.tag-button_tag-button_3MxU5 {\n    padding: .625rem 1rem;\n    /*background: $motion-primary;*/\n    background: #f13e51;\n    border-radius: 1.375rem;\n    color: hsla(0, 100%, 100%, 1);\n    height: 2.5rem;\n}\n.tag-button_tag-button-icon_3VB4Q {\n    max-width: 1rem;\n    max-height: 1rem;\n}\n.tag-button_active_1IIH9 {\n    /*background: $data-primary;*/\n    background: #ff8c00;\n}\n", ""]);

// exports
exports.locals = {
	"tag-button": "tag-button_tag-button_3MxU5",
	"tagButton": "tag-button_tag-button_3MxU5",
	"tag-button-icon": "tag-button_tag-button-icon_3VB4Q",
	"tagButtonIcon": "tag-button_tag-button-icon_3VB4Q",
	"active": "tag-button_active_1IIH9"
};