import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import Box from '../box/box.jsx';
import styles from './code-editor-wrap.css';
import CodeEditor from '../code-editor/code-editor.jsx';
import ArduinoCodeEditor from '../arduino-code-editor/arduino-code-editor.jsx'
import { getStageDimensions } from '../../lib/screen-utils.js';
import StageHeader from '../../containers/stage-header.jsx';
import Button from '../button/button.jsx';
import ableProgramIco from './icon--able-program.svg'
import unableProgramIco from './icon--unable-program.svg'
import ableProgramIcon from './icon_bianji_01.png'
import unableProgramIcon from './icon_bianji_02.png'
import clearIcon from './icon_qingchu.png'
import uploadIcon from './icon_shangchuan.png'
import serialListenIcon from './icon_ckbjsq.png'
import { STAGE_SIZE_MODES } from '../../lib/layout-constants';
import { setStageSize } from '../../reducers/stage-size';
import {toSerialSendMessage, strToUnicode} from '../../reducers/menus.js'
import CodeConsole from "../code-console/code-console.jsx";
import ArduinoCodeConsole from "../code-console/arduino-code-console.jsx";
import SerialModal from './serial-listen.jsx';
import ReactDOM from "react-dom";
import {showLoadingProcess} from "../../reducers/process.js";
import debounce from "lodash/debounce";

const messages = defineMessages({
    largeStageSizeMessage: {
        defaultMessage: 'Switch to large stage',
        description: 'Button to change stage size to large',
        id: 'gui.stageHeader.stageSizeLarge'
    },
    smallStageSizeMessage: {
        defaultMessage: 'Switch to small stage',
        description: 'Button to change stage size to small',
        id: 'gui.stageHeader.stageSizeSmall'
    }
});
/**
 * 设置代码框 可拖动宽度 未完成
 * */
// window.onload = function () {
//     let draggableCodeEditorObj = document.getElementById('code-editor');
//     let self = this;
//     draggableCodeEditorObj.onmousedown = function (e) {
//         console.log('e.clientX ========',e.clientX)
//         let startX = e.clientX- this.offsetLeft;
//         e.cancelBubble = true;
//         console.log(startX);
//         document.onmousemove = function (ev) {
//             let resizeX = ev.clientX - startX;
//             let maxWidth = document.documentElement.clientWidth -draggableCodeEditorObj.offsetWidth;
//             resizeX <= 0 && (resizeX =0 );
//             resizeX >= maxWidth && ( resizeX = maxWidth)
//             console.log('resizeX =========',resizeX)
//             console.log('maxwidth ========', maxWidth)
//             draggableCodeEditorObj.style.left = resizeX + "px";
//
//         }
//
//         document.onmouseup = function() {//鼠标弹起时别忘了把其他事件给取消了哈
//             document.onmousemove = null;
//             document.onnouseup = null;
//         }
//
//     }
// }

class CodeEditorWrap extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'uploadToServer',
            'handleCodeBoxSwitching',
            'handleClearConsoleDiv',
            'serialListenShow',
            'onRef',
            'handleImageSize',
            'handleChangeProgramMode',
            'handleImgMouseOver',
            'handleImgMouseOut',
            'handleChildTextarea'
        ]);
        this.state = {
            editPythonText: '',  // 编辑的python代码
            editArduinoText: '',  // 编辑的arduino代码
            isHResize: false, //当前是否可拖动
            lastClientX:0, //上一次鼠标的横坐标
            changement:0, //鼠标横坐标变化量
            wid:480,//默认侧边栏宽度
            screenWid:window.innerWidth, // 窗口宽度
        }
        this.arduinoShowBox = false; // 当前显示的是否是arduino代码框
        this.programEditMode = false; // 当前代码框是否可编辑
        this.hResizeDown=this.hResizeDown.bind(this) //开始拖动函数
        this.hResizeOver=this.hResizeOver.bind(this) //正在拖动函数
        this.stopResize=this.stopResize.bind(this) //停止拖动函数
        window.onmousemove=e=>this.hResizeOver(e) //鼠标移动对应的正在拖动函数
        window.onmouseup=e=>this.stopResize(e) //鼠标松开对应的停止拖动函数

    }
    /**
     * 开始拖动侧边栏
     */
    hResizeDown(e){
        e.stopPropagation()
        this.setState({
            isHResize:true,
            lastClientX:e.clientX
        })
    }
    /**
     * 正在拖动侧边栏
     */
    hResizeOver(e){
        const {isHResize,lastClientX}=this.state
        let changement=e.clientX-lastClientX
        if(isHResize)
        {
            this.setState({
                wid:this.state.wid-changement,
                lastClientX:e.clientX
            })
        }
        }
    /**
     * 鼠标松开时,停止拖动
     */
    stopResize(e){
        this.setState({
            isHResize:false
        })
    }

    /*上传到服务端*/
    uploadToServer(){
        let code, arduino_code;
        if (this.arduinoShowBox){
            this.programEditMode ? arduino_code = this.state.editArduinoText: arduino_code = this.props.arduinoCode;
            if (global.COMPorts.length !==0){
                showLoadingProcess('正在上传到设备中。。。')
                toSerialSendMessage({'command':'arduinoCoding', 'data': arduino_code})
            }
        }
        else{
            this.programEditMode ? code = this.state.editPythonText : code = this.props.code;
            if (this.props.vm.runtime.peripheralExtensions.id === 'AiGBox'){ //高级试验箱特殊处理
                code = strToUnicode(code)
                let init_str_index = code.indexOf('\n'); //默认第一行为MQTT id
                if (init_str_index !== -1){
                    let init_str = code.substring(0, init_str_index).split('import')[1].replace(' ','')
                    if(init_str.startsWith('AIGBOX')){
                        let device_id = init_str.split('AIGBOX').join('').toString();
                        toSerialSendMessage({'command':'MQTT_code', 'data':[device_id,code.substring(init_str_index)]})
                    }
                    else{
                        alert('请先初始化设备！')
                    }
                }
                else {
                    alert('请先初始化设备！')
                }
            }else{
                if (global.COMPorts.length !==0){
                    showLoadingProcess('正在上传到设备中。。。')
                    toSerialSendMessage({'command':'coding', 'data': code})
                }
            }
        }
    }
    /* 串口监视器弹窗 */
    serialListenShow(){
        this.child.openModal();
        document.getElementById('serial-console').innerText = '';
        let selectBaudRateObj = document.getElementById('select_baud_rate');
        let selectDelimiterObj = document.getElementById('select_delimiter');
        if (Object.keys(global.comState).length === 0){
            selectBaudRateObj.disabled = selectDelimiterObj.disabled = true;
        }
        else{
            selectBaudRateObj.disabled = selectDelimiterObj.disabled = true;
            let baudValue = selectBaudRateObj.options[selectBaudRateObj.selectedIndex].value;
            let hasOpenPort = false;
            for(let port in global.comState){
                if (global.comState[port] === true){
                    selectBaudRateObj.disabled = selectDelimiterObj.disabled = false;
                    toSerialSendMessage({'command':'serial_monitor', 'data':{'port': port, 'baud': baudValue}})
                    hasOpenPort = true
                }
            }
            if (!hasOpenPort){
                this.child.closeModal()
                alert('Arduino 串口监视器打开失败:没有选择串口 !')
            }
        }
    }

    /*arduino python 代码框切换显示*/
    handleCodeBoxSwitching(){
        this.arduinoShowBox = document.getElementById('changeCodeStage').checked;
        this.forceUpdate();
    }
    /*清除输出框*/
    handleClearConsoleDiv(){
        let arduinoConsoleObj = document.getElementById('arduino_code_console'),
        codeConsoleObj = document.getElementById('code_console');
        if (arduinoConsoleObj){
            arduinoConsoleObj.innerText = '';
        }
        if (codeConsoleObj){
            codeConsoleObj.innerText = '';
        }
    }

    onRef(ref){
        this.child = ref;
    }
    /*渲染串口模态框*/
    componentDidMount() {
        let serialDiv = document.createElement('div');
        ReactDOM.render(React.createElement(SerialModal, { onRef :this.onRef}), serialDiv);
        document.body.appendChild(serialDiv)
    }
    /**
     * 处理可编辑不可编辑代码框
     * */
    handleChangeProgramMode(){
        this.programEditMode = !this.programEditMode
        let imgProgramIcon = document.getElementById('imgProgramIcon');
        imgProgramIcon.src = this.programEditMode ? ableProgramIcon: unableProgramIcon;
        this.forceUpdate();
    }
    /**
     * 处理图片大小
     * */
    handleImageSize(elementName, size){
        let imageObj = document.getElementById(elementName);
        imageObj.style.width = size;
        imageObj.style.height = size;
    }

    handleImgMouseOver(){
        this.handleImageSize('imgProgramIcon', '24px')
    }
    handleImgMouseOut(){
        this.handleImageSize('imgProgramIcon', '24px')
    }
    /**
     * 处理代码框 子组件传过来的编辑的代码
     * */
    handleChildTextarea(editCodeText){
        this.arduinoShowBox ? this.setState({editArduinoText:editCodeText}):this.setState({editPythonText:editCodeText})
    }

    render () {
        const {
            stageSize,
            vm,
        } = this.props;

        const stageDimensions = getStageDimensions(stageSize, false);

        return (
            <div className={styles.wrapDiv} id='code-editor'>
                <div className={classNames(styles.wrap)} style={{width:this.state.wid+'px'}}
                    // style={{
                    //     width: stageDimensions.width + 2
                    // }}
                >
                    <div id="dragUI" className={classNames(styles.dragFrame)} onMouseDown={e=>this.hResizeDown(e)} >
                        <div className={classNames(styles.dragIcon1)}/>
                        <div className={classNames(styles.dragIcon2)}/>
                    </div>
                    <div className={classNames(styles.hiddenHeader)}>
                        <div className={classNames(styles.programLanguageCheckbox)}>
                            <input type="checkbox" id="changeCodeStage" name="programLanguageCheckbox" onChange={this.handleCodeBoxSwitching}/>
                            <label htmlFor="changeCodeStage"/>
                        </div>
                    </div>
                    {/*<div className={classNames(styles.header)}>*/}
                    {/*    <div className={classNames(styles.programModeTitle)} >*/}
                    {/*        {*/}
                    {/*            this.arduinoShowBox ? <label>Arduino代码</label>*/}
                    {/*                :<label style={{userSelect: 'none'}}>python代码</label>*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {
                        this.arduinoShowBox && !this.programEditMode ?
                            <ArduinoCodeEditor canEdit={this.programEditMode} code={this.props.arduinoCode}/> :
                            this.arduinoShowBox && this.programEditMode ?
                                <ArduinoCodeEditor canEdit={this.programEditMode} handleChildTextarea={this.handleChildTextarea}/>:
                                !this.arduinoShowBox && !this.programEditMode ?
                                    <CodeEditor canEdit={this.programEditMode} code={this.props.code} />:
                                    <CodeEditor canEdit={this.programEditMode} handleChildTextarea={this.handleChildTextarea}/>
                    }
                    <div className={styles.consoleHeader}>
                        <div className={styles.btnGroupRight}>
                            <button className={classNames(styles.optBtn)} onClick={this.handleClearConsoleDiv}>
                                <img src={clearIcon} className={classNames(styles.programIcon, styles.imageDeblurring)}/>
                                <span className={classNames(styles.tooltiptext)}>清空</span>
                            </button>
                            <button className={classNames(styles.optBtn)} onClick={this.uploadToServer}>
                                <img src={uploadIcon} className={classNames(styles.programIcon, styles.imageDeblurring)}/>
                                <span className={classNames(styles.tooltiptext)}>上传</span>
                            </button>
                            {
                                this.arduinoShowBox ?
                                    <button className={classNames(styles.optBtn)} onClick={this.serialListenShow}>
                                        <img src={serialListenIcon} className={classNames(styles.programIcon, styles.imageDeblurring)}/>
                                        <span className={classNames(styles.tooltiptext)}>串口监视</span>
                                    </button> : null
                            }
                        </div>
                        <div className={styles.ellipsis}>
                        </div>
                        <div className={styles.btnGroupLeft}>
                            <button className={classNames(styles.optBtn)} onClick={this.handleChangeProgramMode}>
                                <img id={"imgProgramIcon"} src={unableProgramIcon} className={classNames(styles.programIcon, styles.imageDeblurring)}/>
                                <span className={classNames(styles.tooltiptext)}>编码</span>
                            </button>
                        </div>


                    </div>
                    {this.arduinoShowBox ?
                        <ArduinoCodeConsole stageSize={stageSize} vm={vm}/> :
                        <CodeConsole stageSize={stageSize} vm={vm}/>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    // This is the button's mode, as opposed to the actual current state
    stageSizeMode: state.scratchGui.stageSize.stageSize,
    // 生成代码
    code: state.scratchGui.generatorCode.code,
    arduinoCode: state.scratchGui.arduinoGeneratorCode.arduinoCode
});
const mapDispatchToProps = dispatch => ({
    onSetStageLarge: () => dispatch(setStageSize(STAGE_SIZE_MODES.large)),
    onSetStageSmall: () => dispatch(setStageSize(STAGE_SIZE_MODES.small))
});
export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(CodeEditorWrap));
