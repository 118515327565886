import axios from 'axios';
import cookie from 'react-cookies'

// const HOST = 'http://127.0.0.1:8001/'
const HOST = 'https://sd.3000iot.com/'
const URLS = {
    // saveScratchAssets: HOST+'edu/scratch/save/assets',
    // saveScratchProject: HOST+'edu/scratch/save/project',
    getSB3Url:HOST+'edu/scratch/get/url',
    saveSB3File:HOST+'edu/scratch/save/sb3',
    saveSB3Thumbnail:HOST+'edu/scratch/save/thumbnail'
}

/**
 * assets 接收的 vm解析的素材列表
 * */
// const  saveScratchAssets = (assets)=>{
//     for(var i=0;i<assets.length;i++){
//         let format = assets[i].assetType.runtimeFormat
//         axios.post(URLS.saveScratchAssets+'/'+assets[i].assetId+'.'+format, {
//             assets: assets[i],
//         }).then(res=>{
//             console.log('res ======', res)
//         }).catch(reason => {
//             console.log('reason ====>', reason)
//         })
//     }
// }

/**
 * input: 作品标题 、vm
 * */
// const saveScratchProject = (title, vm) =>{
//     axios.post(URLS.saveScratchProject, {
//         title: title,
//         src: vm.toJSON(),
//     }).then(res=>{
//         if (res.data.ret){
//             var projectId = res.data.info;
//         }else {
//             alert(res.data.info)
//         }
//     }).catch(reason => {
//         console.log('reason ====>', reason)
//     })
// }
/**
 * 保存sb3文件 input:title-标题、sb3Content-文件内容
 * */
const saveSB3File = (title, sb3Content)=>{
    let newFile = new FormData();
    let query = window.location.search.substring(1);
    let args = query.split(",");
    for (let i =0;i<args.length;i++){
        let arg = args[i].split('=')
        if (arg[0] === 'projectId'){
            newFile.append('projectId', arg[1])
        }
    }
    newFile.append('sb3', sb3Content)
    newFile.append('title', title)
    return axios.post(URLS.saveSB3File, newFile, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(res=>{
        if(res.data.ret){
            cookie.save('workid', res.data.info)
            alert('作品保存成功！')
            window.open('/work/edit/'+res.data.info)
            return res.data.info
        }else{
            alert(res.data.info)
        }
    }).catch(error=>{
        alert('功能暂未开放，敬请期待！')
        console.log('saveSB3File ERROR:', error)
    })
}

/**
 * 根据projectId获取SB3文件的url input: 作品Id
 * */
const getSB3Url = (projectId) =>{
    return axios.get(URLS.getSB3Url, {params:{
        projectId:projectId
        }}).then(res=>{
            if (res.data.ret){
                return res.data.info.src
            }else{
                // alert(res.data.info)
                console.log('getSB3Url failed', res.data.info)
            }
    }).catch(error=>{
        console.log('getSB3 ERROR:',error)
    })
}
/**
 * 保存sb3文件的缩略图 input: 作品Id image
 * */
const saveSB3Thumbnail = (projectId, image) =>{
    axios.post(URLS.saveSB3Thumbnail,{
        projectId:projectId,
        image:image
    }).then(res=>{
            if (res.data.ret){
                console.log('saveSB3Thumbnail:', res.data.info)
            }
        }
    ).catch(error=>{
        console.log('saveSB3Thumbnail ERROR:',error)
    })
}

export {
    // saveScratchAssets,
    // saveScratchProject,
    getSB3Url,
    saveSB3File,
    saveSB3Thumbnail
}
