exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*$ui-primary: hsla(215, 100%, 95%, 1); !* #E5F0FF *!*/\n/* #E5F0FF */\n/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #FF4254 主题颜色 */\n/* #3373CC */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #E53C4C */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.green-flag_green-flag_mk1Vo {\n    width: 3rem;\n    height: 3rem;\n    padding: 0.375rem;\n    border-radius: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    user-drag: none;\n    cursor: pointer;\n}\n.green-flag_green-flag_mk1Vo:hover {\n    background-color: hsla(215, 100%, 65%, 0.15);\n}\n.green-flag_green-flag_mk1Vo.green-flag_is-active_17xP9 {\n    background-color: hsla(215, 100%, 65%, 0.35);\n}\n", ""]);

// exports
exports.locals = {
	"green-flag": "green-flag_green-flag_mk1Vo",
	"greenFlag": "green-flag_green-flag_mk1Vo",
	"is-active": "green-flag_is-active_17xP9",
	"isActive": "green-flag_is-active_17xP9"
};