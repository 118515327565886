/*
* author : zyk
* time : 2020-10-22
* */
import {regQuotationMarks,replaceQuotationMarks, colorHexToRGB} from '../common.js'

export default function (Blockly) {
    // 管脚号下拉框
    Blockly.Arduino['ckb_menu_PIN_MENU'] = function (block) {
        let PIN_NUM = block.getFieldValue('PIN_MENU');
        return [PIN_NUM];
    }
    // 传感器管脚号下拉框
    Blockly.Arduino['ckb_menu_SENSOR_PIN_MENU'] = function (block) {
        let SENSOR_PIN = block.getFieldValue('SENSOR_PIN_MENU');
        return [SENSOR_PIN];
    }
    // 模拟打印管脚号下拉框
    Blockly.Arduino['ckb_menu_ANALOG_PRINT_PIN_MENU'] = function (block) {
        let ANALOG_PRINT_PIN = block.getFieldValue('ANALOG_PRINT_PIN_MENU');
        return [ANALOG_PRINT_PIN];
    }
    // 模拟量管脚号下拉框
    Blockly.Arduino['ckb_menu_ANALOG_PIN_MENU'] = function (block) {
        let ANALOG_PIN = block.getFieldValue('ANALOG_PIN_MENU');
        return [ANALOG_PIN];
    }
    // 工作模式下拉框
    Blockly.Arduino['ckb_menu_OPERATION_MODE_MENU'] = function (block) {
        let OPERATIONMODE = block.getFieldValue('OPERATION_MODE_MENU');
        return [OPERATIONMODE];
    }
    // 高低电平下拉框
    Blockly.Arduino['ckb_menu_LEVEL_MENU'] = function (block) {
        let LEVEL = block.getFieldValue('LEVEL_MENU');
        return [LEVEL];
    }
    // 数据类型下拉框
    Blockly.Arduino['ckb_menu_VARIABLE_TYPE_MENU'] = function (block) {
        let VARIABLE_TYPE = block.getFieldValue('VARIABLE_TYPE_MENU');
        return [VARIABLE_TYPE];
    }
    // 运算符下拉框
    Blockly.Arduino['ckb_menu_VARIABLE_OPERATOR_MENU'] = function (block) {
        let VARIABLE_OPERATOR = block.getFieldValue('VARIABLE_OPERATOR_MENU');
        return [VARIABLE_OPERATOR];
    }
    // 开关下拉框
    Blockly.Arduino['ckb_menu_SWITCH_MENU'] = function (block) {
        let SWITCH = block.getFieldValue('SWITCH_MENU');
        return [SWITCH];
    }
    //电机开关下拉框
    Blockly.Arduino['ckb_menu_MOTOR_MENU'] = function (block) {
        let MOTOR = block.getFieldValue('MOTOR_MENU');
        return [MOTOR];
    }
    //按钮下拉框
    Blockly.Arduino['ckb_menu_BUTTON_MENU'] = function (block) {
        let BUTTON = block.getFieldValue('BUTTON_MENU');
        return [BUTTON];
    }
    //LED颜色管脚下拉框
    Blockly.Arduino['ckb_menu_LED_PIN_MENU'] = function (block) {
        let LED_PIN = block.getFieldValue('LED_PIN_MENU');
        return [LED_PIN];
    }
    //RGB管脚下拉框
    Blockly.Arduino['ckb_menu_RGB_MENU'] = function (block) {
        let RGB = block.getFieldValue('RGB_MENU');
        return [RGB];
    }

    // 数码管型号下拉框
    Blockly.Arduino['ckb_menu_DIGITRON_MODEL_MENU'] = function (block) {
        let DIGITRON_MODEL = block.getFieldValue('DIGITRON_MODEL_MENU');
        return [DIGITRON_MODEL];
    }
    // 数码管管脚号下拉框
    Blockly.Arduino['ckb_menu_DIGITRON_PIN_MENU'] = function (block) {
        let DIGITRON_PIN = block.getFieldValue('DIGITRON_PIN_MENU');
        return [DIGITRON_PIN];
    }
    //数码管状态下拉框
    Blockly.Arduino['ckb_menu_DIGITRON_STATE_MENU'] = function (block){
        let DIGITRON_STATE = block.getFieldValue('DIGITRON_STATE_MENU');
        return [DIGITRON_STATE]
    }
    //数码管亮度下拉框
    Blockly.Arduino['ckb_menu_DIGITRON_LIGHT_MENU'] = function (block){
        let DIGITRON_LIGHT = block.getFieldValue('DIGITRON_LIGHT_MENU');
        return [DIGITRON_LIGHT]
    }
    //数码管位数下拉框
    Blockly.Arduino['ckb_menu_DIGITRON_DIGIT_MENU'] = function (block){
        let DIGITRON_DIGIT = block.getFieldValue('DIGITRON_DIGIT_MENU');
        return [DIGITRON_DIGIT]
    }
    //数码管段位下拉框
    Blockly.Arduino['ckb_menu_DIGITRON_RANK_MENU'] = function (block){
        let DIGITRON_RANK = block.getFieldValue('DIGITRON_RANK_MENU');
        return [DIGITRON_RANK]
    }
    //摇杆坐标下拉框
    Blockly.Arduino['ckb_menu_ROCKER_MENU'] = function (block){
        let ROCKER = block.getFieldValue('ROCKER_MENU');
        return [ROCKER]
    }
    // 初始化
    Blockly.Arduino['ckb_setUp'] = function (block) {
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        let SUBSTACK = Blockly.Arduino.statementToCode(block, 'SUBSTACK'),
            pass = Blockly.Arduino.addLoopTrap(SUBSTACK, block.id) || '';
        pass = pass.trim();
        Blockly.Arduino.setups_['setUp'] = pass;
        return ''
    }
    // 声明变量
    Blockly.Arduino['ckb_setVariableType'] = function (block) {
        let VARIABLE_TYPE =Blockly.Arduino.valueToCode(block, 'VARIABLE_TYPE', Blockly.Arduino.ORDER_ATOMIC);
        let NAME =Blockly.Arduino.valueToCode(block, 'NAME', Blockly.Arduino.ORDER_ATOMIC);
        return VARIABLE_TYPE+' '+NAME+';\n';
    }
    // 给变量赋值
    Blockly.Arduino['ckb_setVariableValue'] = function (block) {
        let NAME =Blockly.Arduino.valueToCode(block, 'NAME', Blockly.Arduino.ORDER_ATOMIC),
            VARIABLE_OPERATOR =Blockly.Arduino.valueToCode(block, 'VARIABLE_OPERATOR', Blockly.Arduino.ORDER_ATOMIC),
            VALUE =Blockly.Arduino.valueToCode(block, 'VALUE', Blockly.Arduino.ORDER_ATOMIC);
        return NAME+' '+VARIABLE_OPERATOR+ ' '+VALUE+';\n';
    }

    // 获取变量
    Blockly.Arduino['ckb_getVariable'] = function (block) {
        return [Blockly.Arduino.valueToCode(block, 'NAME', Blockly.Arduino.ORDER_ATOMIC), Blockly.Arduino.ORDER_ATOMIC];
    }
    // 串口打印自动换行
    Blockly.Arduino['ckb_serialPrintln'] = function (block) {
        let text = Blockly.Arduino.valueToCode(block, 'TEXT', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.setups_['serial_init'] = 'Serial.begin(9600);'
        return `Serial.println(`+text+`);\n`
    }
    //打开关闭LED灯
    Blockly.Arduino['ckb_switchLED'] = function (block) {
        let SWITCH = Blockly.Arduino.valueToCode(block, 'SWITCH', Blockly.Arduino.ORDER_ATOMIC);
        let LED_PIN = Blockly.Arduino.valueToCode(block, 'LED_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['pinMode'+LED_PIN] = 'pinMode('+LED_PIN+', OUTPUT);\n';
        return 'digitalWrite('+LED_PIN+','+SWITCH+');\n'
    }
    // 蜂鸣器播放
    // Blockly.Arduino['ckb_setBuzzer'] = function (block) {
    //     let BUZZER_FREQ =Blockly.Arduino.valueToCode(block, 'BUZZER_FREQ', Blockly.Arduino.ORDER_ATOMIC),
    //         PLAY_STATE =Blockly.Arduino.valueToCode(block, 'PLAY_STATE', Blockly.Arduino.ORDER_ATOMIC);
    //     Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
    //     Blockly.Arduino.setups_['buzzer_init'] = 'pinMode(6, OUTPUT);';
    //     if (PLAY_STATE === 'HIGH'){
    //         return 'tone(6,'+BUZZER_FREQ+');\n'
    //     }
    //     return 'digitalWrite(6,LOW);\n'
    // }
    Blockly.Arduino['ckb_setBuzzer'] = function (block) {
        let BUZZER_FREQ = Blockly.Arduino.valueToCode(block, 'BUZZER_FREQ', Blockly.Arduino.ORDER_ATOMIC);
        let BUZZER_TIME = Blockly.Arduino.valueToCode(block, 'BUZZER_TIME', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.definitions_['Tone'] =
            'void _Tone(int pin, uint16_t frequency, uint32_t duration) {\n' +
            '  int period = 1000000L / frequency;\n' +
            '  int pulse = period / 2;\n' +
            '  pinMode(pin, OUTPUT);\n' +
            '  for (uint32_t i = 0; i < duration * 1000L; i += period) {\n' +
            '    digitalWrite(pin, HIGH);\n' +
            '    delayMicroseconds(pulse);\n' +
            '    digitalWrite(pin, LOW);\n' +
            '    delayMicroseconds(pulse);\n' +
            '  }\n'+
            '}'
        return '_Tone(6,' + BUZZER_FREQ + ',' + BUZZER_TIME + ');\n'
    }
    // 按键
    Blockly.Arduino['ckb_readButtonValue'] = function (block) {
        let BUTTON =Blockly.Arduino.valueToCode(block, 'BUTTON', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['button'+BUTTON+'_init'] = 'pinMode('+BUTTON+', INPUT);\n'
        // return '!digitalRead('+BUTTON+')'
        return ['digitalRead('+BUTTON+')', Blockly.Arduino.ORDER_ATOMIC]
    }
    // 光敏传感器
    Blockly.Arduino['ckb_readLightSensorOutput'] = function (block) {
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['pin_mode'] = 'pinMode(A5, INPUT);\n'
        return ['analogRead(A5)',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 声音传感器
    Blockly.Arduino['ckb_readSoundSensorOutput'] = function (block) {
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['pin_mode'] = 'pinMode(A3, INPUT);\n'
        return ['analogRead(A3)',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // RGB灯颜色
    Blockly.Arduino['ckb_setRGBLightColor'] = function (block) {
        let color =Blockly.Python.valueToCode(block, 'COLOR', Blockly.Arduino.ORDER_ATOMIC);
        let rgb = colorHexToRGB(replaceQuotationMarks(color))
        rgb = rgb.substring(1, rgb.length-1).split(',')
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'analogWrite(9,'+rgb[2]+');\nanalogWrite(10,'+rgb[0]+');\nanalogWrite(11,'+rgb[1]+');\n'
    }

    // 摇杆
    Blockly.Arduino['ckb_readRockerValue'] = function (block) {
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        let ROCKER = Blockly.Arduino.valueToCode(block, 'ROCKER', Blockly.Arduino.ORDER_ATOMIC);
        return ['analogRead('+ROCKER+')']
    }
    // 舵机
    Blockly.Arduino['ckb_setServoAngle'] = function (block) {
        Blockly.Arduino.includes_['Servo.h'] = '#include <Servo.h>';
        let angle = Blockly.Arduino.valueToCode(block, 'ANGLE', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.definitions_['servo_init'] = 'Servo servo_A0;'
        Blockly.Arduino.setups_['servo_attach'] = 'servo_A0.attach(A0);'
        return 'servo_A0.write('+angle+');\n'
    }
    // 读取滑动电位器
    Blockly.Arduino['ckb_readSlidePotentOutput'] = function (block) {
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.setups_['pin_mode'] = 'pinMode(A4, INPUT);\n'
        return ['analogRead(A4)',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 读取超声波测距
    Blockly.Arduino['ckb_readUltrasound'] = function (block) {
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.definitions_['checkdistance'] = 'float checkdistance_3_2() {\n'+
            '  digitalWrite(3, LOW);\n'+
            '  delayMicroseconds(2);\n'+
            '  digitalWrite(3, HIGH);\n' +
            '  delayMicroseconds(10);\n' +
            '  digitalWrite(3, LOW);\n' +
            '  float distance = pulseIn(2, HIGH) / 58.00;\n' +
            '  delay(10);\n' +
            '  return distance;\n'+
            '}\n'
        Blockly.Arduino.setups_['Echo'] = 'pinMode(3, OUTPUT);\n'
        Blockly.Arduino.setups_['Trig'] = 'pinMode(2, INPUT);\n'
        return ['checkdistance_3_2()',  Blockly.Arduino.ORDER_ATOMIC]
    }
    // 数码管型号
    Blockly.Arduino['ckb_setDigitronModel'] = function (block) {
        let DIGITRON_MODEL =Blockly.Arduino.valueToCode(block, 'DIGITRON_MODEL', Blockly.Arduino.ORDER_ATOMIC);
        let DIO_PIN =Blockly.Arduino.valueToCode(block, 'DIO_PIN', Blockly.Arduino.ORDER_ATOMIC);
        let CLK_PIN =Blockly.Arduino.valueToCode(block, 'CLK_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        Blockly.Arduino.includes_['TM16XX.h'] = '#include "TM16XX.h"';
        Blockly.Arduino.definitions_['TM16XX_Digitron;'] = 'TM16XX Digitron('+DIGITRON_MODEL+','+DIO_PIN+','+CLK_PIN+');\n'
    }
    //数码管状态
    Blockly.Arduino['ckb_setDigitronState'] = function (block) {
        let DIGITRON_STATE =Blockly.Arduino.valueToCode(block, 'DIGITRON_STATE', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'Digitron.setMode('+DIGITRON_STATE+');\n'

    }
    //数码管亮度
    Blockly.Arduino['ckb_setDigitronLight'] = function (block) {
        let DIGITRON_LIGHT =Blockly.Arduino.valueToCode(block, 'DIGITRON_LIGHT', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'Digitron.setBrightness('+DIGITRON_LIGHT+');\n'
    }
    //数码管显示
    Blockly.Arduino['ckb_setDigitronDisplay'] = function (block) {
        let DIGITRON_DISPLAY =Blockly.Arduino.valueToCode(block, 'DIGITRON_DISPLAY', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'Digitron.display('+DIGITRON_DISPLAY+');\n'
    }
    //数码管位数显示对应值
    Blockly.Arduino['ckb_setDigitronDigit'] = function (block) {
        let DIGITRON_DIGIT =Blockly.Arduino.valueToCode(block, 'DIGITRON_DIGIT', Blockly.Arduino.ORDER_ATOMIC);
        let DIGITRON_DISPLAY =Blockly.Arduino.valueToCode(block, 'DIGITRON_DISPLAY', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'Digitron.display('+DIGITRON_DIGIT+','+DIGITRON_DISPLAY+');\n'
    }
    //数码管位数显示对应段位值
    Blockly.Arduino['ckb_setDigitronRank'] = function (block) {
        let DIGITRON_DIGIT =Blockly.Arduino.valueToCode(block, 'DIGITRON_DIGIT', Blockly.Arduino.ORDER_ATOMIC);
        let DIGITRON_RANK =Blockly.Arduino.valueToCode(block, 'DIGITRON_RANK', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'Digitron.display('+DIGITRON_DIGIT+','+DIGITRON_RANK+');\n'
    }
    // 设置管脚工作模式
    Blockly.Arduino['ckb_setOperationMode'] = function (block) {
        let PIN_NUM = Blockly.Arduino.valueToCode(block, 'PIN', Blockly.Arduino.ORDER_ATOMIC);
        let OPERATIONMODE = Blockly.Arduino.valueToCode(block, 'OPERATION_MODE', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'pinMode(' + PIN_NUM + ',' + OPERATIONMODE + ');\n'
    }
    // 设置输出数字量
    Blockly.Arduino['ckb_setDigitalOutput'] = function (block) {
        let Digital_PIN = Blockly.Arduino.valueToCode(block, 'Digital_PIN', Blockly.Arduino.ORDER_ATOMIC);
        let LEVEL = Blockly.Arduino.valueToCode(block, 'LEVEL', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'digitalWrite(' + Digital_PIN + ',' + LEVEL + ');\n'
    }
    // 读取数字量
    Blockly.Arduino['ckb_readDigitalValue'] = function (block) {
        let Digital_PIN = Blockly.Arduino.valueToCode(block, 'Digital_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return ['digitalRead('+Digital_PIN+')',Blockly.Arduino.ORDER_ATOMIC]
    }
    // 模拟输出
    Blockly.Arduino['ckb_setAnalogOutput'] = function (block) {
        let ANALOG_OUTPUT_PIN = Blockly.Arduino.valueToCode(block, 'ANALOG_OUTPUT_PIN', Blockly.Arduino.ORDER_ATOMIC);
        let TEXT = Blockly.Arduino.valueToCode(block, 'TEXT', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return 'analogWrite(' + ANALOG_OUTPUT_PIN + ',' + TEXT + ');\n'
    }
    // 读取管脚模拟量
    Blockly.Arduino['ckb_loadAnalogOutput'] = function (block) {
        let ADC_PIN = Blockly.Arduino.valueToCode(block, 'ADC_PIN', Blockly.Arduino.ORDER_ATOMIC);
        Blockly.Arduino.includes_['Arduino.h'] = '#include <Arduino.h>';
        return ['analogRead('+ADC_PIN+')',  Blockly.Arduino.ORDER_ATOMIC]
    }
}
