exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*$ui-primary: hsla(215, 100%, 95%, 1); !* #E5F0FF *!*/\n/* #E5F0FF */\n/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #FF4254 主题颜色 */\n/* #3373CC */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #E53C4C */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.waveform_container_243I7 {\r\n    width: 100%;\r\n}\n.waveform_waveform-path_2LeiM {\r\n    /*\r\n        This color is lighter than sound-primary, but\r\n        cannot use alpha because of overlapping elements.\r\n    */\r\n    fill: hsl(300, 54%, 72%);\r\n    stroke: hsla(300, 48%, 50%, 1);\r\n}\n.waveform_baseline_2hE-m {\r\n    stroke: hsla(300, 48%, 50%, 1);\r\n}\r\n", ""]);

// exports
exports.locals = {
	"container": "waveform_container_243I7",
	"waveform-path": "waveform_waveform-path_2LeiM",
	"waveformPath": "waveform_waveform-path_2LeiM",
	"baseline": "waveform_baseline_2hE-m"
};