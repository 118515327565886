/*
* author : wlc
* time : 2020-8-6
* */
import {regQuotationMarks,replaceQuotationMarks,ToModbusCRC16} from '../common.js'
export default function (Blockly) {

    // 高低电平下拉框
    Blockly.Python['IoTb_menu_LEVEL_MENU'] = function(block) {
        let level = parseInt(block.getFieldValue('LEVEL_MENU'));
        return [level];
    }
    // 数字输入管脚下拉框
    Blockly.Python['IoTb_menu_DIGITAL_INPUT_PIN_MENU'] = function(block) {
        let DIGITAL_INPUT_PIN = parseInt(block.getFieldValue('DIGITAL_INPUT_PIN_MENU'));
        return [DIGITAL_INPUT_PIN];
    }

    // 数字输出管脚 下拉框
    Blockly.Python['IoTb_menu_DIGITAL_OUTPUT_PIN_MENU'] = function(block) {
        let DIGITAL_OUTPUT_PIN = parseInt(block.getFieldValue('DIGITAL_OUTPUT_PIN_MENU'));
        return [DIGITAL_OUTPUT_PIN];
    }

    // 模拟输入管脚 下拉框
    Blockly.Python['IoTb_menu_ANALOG_INPUT_PIN_MENU'] = function(block) {
        let ANALOG_INPUT_PIN = parseInt(block.getFieldValue('ANALOG_INPUT_PIN_MENU'));
        return [ANALOG_INPUT_PIN];
    }

    // 模拟输出管脚 下拉框
    Blockly.Python['IoTb_menu_ANALOG_OUTPUT_PIN_MENU'] = function(block) {
        let ANALOG_OUTPUT_PIN = parseInt(block.getFieldValue('ANALOG_OUTPUT_PIN_MENU'));
        return [ANALOG_OUTPUT_PIN];
    }
    // PWM模拟输出 下拉框
    Blockly.Python['IoTb_menu_PWM_ANALOG_PIN_MENU'] = function(block) {
        let PWN_ANALOG_PIN = block.getFieldValue('PWM_ANALOG_PIN_MENU');
        return [PWN_ANALOG_PIN];
    }
    // wlan模式下拉框
    Blockly.Python['IoTb_menu_WLAN_IF_MENU'] = function(block) {
        let wlan = block.getFieldValue('WLAN_IF_MENU');
        return [wlan];
    }
    // wlan状态下拉框
    Blockly.Python['IoTb_menu_WLAN_ACTIVE_MENU'] = function(block) {
        let wlan = block.getFieldValue('WLAN_ACTIVE_MENU');
        return [wlan];
    }
    // wlan信息下拉框
    Blockly.Python['IoTb_menu_WLAN_WIFIINFO_MENU'] = function(block) {
        let wlan = block.getFieldValue('WLAN_WIFIINFO_MENU');
        return [wlan];
    }
    // wlan信息下拉框
    Blockly.Python['IoTb_menu_WIFIINFO_MENU'] = function(block) {
        let wlan = block.getFieldValue('WIFIINFO_MENU');
        return [wlan];
    }
    // wlan设置下拉框
    Blockly.Python['IoTb_menu_WLANWIFICONFIG_MENU'] = function(block) {
        let wlan = block.getFieldValue('WLANWIFICONFIG_MENU');
        return [wlan];
    }
    // 连接类型下拉框
    Blockly.Python['IoTb_menu_SOCKTYPE_MENU'] = function(block) {
        let wlan = block.getFieldValue('SOCKTYPE_MENU');
        return [wlan];
    }
    // 开关
    Blockly.Python['IoTb_menu_SWITCH_MENU'] = function(block) {
        let SWITCH = block.getFieldValue('SWITCH_MENU');
        return [SWITCH];
    }
    // 温湿度传感器
    Blockly.Python['IoTb_menu_DHT_MENU'] = function(block){
        let DHT = block.getFieldValue('DHT_MENU');
        return [DHT];
    }
    // 数码管状态下拉框
    Blockly.Python['IoTb_menu_DIGITAL_TUBE_MENU'] = function(block){
        let DIGITAL_TUBE = block.getFieldValue('DIGITAL_TUBE_MENU');
        return [DIGITAL_TUBE];
    }
    // 数码管小数点位数下拉框
    Blockly.Python['IoTb_menu_TUBE_DOT_NUM_MENU'] = function(block){
        let DOT_NUM = block.getFieldValue('TUBE_DOT_NUM_MENU');
        return [DOT_NUM];
    }
    // 数码管小数点亮灭下拉框
    Blockly.Python['IoTb_menu_TUBE_DOT_SWITCH_MENU'] = function(block){
        let DOT_SWITCH = block.getFieldValue('TUBE_DOT_SWITCH_MENU');
        return [DOT_SWITCH];
    }
    // 电机下拉框
    Blockly.Python['IoTb_menu_MOTOR_MENU'] = function(block){
        let MOTOR = block.getFieldValue('MOTOR_MENU');
        return [MOTOR]
    }
    // 电机下拉框
    Blockly.Python['IoTb_menu_DIRECTION_MENU'] = function(block){
        let DIRECTION = block.getFieldValue('DIRECTION_MENU');
        return [DIRECTION]
    }
    //  语音识别模块管脚
    Blockly.Python['IoTb_menu_UART_PIN_MENU'] = function(block){
        let UART_PIN = block.getFieldValue('UART_PIN_MENU');
        return [UART_PIN]
    }
    // 语音识别命令下拉框
    Blockly.Python['IoTb_menu_Speech_Recognition_MENU'] = function(block){
        let Recognition_CMD = block.getFieldValue('Speech_Recognition_MENU');
        return [Recognition_CMD]
    }
    // OLED显示类型下拉框
    Blockly.Python['IoTb_menu_OLED_SHOW_TYPE_MENU'] = function(block){
        let SHOW_TYPE = block.getFieldValue('OLED_SHOW_TYPE_MENU');
        return [SHOW_TYPE]
    }
    // OLED描点亮度下拉框
    Blockly.Python['IoTb_menu_OLED_LUMINANCE_MENU'] = function(block){
        let OLED_LUMINANCE = block.getFieldValue('OLED_LUMINANCE_MENU');
        return [OLED_LUMINANCE]
    }
    // OLED 动作下拉框
    Blockly.Python['IoTb_menu_OLED_ACTION_MENU'] = function(block){
        let OLED_ACTION = block.getFieldValue('OLED_ACTION_MENU');
        return [OLED_ACTION]
    }
    // OLED 方向下拉框
    Blockly.Python['IoTb_menu_OLED_DIRECTION_MENU'] = function(block){
        let OLED_DIRECTION = block.getFieldValue('OLED_DIRECTION_MENU');
        return [OLED_DIRECTION]
    }
    // OLED 矩形下拉框
    Blockly.Python['IoTb_menu_OLED_RECTANGLE_MENU'] = function(block){
        let OLED_RECTANGLE = block.getFieldValue('OLED_RECTANGLE_MENU');
        return [OLED_RECTANGLE]
    }
    // OLED 圆形下拉框
    Blockly.Python['IoTb_menu_OLED_CIRCULAR_MENU'] = function(block){
        let OLED_CIRCULAR = block.getFieldValue('OLED_CIRCULAR_MENU');
        return [OLED_CIRCULAR]
    }
    // OLED 三角形下拉框
    Blockly.Python['IoTb_menu_OLED_TRIANGLE_MENU'] = function(block){
        let OLED_TRIANGLE = block.getFieldValue('OLED_TRIANGLE_MENU');
        return [OLED_TRIANGLE]
    }
    // OLED 行号下拉框
    Blockly.Python['IoTb_menu_OLED_ROW_MENU'] = function(block){
        let OLED_ROW = block.getFieldValue('OLED_ROW_MENU');
        return [OLED_ROW]
    }
    //串口地址
    Blockly.Python['IoTb_menu_MOTOR_PORT_ADDRESS_MENU'] = function(block){
        let PORT_ADDRESS = block.getFieldValue('PORT_ADDRESS_MENU');
        return [PORT_ADDRESS]
    }
    //广播频道
    Blockly.Python['IoTb_menu_RADIO_CHANNEL_MENU'] = function(block){
        let RADIO_CHANNEL = block.getFieldValue('RADIO_CHANNEL_MENU');
        return [RADIO_CHANNEL]
    }
    //数据长度
    Blockly.Python['IoTb_menu_DATA_LENGTH_MENU'] = function(block){
        let DATA_LENGTH = block.getFieldValue('DATA_LENGTH_MENU');
        return [DATA_LENGTH]
    }
    // 定义变量
    Blockly.Python['IoTb_setVariable'] = function (block) {
        let NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC));
        let VALUE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC));
        return NAME+' = '+VALUE+'\n';
    };
    // 设置全局变量
    Blockly.Python['IoTb_setVariableGlobal'] = function (block) {
        let NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC));
        return 'global '+NAME+'\n';
    };
    // 打印
    Blockly.Python['IoTb_printText'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        return 'print(' + TEXT + ')\n';
    };
    // jsonLoadsValue
    Blockly.Python['IoTb_jsonLoadsValue'] = function (block) {
        Blockly.Python.definitions_['import_json'] = 'import json';
        let VALUE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC));
        let NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC));
        return NAME+' = json.loads('+VALUE+')\n';
    };
    // getDictionaryValue
    Blockly.Python['IoTb_getDictionaryValue'] = function (block) {
        let DICTIONARY = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'DICTIONARY', Blockly.Python.ORDER_ATOMIC));
        let KEY = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'KEY', Blockly.Python.ORDER_ATOMIC));
        return [DICTIONARY+'['+KEY+']'];
    };

    //设置数字输出
    Blockly.Python['IoTb_setDigitalOutput'] = function (block) {
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let DIGITAL_OUTPUT_PIN = Blockly.Python.valueToCode(block, 'DIGITAL_OUTPUT_PIN', Blockly.Python.ORDER_ATOMIC);
        let LEVEL = Blockly.Python.valueToCode(block, 'LEVEL', Blockly.Python.ORDER_ATOMIC);
        return 'pin'+DIGITAL_OUTPUT_PIN+' = machine.Pin('+DIGITAL_OUTPUT_PIN+',machine.Pin.OUT)\n'+
            'pin'+DIGITAL_OUTPUT_PIN+'.value('+LEVEL+')\n'
    }

    //读取数字量
    Blockly.Python['IoTb_loadDigitalOutput'] = function(block){
        let DIGITAL_INPUT_PIN = Blockly.Python.valueToCode(block, 'DIGITAL_INPUT_PIN', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let functionName = Blockly.Python.provideFunction_(
            'digitalRead', ['def '+ Blockly.Python.FUNCTION_NAME_PLACEHOLDER_+'(p):',
                '  pin0 = machine.Pin(p, machine.Pin.IN)',
                '  return pin0.value()']
        )
        let code = functionName+'('+DIGITAL_INPUT_PIN+')'
        return [code]
    }

    //设置模拟输出
    Blockly.Python['IoTb_setAnalogOutput'] = function(block){
        let ANALOG_OUTPUT_PIN = Blockly.Python.valueToCode(block, 'ANALOG_OUTPUT_PIN', Blockly.Python.ORDER_ATOMIC);
        let NUM = Blockly.Python.valueToCode(block, 'NUM', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return'dac'+ANALOG_OUTPUT_PIN+' = machine.DAC(machine.Pin('+ANALOG_OUTPUT_PIN+'))\n' +
            'dac'+ANALOG_OUTPUT_PIN+'.write('+NUM+')\n'
    }

    //读取模拟量
    Blockly.Python['IoTb_loadAnalogOutput'] = function(block){
        let ANALOG_INPUT_PIN = Blockly.Python.valueToCode(block, 'ANALOG_INPUT_PIN', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let functionName = Blockly.Python.provideFunction_(
            'analogRead',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(p):',
                '  adc = machine.ADC(machine.Pin(p))',
                '  adc.atten(machine.ADC.ATTN_11DB)',
                '  return adc.read()']);
        let code = functionName+'('+ANALOG_INPUT_PIN+')'
        return [code]
    }
    // i2c初始化
    Blockly.Python['IoTb_initI2C'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let I2C = Blockly.Python.valueToCode(block, 'I2C', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let SDA = Blockly.Python.valueToCode(block, 'SDA', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let SCL = Blockly.Python.valueToCode(block, 'SCL', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let FREQUENCY = Blockly.Python.valueToCode(block, 'FREQUENCY', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return I2C+' = machine.I2C(scl = machine.Pin('+SCL+'), sda = machine.Pin('+SDA+'), freq = '+FREQUENCY+')\n'

    }

    // i2c写入数字地址：
    Blockly.Python['IoTb_I2CWriteNumbers'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let I2C = Blockly.Python.valueToCode(block, 'I2C', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let ADRESS = Blockly.Python.valueToCode(block, 'ADRESS', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let VALUE = Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return I2C+'.writeto('+ADRESS+', '+VALUE+')\n'

    }

    // i2c读取数字地址：
    Blockly.Python['IoTb_I2CLoadNumbers'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let I2C = Blockly.Python.valueToCode(block, 'I2C', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let ADRESS = Blockly.Python.valueToCode(block, 'ADRESS', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let VALUE = Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let code = ''+I2C+'.readfrom('+ADRESS+', '+VALUE+')'
        return [code]

    }

    // i2c搜索链接的设备：
    Blockly.Python['IoTb_I2CSearchDevices'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let I2C = Blockly.Python.valueToCode(block, 'I2C', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let code = ''+I2C+'.scan()'
        return [code]

    }

    // i2c搜索链接的设备：
    Blockly.Python['IoTb_I2CLoad'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let I2C = Blockly.Python.valueToCode(block, 'I2C', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let code = ''+I2C+'.read()'
        return [code]

    }

    // i2c是否有数据：
    Blockly.Python['IoTb_I2CLoadSuccess'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let I2C = Blockly.Python.valueToCode(block, 'I2C', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let code = ''+I2C+'.available()'
        return [code, Blockly.Python.ORDER_ATOMIC]

    }
    // 初始化pwm对象
    Blockly.Python['IoTb_initPWMAnalogOutput'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let PWM_PIN = Blockly.Python.valueToCode(block, 'PWM_PIN', Blockly.Python.ORDER_ATOMIC);
        return 'pwm'+PWM_PIN+' = machine.PWM(machine.Pin('+PWM_PIN+'))\n'
    }

    // pwm对象设置数值
    Blockly.Python['IoTb_setPWMAnalogOutputData'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let PWM_PIN = Blockly.Python.valueToCode(block, 'PWM_PIN', Blockly.Python.ORDER_ATOMIC);
        let NUM = Blockly.Python.valueToCode(block, 'NUM', Blockly.Python.ORDER_ATOMIC);
        return 'pwm'+PWM_PIN+'.duty('+NUM+')\n'
    }

    // pwm对象设置使用频率
    Blockly.Python['IoTb_setPWMAnalogOutputFrequency'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let PWM_PIN = Blockly.Python.valueToCode(block, 'PWM_PIN', Blockly.Python.ORDER_ATOMIC);
        let FREQUENCY = Blockly.Python.valueToCode(block, 'FREQUENCY', Blockly.Python.ORDER_ATOMIC);
        return 'pwm'+PWM_PIN+'.freq('+FREQUENCY+')\n'
    }


    //舵机
    Blockly.Python['IoTb_setServoAngle'] = function(block){
        Blockly.Python.definitions_['import_servo'] = 'import servo';
        let ANGLE = Blockly.Python.valueToCode(block, 'ANGLE', Blockly.Python.ORDER_ATOMIC);
        let DIGITAL_OUTPUT_PIN = Blockly.Python.valueToCode(block, 'DIGITAL_OUTPUT_PIN', Blockly.Python.ORDER_ATOMIC);
        return 'servo.servo_write_angle('+DIGITAL_OUTPUT_PIN+','+ANGLE+')\n'
    }
    // LED灯
    Blockly.Python['IoTb_setLEDLight'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let DIGITAL_OUTPUT_PIN = Blockly.Python.valueToCode(block, 'DIGITAL_OUTPUT_PIN', Blockly.Python.ORDER_ATOMIC),
            SWITCH = Blockly.Python.valueToCode(block, 'SWITCH', Blockly.Python.ORDER_ATOMIC)
        Blockly.Python.definitions_['import_LED'+DIGITAL_OUTPUT_PIN] = 'pin'+DIGITAL_OUTPUT_PIN+' = machine.Pin('+DIGITAL_OUTPUT_PIN+', machine.Pin.OUT)\n'
        return 'pin'+DIGITAL_OUTPUT_PIN+'.value('+SWITCH+')\n'
    }
    //dht 温湿度传感器
    Blockly.Python['IoTb_loadTemperatureHumiditySensorValue'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_dhtx'] = 'import dhtx';
        let DIGITAL_OUTPUT_PIN = Blockly.Python.valueToCode(block, 'DIGITAL_OUTPUT_PIN', Blockly.Python.ORDER_ATOMIC),
            dht = Blockly.Python.valueToCode(block, 'DHT', Blockly.Python.ORDER_ATOMIC)
        return ['dhtx.get_dht_'+dht+'(\'dht11\','+DIGITAL_OUTPUT_PIN+')']
    }
    //蜂鸣器
    Blockly.Python['IoTb_setBuzzer'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_utime'] = 'import utime';
        let DIGITAL_OUTPUT_PIN = Blockly.Python.valueToCode(block, 'DIGITAL_OUTPUT_PIN', Blockly.Python.ORDER_ATOMIC),
            FREQ = Blockly.Python.valueToCode(block, 'FREQ', Blockly.Python.ORDER_ATOMIC),
            TIME = Blockly.Python.valueToCode(block, 'TIME', Blockly.Python.ORDER_ATOMIC)
        return 'pwm'+DIGITAL_OUTPUT_PIN+' = machine.PWM(machine.Pin('+DIGITAL_OUTPUT_PIN+'))\n'+
            'pwm'+DIGITAL_OUTPUT_PIN+'.freq('+FREQ+')\n'+
            'utime.sleep('+TIME+')\n'+
            'pwm'+DIGITAL_OUTPUT_PIN+'.deinit()\n'
    }
    // 红外传感器
    Blockly.Python['IoTb_loadInfraredSensorValue'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let DIGITAL_INPUT_PIN = Blockly.Python.valueToCode(block, 'DIGITAL_INPUT_PIN', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_infrared_sensor'+DIGITAL_INPUT_PIN] = 'infrared'+DIGITAL_INPUT_PIN+' = machine.Pin('+DIGITAL_INPUT_PIN+', machine.Pin.IN)\n';
        return ['infrared'+DIGITAL_INPUT_PIN+'.value()']
    }
    // 声音传感器
    Blockly.Python['IoTb_loadSoundSensorValue'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let ANALOG_INPUT_PIN = Blockly.Python.valueToCode(block, 'ANALOG_INPUT_PIN', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_sound_sensor'+ANALOG_INPUT_PIN] = 'adc'+ANALOG_INPUT_PIN+' = machine.ADC(machine.Pin('+ANALOG_INPUT_PIN+'))\n';
        return ['adc'+ANALOG_INPUT_PIN+'.read()']
    }
    // 风扇模块
    Blockly.Python['IoTb_setFanMoudle'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let PWM_ANALOG_PIN = Blockly.Python.valueToCode(block, 'PWM_ANALOG_PIN', Blockly.Python.ORDER_ATOMIC),
            FREQ = Blockly.Python.valueToCode(block, 'FREQ', Blockly.Python.ORDER_ATOMIC),
            DUTY = Blockly.Python.valueToCode(block, 'DUTY', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_fan_moudle'+PWM_ANALOG_PIN] = 'pwm'+PWM_ANALOG_PIN+' = machine.PWM(machine.Pin('+PWM_ANALOG_PIN+'), freq='+FREQ+')\n';
        return 'pwm'+PWM_ANALOG_PIN+'.duty('+DUTY+')\n'
    }
    // 光线传感器
    Blockly.Python['IoTb_loadLightSensorValue'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let ANALOG_INPUT_PIN = Blockly.Python.valueToCode(block, 'ANALOG_INPUT_PIN', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_light_sensor'+ANALOG_INPUT_PIN] = 'adc'+ANALOG_INPUT_PIN+' = machine.ADC(machine.Pin('+ANALOG_INPUT_PIN+'))\n' +
            'adc'+ANALOG_INPUT_PIN+'.atten(machine.ADC.ATTN_11DB)\n';
        return ['adc'+ANALOG_INPUT_PIN+'.read()']
    }
    // 震动传感器
    Blockly.Python['IoTb_loadVibrationSensor'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let DIGITAL_INPUT_PIN = Blockly.Python.valueToCode(block, 'DIGITAL_INPUT_PIN', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_vibration_sensor'+DIGITAL_INPUT_PIN] = 'pin'+DIGITAL_INPUT_PIN+' = machine.Pin('+DIGITAL_INPUT_PIN+', machine.Pin.IN)\n'
        return ['pin'+DIGITAL_INPUT_PIN+'.value()']
    }
    // 继电器
    Blockly.Python['IoTb_setRelayLevel'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let DIGITAL_OUTPUT_PIN = Blockly.Python.valueToCode(block, 'DIGITAL_OUTPUT_PIN', Blockly.Python.ORDER_ATOMIC),
            SWITCH = Blockly.Python.valueToCode(block, 'SWITCH', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_relay'+DIGITAL_OUTPUT_PIN] = 'pin'+DIGITAL_OUTPUT_PIN+' = machine.Pin('+DIGITAL_OUTPUT_PIN+', machine.Pin.OUT)\n'
        return 'pin'+DIGITAL_OUTPUT_PIN+'.value('+SWITCH+')\n'
    }
    // 土壤湿度传感器
    Blockly.Python['IoTb_loadSoilMoistureSensorValue'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let ANALOG_INPUT_PIN = Blockly.Python.valueToCode(block, 'ANALOG_INPUT_PIN', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_soil_moisture_sensor'+ANALOG_INPUT_PIN] = 'adc'+ANALOG_INPUT_PIN+' = machine.ADC(machine.Pin('+ANALOG_INPUT_PIN+'))\n' +
            'adc'+ANALOG_INPUT_PIN+'.atten(machine.ADC.ATTN_11DB)\n'
        return ['adc'+ANALOG_INPUT_PIN+'.read()']
    }
    // 超声波测距
    Blockly.Python['IoTb_loadUltrasonicSensor'] = function(block){
        Blockly.Python.definitions_['import_sonar'] = 'import sonar';
        let trig = Blockly.Python.valueToCode(block, 'DIGITAL_OUTPUT_PIN', Blockly.Python.ORDER_ATOMIC),
            echo = Blockly.Python.valueToCode(block, 'DIGITAL_INPUT_PIN', Blockly.Python.ORDER_ATOMIC);
        return ['sonar.Sonar('+trig+', '+echo+').checkdist()']
    }
    // 数码管初始化
    Blockly.Python['IoTb_setDigitalTubeState'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_tm1650'] = 'import tm1650';
        let SDA = Blockly.Python.valueToCode(block, 'SDA', Blockly.Python.ORDER_ATOMIC),
            SCL = Blockly.Python.valueToCode(block, 'SCL', Blockly.Python.ORDER_ATOMIC),
            DIGITAL_TUBE = Blockly.Python.valueToCode(block, 'DIGITAL_TUBE', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_tube'+SCL+'_'+SDA] = 'i2c = machine.I2C(scl = machine.Pin('+SCL+'), sda = machine.Pin('+SDA+'), freq = 100000)\n' +
            'disp = tm1650.TM1650(i2c)\n'
        return 'disp.tm1650_'+DIGITAL_TUBE+'()\n'
    }
    // 数码管显示数字
    Blockly.Python['IoTb_setDigitalTubeShowNum'] = function(block){
        let NUM = Blockly.Python.valueToCode(block, 'NUM', Blockly.Python.ORDER_ATOMIC);
        return 'disp.tm1650_show_num('+NUM+')\n'
    }
    // 数码管显示点
    Blockly.Python['IoTb_setDigitalTubeShowDot'] = function(block){
        let NUM = Blockly.Python.valueToCode(block, 'NUM', Blockly.Python.ORDER_ATOMIC),
            SWITCH = Blockly.Python.valueToCode(block, 'SWITCH', Blockly.Python.ORDER_ATOMIC)
        return 'disp.tm1650_show_dot('+NUM+', '+SWITCH+')\n'
    }
    // 电机
    Blockly.Python['IoTb_setMotorSpeed'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let motor = Blockly.Python.valueToCode(block, 'MOTOR', Blockly.Python.ORDER_ATOMIC),
            direction = Blockly.Python.valueToCode(block, 'DIRECTION', Blockly.Python.ORDER_ATOMIC),
            speed = Blockly.Python.valueToCode(block, 'SPEED', Blockly.Python.ORDER_ATOMIC);
        if (speed>1023){
            speed = 1023
        }else if( speed < 0 || speed === 'NaN'){
            speed = 0
        }
        let motorArr = JSON.parse(motor);
        let retCode;
        let pin0 = motorArr[0], pin1 = motorArr[1];
        Blockly.Python.definitions_['import_motor'+pin0] = 'motor'+pin0+' = machine.PWM(machine.Pin('+pin0+'))\n'+
            'motor'+pin0+'.freq(20000)\n';
        Blockly.Python.definitions_['import_motor'+pin1] = 'motor'+pin1+' = machine.PWM(machine.Pin('+pin1+'))\n'+
            'motor'+pin1+'.freq(20000)\n'
        let _speed = '1023 - '+speed.toString()  //修改成可以是表达式的
        if (direction === '1'){
            retCode = 'motor'+motorArr[0]+'.duty('+_speed+')\n'+
                'motor'+motorArr[1]+'.duty(1023)\n'
        }else{
            retCode = 'motor'+motorArr[0]+'.duty(1023)\n'+
                'motor'+motorArr[1]+'.duty('+_speed+')\n'
        }
        return retCode
    }

    //init mqtt server
    Blockly.Python['IoTb_initMqttServer'] = function(block){
        Blockly.Python.definitions_['import_SCBoard_app'] = 'import SCBoard_app';
        let WIFI_NAME = Blockly.Python.valueToCode(block, 'WIFI_NAME', Blockly.Python.ORDER_ATOMIC),
            WIFI_PASSWORD = Blockly.Python.valueToCode(block, 'WIFI_PASSWORD', Blockly.Python.ORDER_ATOMIC),
            DEVICE_ID = Blockly.Python.valueToCode(block, 'DEVICE_ID', Blockly.Python.ORDER_ATOMIC),
            NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC)),
            password = '  print('+NAME+')\n' +
                '  global board_app\n',
            SUBSTACK = Blockly.Python.statementToCode(block, 'SUBSTACK'),
            pass = password+Blockly.Python.addLoopTrap(SUBSTACK, block.id) || password;
        return 'def app_con_data('+NAME+'):\n' +pass+'\n'+'board_app = SCBoard_app.SCBoard_app('+WIFI_NAME+','+WIFI_PASSWORD+','+DEVICE_ID+')\n' +
            'board_app.call_app_data(app_con_data)\n'
    }

    // 一键式连接wifi
    Blockly.Python['IoTb_oneClickConnectToWifi'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_utime'] = 'import utime';
        let NAME = Blockly.Python.valueToCode(block, 'WIFI_NAME', Blockly.Python.ORDER_ATOMIC);
        let PASSWORD = Blockly.Python.valueToCode(block, 'WIFI_PASSWORD', Blockly.Python.ORDER_ATOMIC);
        let functionName = Blockly.Python.provideFunction_(
            'connectToWifi',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  wlan = network.WLAN(network.STA_IF)',
                '  wlan.active(True)',
                '  wlan.connect('+NAME+','+PASSWORD+')',
                '  for i in range(60):',
                '    if wlan.isconnected():',
                '      print("连接到wifi成功")',
                '      break;',
                '    utime.sleep(1)',
                '    print("正在连接wifi...")',
                '  if not wlan.isconnected():',
                '    print("连接wifi失败！")'
            ]);
        return [functionName+'()']
    }


    // 初始化wlan设置wifi模式为
    Blockly.Python['IoTb_wlanInit'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let WLANIF = Blockly.Python.valueToCode(block, 'WLANIF', Blockly.Python.ORDER_ATOMIC);
        return 'wlan = network.WLAN(network.'+WLANIF+')\n'
    }

    // wlan设置状态
    Blockly.Python['IoTb_wlanSetActive'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let ACTIVE = Blockly.Python.valueToCode(block, 'ACTIVE', Blockly.Python.ORDER_ATOMIC);
        return 'wlan.active('+ACTIVE+')\n'
    }
    // wlan获取连接状态
    Blockly.Python['IoTb_wlanGetActive'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        return ['wlan.active()', Blockly.Python.ORDER_ATOMIC]
    }
    // wlan扫描可用wifi
    Blockly.Python['IoTb_wlanGetUsableWifi'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let WLANINFO = Blockly.Python.valueToCode(block, 'WLANINFO', Blockly.Python.ORDER_ATOMIC).replace(/\s*/g,'');
        return ['wlan.scan()'+WLANINFO]
    }

    // wlan连接wifi
    Blockly.Python['IoTb_wlanConnectWifi'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let WIFINAME = Blockly.Python.valueToCode(block, 'WIFINAME', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let WIFIPASSWORD = Blockly.Python.valueToCode(block, 'WIFIPASSWORD', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return 'wlan.connect("'+WIFINAME+'","'+WIFIPASSWORD+'")\n'
    }

    // 获取wifi连接状态
    Blockly.Python['IoTb_wlanWifiGetActive'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        return ['wlan.isconnected()', Blockly.Python.ORDER_ATOMIC]
    }

    // 获取wifi的信息
    Blockly.Python['IoTb_wlanWifigetInfo'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let WIFIINFO = Blockly.Python.valueToCode(block, 'WIFIINFO', Blockly.Python.ORDER_ATOMIC);
        let code = 'wlan.ifconfig()['+WIFIINFO+']'
        return [code]
    }

    // 获取wifi的配置信息
    Blockly.Python['IoTb_wlanWifigetConfigInfo'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let WLANWIFICONFIG = Blockly.Python.valueToCode(block, 'WLANWIFICONFIG', Blockly.Python.ORDER_ATOMIC);
        let code = 'wlan.config(\"'+WLANWIFICONFIG+'\")'
        return [code]

    }

    // wifi断开连接
    Blockly.Python['IoTb_wlanDisconnectWifi'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        return 'wlan.disconnect()\n'

    }
    //获取wifi接入点名称或通道
    Blockly.Python['IoTb_wlanSetConfigt'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        let ESSID = Blockly.Python.valueToCode(block, 'ESSID', Blockly.Python.ORDER_ATOMIC);
        let CHANNEL = Blockly.Python.valueToCode(block, 'CHANNEL', Blockly.Python.ORDER_ATOMIC);
        return 'wlan.config(essid = '+ESSID+', channel = '+CHANNEL+')\n'

    }

    // socket创建套接字
    Blockly.Python['IoTb_socketCreat'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let SOCK = Blockly.Python.valueToCode(block, 'SOCK', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let SOCKTYPE = Blockly.Python.valueToCode(block, 'SOCKTYPE', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return SOCK+' = socket.socket(socket.'+SOCKTYPE+')\n'

    }
    // 将套接字绑定地址
    Blockly.Python['IoTb_socketBindAddress'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let SOCK = Blockly.Python.valueToCode(block, 'SOCK', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let ADDRESS = Blockly.Python.valueToCode(block, 'ADDRESS', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let PORT = Blockly.Python.valueToCode(block, 'PORT', Blockly.Python.ORDER_ATOMIC);
        return SOCK+'.bind(("'+ADDRESS+'", '+PORT+'))\n'

    }
    // 将套接字连接地址
    Blockly.Python['IoTb_socketConnectAddress'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let SOCK = Blockly.Python.valueToCode(block, 'SOCK', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let ADDRESS = Blockly.Python.valueToCode(block, 'ADDRESS', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let PORT = Blockly.Python.valueToCode(block, 'PORT', Blockly.Python.ORDER_ATOMIC);
        return SOCK+'.connect(("'+ADDRESS+'", '+PORT+'))\n'

    }
    // 开始监听传入连接
    Blockly.Python['IoTb_socketListen'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let SOCK = Blockly.Python.valueToCode(block, 'SOCK', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let NUM = Blockly.Python.valueToCode(block, 'NUM', Blockly.Python.ORDER_ATOMIC);
        return SOCK+'.listen('+NUM+')\n'

    }
    // 接受连接并返回套接字对象
    Blockly.Python['IoTb_socketaccept'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let SOCK = Blockly.Python.valueToCode(block, 'SOCK', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return [SOCK+'.accept()']

    }
    // 接受套接字的数据TCP
    Blockly.Python['IoTb_socketTCPConnRecv'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let CONN = Blockly.Python.valueToCode(block, 'CONN', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let COUNT = Blockly.Python.valueToCode(block, 'COUNT', Blockly.Python.ORDER_ATOMIC);
        return [CONN+'.recv('+COUNT+')']

    }

    // [CONN] 发送数据TCP
    Blockly.Python['IoTb_socketTCPSend'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let CONN = Blockly.Python.valueToCode(block, 'CONN', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let DATA = Blockly.Python.valueToCode(block, 'DATA', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return CONN+'.send('+DATA+')\n'

    }

    // 接受套接字的数据UDP
    Blockly.Python['IoTb_socketUDPConnRecv'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let CONN = Blockly.Python.valueToCode(block, 'CONN', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let COUNT = Blockly.Python.valueToCode(block, 'COUNT', Blockly.Python.ORDER_ATOMIC);
        return [CONN+'.recvfrom('+COUNT+')']

    }

    // [CONN] 发送数据UDP
    Blockly.Python['IoTb_socketTXPSend'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let CONN = Blockly.Python.valueToCode(block, 'CONN', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let DATA = Blockly.Python.valueToCode(block, 'DATA', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let ADDRESS = Blockly.Python.valueToCode(block, 'ADDRESS', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        let PORT = Blockly.Python.valueToCode(block, 'PORT', Blockly.Python.ORDER_ATOMIC);
        return CONN+'.sendto('+DATA+',("'+ADDRESS+'", '+PORT+'))'

    }

    // conn关闭连接
    Blockly.Python['IoTb_socketclose'] = function(block){
        Blockly.Python.definitions_['import_network'] = 'import network';
        Blockly.Python.definitions_['import_socket'] = 'import socket';
        let CONN = Blockly.Python.valueToCode(block, 'CONN', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"");
        return CONN+'.close()'
    }

    // 获取变量
    Blockly.Python['IoTb_getVariableName'] = function(block){
        return [Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"")];
    }

    // 变量转字符串
    Blockly.Python['IoTb_changeVariableToString'] = function(block){
        let Variable =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC));
        return ['str('+Variable+')']
    }

    // mqtt 发送消息
    Blockly.Python['IoTb_MqttSendMessage'] = function(block){
        let MESSAGE =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MESSAGE', Blockly.Python.ORDER_ATOMIC));
        let MSG_NAME =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MSG_NAME', Blockly.Python.ORDER_ATOMIC));
        return 'board_app.app_senddata('+MSG_NAME+', '+MESSAGE+')\n'
    }
    // mqtt发送消息字符串
    Blockly.Python['IoTb_MqttSendMessageStr'] = function(block){
        let MESSAGE =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MESSAGE', Blockly.Python.ORDER_ATOMIC));
        return 'board_app.app_senddata_sendmsg('+MESSAGE+')\n'
    }

    // 串口 uart初始化
    Blockly.Python['IoTb_initUart'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let RX_Pin =  Blockly.Python.valueToCode(block, 'RX_Pin', Blockly.Python.ORDER_ATOMIC);
        let TX_Pin =  Blockly.Python.valueToCode(block, 'TX_Pin', Blockly.Python.ORDER_ATOMIC);
        let BAUDRATE =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'BAUDRATE', Blockly.Python.ORDER_ATOMIC));

        return 'uart = machine.UART(2, baudrate='+BAUDRATE+', rx='+RX_Pin+',tx='+TX_Pin+',timeout=10)\n'
    }
    // 串口 存在数据
    Blockly.Python['IoTb_whileUartAny'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return ['uart.any()', Blockly.Python.ORDER_ATOMIC]
    }
    // 串口读取一行数据
    Blockly.Python['IoTb_uartReadline'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let VARIABLE =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC));
        return VARIABLE+'= uart.readline()\n'
    }
    // 串口读取所有数据
    Blockly.Python['IoTb_uartReadAll'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let VARIABLE =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC));
        return VARIABLE+'= uart.read()\n'
    }
    // 语音识别模块
    Blockly.Python['IoTb_speechRecognition'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let Speech_Recognition =  Blockly.Python.valueToCode(block, 'Speech_Recognition', Blockly.Python.ORDER_ATOMIC);
        let SUBSTACK = Blockly.Python.statementToCode(block, 'SUBSTACK'),
            pass = Blockly.Python.addLoopTrap(SUBSTACK, block.id) || '  pass';
        let pass_format = pass.split('\n');
        let func_body='';
        for(let i = 0;i< pass_format.length;i++){
            func_body += ''+pass_format[i]+'\n'
        }
        return "if len(bin_data)> 4 and bin_data[4] == "+Speech_Recognition+":\n" +
            func_body+'\n'
    }
    // 串口写数据
    Blockly.Python['IoTb_uartWrite'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        let BYTES =  replaceQuotationMarks(Blockly.Python.valueToCode(block, 'BYTES', Blockly.Python.ORDER_ATOMIC));
        return 'uart.write('+BYTES+')\n'
    }
    //初始化oled屏幕
    Blockly.Python['IoTb_initOLED'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return 'oled = ssd1306.SSD1306_I2C(128, 64, i2c)\n'
    }
    //设置屏幕字母
    Blockly.Python['IoTb_setOLEDShowText'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC);
        let COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC);
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        return 'oled.text('+TEXT+','+COORDINATE_X+','+COORDINATE_Y+')\n'
    }
    //屏幕显示类型
    Blockly.Python['IoTb_setScreenShowType'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        let SHOW_TYPE = Blockly.Python.valueToCode(block, 'SHOW_TYPE', Blockly.Python.ORDER_ATOMIC);
        return 'oled.'+SHOW_TYPE+' \n'
    }
    //设置描点
    Blockly.Python['IoTb_setScreenDrawDot'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC);
        let LUMINANCE = Blockly.Python.valueToCode(block, 'LUMINANCE', Blockly.Python.ORDER_ATOMIC);
        return 'oled.pixel('+COORDINATE_X+','+COORDINATE_Y+','+LUMINANCE+')\n'
    }
    //屏幕画线
    Blockly.Python['IoTb_setScreenDrawLine'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        let COORDINATE_X1 = Blockly.Python.valueToCode(block, 'COORDINATE_X1', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y1 = Blockly.Python.valueToCode(block, 'COORDINATE_Y1', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_X2 = Blockly.Python.valueToCode(block, 'COORDINATE_X2', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y2 = Blockly.Python.valueToCode(block, 'COORDINATE_Y2', Blockly.Python.ORDER_ATOMIC);
        let ACTION = Blockly.Python.valueToCode(block, 'ACTION', Blockly.Python.ORDER_ATOMIC);
        return 'oled.line('+COORDINATE_X1+', '+COORDINATE_Y1+', '+COORDINATE_X2+ ', '+COORDINATE_Y2+ ', '+ACTION+') \n'
    }
    //屏幕画水平或垂直线
    Blockly.Python['IoTb_setScreenDrawDirectionLine'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC),
            DIRECTION = Blockly.Python.valueToCode(block, 'DIRECTION', Blockly.Python.ORDER_ATOMIC),
            ACTION = Blockly.Python.valueToCode(block, 'ACTION', Blockly.Python.ORDER_ATOMIC),
            LENGTH = Blockly.Python.valueToCode(block, 'LENGTH', Blockly.Python.ORDER_ATOMIC);
        return 'oled.'+DIRECTION+'('+COORDINATE_X+', '+COORDINATE_Y+', '+LENGTH+', '+ACTION+')\n'
    }
    //屏幕画矩形
    Blockly.Python['IoTb_setScreenDrawRectangle'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC),
            ACTION = Blockly.Python.valueToCode(block, 'ACTION', Blockly.Python.ORDER_ATOMIC),
            TYPE = Blockly.Python.valueToCode(block, 'TYPE', Blockly.Python.ORDER_ATOMIC),
            HEIGHT = Blockly.Python.valueToCode(block, 'HEIGHT', Blockly.Python.ORDER_ATOMIC),
            WIDTH = Blockly.Python.valueToCode(block, 'WIDTH', Blockly.Python.ORDER_ATOMIC);
        return 'oled.'+TYPE+'('+COORDINATE_X+', '+COORDINATE_Y+', '+WIDTH+', '+HEIGHT+', '+ACTION+')\n'
    }
    //屏幕画圆形
    Blockly.Python['IoTb_setScreenDrawCircular'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        let COORDINATE_X = Blockly.Python.valueToCode(block, 'COORDINATE_X', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y = Blockly.Python.valueToCode(block, 'COORDINATE_Y', Blockly.Python.ORDER_ATOMIC),
            ACTION = Blockly.Python.valueToCode(block, 'ACTION', Blockly.Python.ORDER_ATOMIC),
            TYPE = Blockly.Python.valueToCode(block, 'TYPE', Blockly.Python.ORDER_ATOMIC),
            RADIUD = Blockly.Python.valueToCode(block, 'RADIUD', Blockly.Python.ORDER_ATOMIC);
        return 'oled.'+TYPE+'('+COORDINATE_X+', '+COORDINATE_Y+', '+RADIUD+', '+ACTION+')\n'
    }
    //屏幕画三角形
    Blockly.Python['IoTb_setScreenDrawTriangle'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        let COORDINATE_X1 = Blockly.Python.valueToCode(block, 'COORDINATE_X1', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y1 = Blockly.Python.valueToCode(block, 'COORDINATE_Y1', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_X2 = Blockly.Python.valueToCode(block, 'COORDINATE_X2', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y2 = Blockly.Python.valueToCode(block, 'COORDINATE_Y2', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_X3 = Blockly.Python.valueToCode(block, 'COORDINATE_X3', Blockly.Python.ORDER_ATOMIC),
            COORDINATE_Y3 = Blockly.Python.valueToCode(block, 'COORDINATE_Y3', Blockly.Python.ORDER_ATOMIC),
            ACTION = Blockly.Python.valueToCode(block, 'ACTION', Blockly.Python.ORDER_ATOMIC),
            TYPE = Blockly.Python.valueToCode(block, 'TYPE', Blockly.Python.ORDER_ATOMIC);
        return 'oled.'+TYPE+'('+COORDINATE_X1+', '+COORDINATE_Y1+', '+COORDINATE_X2+', '+COORDINATE_Y2+', '+COORDINATE_X3+', '+COORDINATE_Y3+', '+ACTION+')\n'
    }
    //屏幕显示内容
    Blockly.Python['IoTb_setOLEDScreenShow'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        return 'oled.show()\n'
    }
    // OLED 清屏
    Blockly.Python['IoTb_clearOLED'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        return 'oled.show_fill(0)\n'
    }
    // OLED 显示第几行
    Blockly.Python['IoTb_setOLEDShowRows'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        let ROW = Blockly.Python.valueToCode(block, 'ROW', Blockly.Python.ORDER_ATOMIC);
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        return 'oled.text('+TEXT+',0,'+ROW+')\n'
    }
    // OLED 清除第几行
    Blockly.Python['IoTb_setOLEDClearRows'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_ssd1306'] = 'import ssd1306';
        let ROW = Blockly.Python.valueToCode(block, 'ROW', Blockly.Python.ORDER_ATOMIC);
        return 'oled.fill_rect(0,'+ROW+',128,16,0)\n'
    }
    // LM35温度传感器
    Blockly.Python['IoTb_ADCgetLM35temp'] = function(block){
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        Blockly.Python.definitions_['import_lm35'] = 'import lm35';
        let DIGITAL_OUTPUT_PIN = Blockly.Python.valueToCode(block, 'DIGITAL_OUTPUT_PIN', Blockly.Python.ORDER_ATOMIC);
        let code = 'lm35.get_LM35_temperature('+DIGITAL_OUTPUT_PIN+')'
        return [code]
    }
    Blockly.Python['IoTb_initInfraredData'] = function(block){
        let VARIABLE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC));
        let SUBSTACK = Blockly.Python.statementToCode(block, 'SUBSTACK');
        let pass = Blockly.Python.addLoopTrap(SUBSTACK, block.id);
        return 'def '+VARIABLE+'(data,addr):\n' +pass+'\n'
    }
    Blockly.Python['IoTb_initInfraredSend'] = function(block){
        Blockly.Python.definitions_['import_scboard'] = 'import SCBoard';
        let PIN = Blockly.Python.valueToCode(block, 'PIN', Blockly.Python.ORDER_ATOMIC);
        return 'infrared = SCBoard.infrared_set('+PIN+')\n'
    }
    Blockly.Python['IoTb_infraredSendBinData'] = function(block){
        Blockly.Python.definitions_['import_scboard'] = 'import SCBoard';
        let DATA = Blockly.Python.valueToCode(block, 'DATA', Blockly.Python.ORDER_ATOMIC);
        return 'infrared.inf_sendcode(b'+DATA+')\n'
    }
    Blockly.Python['IoTb_getInfraredData'] = function(block){
        Blockly.Python.definitions_['import_irremote'] = 'import irremote';
        let FUNCTION = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'FUNCTION', Blockly.Python.ORDER_ATOMIC));
        let PIN = Blockly.Python.valueToCode(block, 'PIN', Blockly.Python.ORDER_ATOMIC);
        return 'irremote.resume('+PIN+','+FUNCTION+')\n'
    }
    //土壤湿度
    Blockly.Python['IoTb_modbusSoilMoisture'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 02 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'soilMoisture'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x02,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      moisture = (value[3]*256 + value[4]*1)/10',
                '      return moisture',
                '  except:',
                '    print(\'读取土壤湿度数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //空气湿度
    Blockly.Python['IoTb_modbusAirMoisture'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 00 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'airMoisture'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x00,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      moisture = (value[3]*256 + value[4]*1)/10',
                '      return moisture',
                '  except:',
                '    print(\'读取空气湿度数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //空气温度
    Blockly.Python['IoTb_modbusAirTemperature'] = function(block){
        Blockly.Python.definitions_['import_ori'] = 'import ori';
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 01 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'airTemperature'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x01,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      getTemp = value[3]*256 + value[4]',
                '      if getTemp<800:',
                '        temp = getTemp/10',
                '        return temp',
                '      else:',
                '        binary = bin(value)[2:]',
                '        list_bin = [0] * (16 - len(binary))',
                '        list_bin = list_bin + list(map(int,binary))',
                '        str_bin = "".join(\'%d\'%i for i in list_binary)',
                '        airtemp = ori.com2dec(temp)/10',
                '        return airtemp',
                '  except:',
                '    print(\'读取空气温度数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //风向
    Blockly.Python['IoTb_modbusWindDirection'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        Blockly.Python.definitions_['wind_direction'] = 'wind_direction = {0:\'东北偏北\', 1:\'东北\', 2:\'东北偏东\',3:\'正东\', 4:\'东南偏东\',5:\'东南\',\n' +
            '6:\'东南偏南\',7:\'正南\',8:\'西南偏南\',9:\'西南\',10:\'西南偏西\',11:\'正西\',12:\'西北偏西\',13:\'西北\',14:\'西北偏北\',15:\'正北\'\n' +
            '}';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 17 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'windDirection'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x17,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      direction = value[3]*256 + value[4]*1',
                '      return wind_direction[direction]',
                '  except:',
                '    print(\'读取风向数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //风速
    Blockly.Python['IoTb_modbusWindSpeed'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 16 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'windSpeed'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x16,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      speed = (value[3]*256 + value[4]*1)/10',
                '      return speed',
                '  except:',
                '    print(\'读取风速数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //气象站光照
    Blockly.Python['IoTb_modbusBeam'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 07 00 02');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'Beam'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x07,0x00,0x02,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      beam = value[3]*16777215+ value[4]*65536 + value[5]*256 + value[6]',
                '      return beam',
                '  except:',
                '    print(\'读取光照数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //PH
    Blockly.Python['IoTb_modbusPHValue'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 00 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'PHValue'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x00,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      ph = value[3]*256+ value[4]*1',
                '      return ph/10',
                '  except:',
                '    print(\'读取PH数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //PM2.5
    Blockly.Python['IoTb_modbusPMValue'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 04 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'PMValue'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x04,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      pm = value[3]*256+ value[4]*1',
                '      return pm',
                '  except:',
                '    print(\'读取PM2.5数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //大气压
    Blockly.Python['IoTb_getAtmosphericPressure'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 0A 00 02');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'Pressure'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x0A,0x00,0x02,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      pressure = value[3]*16777215 + value[4]*65536 + value[5]*256 + value[6]*1',
                '      return pressure/1000',
                '  except:',
                '    print(\'读取大气压数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //土壤湿度
    Blockly.Python['IoTb_getSoilMoisture'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 00 00 03');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'soilMoistureValue'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x00,0x00,0x03,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      soil_moisture = (value[3]*256 + value[4]*1)/10',
                '      return soil_moisture',
                '  except:',
                '    print(\'读取土壤湿度数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //土壤温度
    Blockly.Python['IoTb_getSoilTemp'] = function(block){
        Blockly.Python.definitions_['import_ori'] = 'import ori';
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 03 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'soilTemperature'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x03,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      getTemp = value[3]*256 + value[4]',
                '      if getTemp<800:',
                '        temp = getTemp/10',
                '        return temp',
                '      else:',
                '        binary = bin(value)[2:]',
                '        list_bin = [0] * (16 - len(binary))',
                '        list_bin = list_bin + list(map(int,binary))',
                '        str_bin = "".join(\'%d\'%i for i in list_binary)',
                '        soiltemp = ori.com2dec(temp)/10',
                '        return soiltemp',
                '  except:',
                '    print(\'读取土壤温度数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //土壤电导率
    Blockly.Python['IoTb_getSoilECSensor'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 02 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'soilECSensor'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x02,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      ecSensor = value[3]*256 + value[4]*1',
                '      return ecSensor',
                '  except:',
                '    print(\'读取土壤电导率数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //大棚氮
    Blockly.Python['IoTb_getSoilNitrogen'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 1E 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'Nitrogen'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x1E,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      n = value[3]*256 + value[4]*1',
                '      return n',
                '  except:',
                '    print(\'读取氮数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //大棚磷
    Blockly.Python['IoTb_getSoilPhosphorus'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 1F 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'Phosphorus'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x1F,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      p = value[3]*256 + value[4]*1',
                '      return p',
                '  except:',
                '    print(\'读取磷数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //大棚钾
    Blockly.Python['IoTb_getSoilPotassium'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 20 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'Potassium'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x20,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      k = value[3]*256 + value[4]*1',
                '      return k',
                '  except:',
                '    print(\'读取钾数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //气象站雨量
    Blockly.Python['IoTb_getRainfall'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 00 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'Rainfall'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x00,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      rainfall = (value[3]*256 + value[4]*1)/10',
                '      return rainfall',
                '  except:',
                '    print(\'读取雨量数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    Blockly.Python['IoTb_greenhouseAirMoisture'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 00 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'greenhouseMoisture'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x00,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      ghMoisture = (value[3]*256 + value[4]*1)/10',
                '      return ghMoisture',
                '  except:',
                '    print(\'读取大气压数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //大棚温度
    Blockly.Python['IoTb_greenhouseAirTemp'] = function(block){
        Blockly.Python.definitions_['import_ori'] = 'import ori';
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 01 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'greenhouseAirTemp'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x01,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      getTemp = value[3]*256 + value[4]',
                '      if getTemp<800:',
                '        temp = getTemp/10',
                '        return temp',
                '      else:',
                '        binary = bin(value)[2:]',
                '        list_bin = [0] * (16 - len(binary))',
                '        list_bin = list_bin + list(map(int,binary))',
                '        str_bin = "".join(\'%d\'%i for i in list_binary)',
                '        ghtemp = ori.com2dec(temp)/10',
                '        return ghtemp',
                '  except:',
                '    print(\'读取空气温度数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //大棚大气压
    Blockly.Python['IoTb_greenhouseAtmosphericPressure'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 0A 00 02');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'greenhousePressure'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x0A,0x00,0x02,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      pressure = value[3]*16777215 + value[4]*65536 + value[5]*256 + value[6]*1',
                '      return pressure/1000',
                '  except:',
                '    print(\'读取大气压数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //大棚光照
    Blockly.Python['IoTb_greenhouseBeam'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 07 00 02');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'greenhouseBeam'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x07,0x00,0x02,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      beam = value[3]*16777215+ value[4]*65536 + value[5]*256 + value[6]',
                '      return beam',
                '  except:',
                '    print(\'读取光照数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //气象站PM10
    Blockly.Python['IoTb_modbusPM10Value'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 09 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'PM10Value'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x09,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      pm10 = value[3]*256+ value[4]*1',
                '      return pm10',
                '  except:',
                '    print(\'读取PM10数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    //大棚二氧化碳
    Blockly.Python['IoTb_getCO2'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 05 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'getCO2Value'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x05,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      co2 = value[3]*256+ value[4]*1',
                '      return co2',
                '  except:',
                '    print(\'读取二氧化碳数值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    Blockly.Python['IoTb_addMqttTheme'] = function(block){
        let NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC));
        let FUNCTION = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'FUNCTION', Blockly.Python.ORDER_ATOMIC));
        let MSG = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MSG', Blockly.Python.ORDER_ATOMIC));
        let SUBSTACK = Blockly.Python.statementToCode(block, 'SUBSTACK');
        let pass = Blockly.Python.addLoopTrap(SUBSTACK, block.id);
        return 'def '+FUNCTION+'(topic,'+MSG+'):\n' +
            '  print("'+FUNCTION+'  topic:" + str(topic) + "  msg:" + str('+MSG+'))\n' +pass+'\n'+
            'board_app.mqtt_subscribe('+NAME+','+FUNCTION+')\n'
    }
    Blockly.Python['IoTb_MqttSendCustomMessage'] = function(block){
        let MSG_NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MSG_NAME', Blockly.Python.ORDER_ATOMIC));
        let MESSAGE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'MESSAGE', Blockly.Python.ORDER_ATOMIC));
        return 'board_app.app_senddata_self('+MSG_NAME+','+MESSAGE+')\n'
    }
    Blockly.Python['IoTb_MqttIsConnect'] = function(block){
        return ['board_app.state']
    }
    Blockly.Python['IoTb_openRadio'] = function(block){
        let CHANNEL = Blockly.Python.valueToCode(block, 'CHANNEL', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_radio'] = 'import radio';
        return 'radio.on()\nradio.config(channel='+CHANNEL+')\n'
    }
    Blockly.Python['IoTb_sendRadioMessage'] = function(block){
        let MESSAGE = Blockly.Python.valueToCode(block, 'MESSAGE', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_radio'] = 'import radio';
        return 'radio.send('+MESSAGE+')\n'
    }
    Blockly.Python['IoTb_closeRadio'] = function(block){
        Blockly.Python.definitions_['import_radio'] = 'import radio';
        return 'radio.off()\n'
    }
    Blockly.Python['IoTb_receiveRadioMessage'] = function(block){
        let MESSAGE = Blockly.Python.valueToCode(block, 'MESSAGE', Blockly.Python.ORDER_ATOMIC);
        Blockly.Python.definitions_['import_mpython'] = 'from mpython import *';
        Blockly.Python.definitions_['import_Timer'] = 'from machine import Timer';
        Blockly.Python.definitions_['import_radio'] = 'import radio';
        Blockly.Python.definitions_['import_ubinascii'] = 'import ubinascii';
        Blockly.Python.definitions_['radio_callback'] = '_radio_msg_list = []\n'+
            'def radio_callback(_msg):\n'+
            '  global _radio_msg_list\n'+
            '  try: radio_recv(_msg)\n'+
            '  except: pass\n'+
            '  if _msg in _radio_msg_list:\n'+
            '    eval(\'radio_recv_\' + bytes.decode(ubinascii.hexlify(_msg)) + \'()\')\n'+
            'tim13 = Timer(13)\n'+
            'def timer13_tick(_):\n'+
            '  _msg = radio.receive()\n'+
            '  if not _msg: return\n'+
            '  radio_callback(_msg)\n'+
            'tim13.init(period=20, mode=Timer.PERIODIC, callback=timer13_tick)';
        let SUBSTACK = Blockly.Python.statementToCode(block, 'SUBSTACK');
        let pass = Blockly.Python.addLoopTrap(SUBSTACK, block.id) || '  pass';
        return '_radio_msg_list.append('+MESSAGE+')\n'+
            'def radio_recv_'+replaceQuotationMarks(MESSAGE)+'():\n' + pass+'\n'

    }
    Blockly.Python['IoTb_receiveArbitrarilyRadioMessage'] = function(block){
        Blockly.Python.definitions_['import_mpython'] = 'from mpython import *';
        Blockly.Python.definitions_['import_Timer'] = 'from machine import Timer';
        Blockly.Python.definitions_['import_radio'] = 'import radio';
        Blockly.Python.definitions_['import_ubinascii'] = 'import ubinascii';
        Blockly.Python.definitions_['radio_callback'] = '_radio_msg_list = []\n'+
            'def radio_callback(_msg):\n'+
            '  global _radio_msg_list\n'+
            '  try: radio_recv(_msg)\n'+
            '  except: pass\n'+
            '  if _msg in _radio_msg_list:\n'+
            '    eval(\'radio_recv_\' + bytes.decode(ubinascii.hexlify(_msg)) + \'()\')\n'+
            'tim13 = Timer(13)\n'+
            'def timer13_tick(_):\n'+
            '  _msg = radio.receive()\n'+
            '  if not _msg: return\n'+
            '  radio_callback(_msg)\n'+
            'tim13.init(period=20, mode=Timer.PERIODIC, callback=timer13_tick)';
        let SUBSTACK = Blockly.Python.statementToCode(block, 'SUBSTACK');
        let pass = Blockly.Python.addLoopTrap(SUBSTACK, block.id) || '  pass';
        return 'def radio_recv(_msg):\n' + pass+'\n'
    }
    Blockly.Python['IoTb_receiveRadioOtherMessage'] = function(block){
        return ['_msg']
    }
    Blockly.Python['IoTb_getWaterECSensor'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 02 00 02');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'WaterConductivity'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x02,0x00,0x02,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      beam =( value[3]*16777215+ value[4]*65536 + value[5]*256 + value[6] )/100',
                '      return beam',
                '  except:',
                '    print(\'读取水中电导率值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    Blockly.Python['IoTb_getWaterDissolvedOxygen'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 02 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'WaterDissolvedOxygen'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x02,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      moisture = (value[3]*256 + value[4]*1)/100',
                '      return moisture',
                '  except:',
                '    print(\'读取水中溶解氧错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    Blockly.Python['IoTb_getWaterPHvalue'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 02 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'WaterPHvalue'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x02,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      moisture = (value[3]*256 + value[4]*1)/100',
                '      return moisture',
                '  except:',
                '    print(\'读取水中PH值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    Blockly.Python['IoTb_getWaterTurbidness'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 02 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'WaterTurbidness'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x02,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      moisture = value[3]*256 + value[4]*1',
                '      return moisture',
                '  except:',
                '    print(\'读取水浊度值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    Blockly.Python['IoTb_getWaterTemp'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let ADDRESS_CODE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS_CODE', Blockly.Python.ORDER_ATOMIC));
        let ModbusCrc16 = ToModbusCRC16(ADDRESS_CODE.substring(ADDRESS_CODE.length-2)+' 03 00 01 00 01');
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'WaterTemp'+ADDRESS_CODE,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+ADDRESS_CODE+',0x03,0x00,0x01,0x00,0x01,0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      moisture = (value[3]*256 + value[4]*1)/10',
                '      return moisture',
                '  except:',
                '    print(\'读取水温度值错误\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
    Blockly.Python['IoTb_parsingMessages'] = function(block){
        let VARIABLE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC));
        let DATA = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'DATA', Blockly.Python.ORDER_ATOMIC));
        let ADDRESS = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'ADDRESS', Blockly.Python.ORDER_ATOMIC));
        let functionName = Blockly.Python.provideFunction_(
            'getModbussensor',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(bin_data,dlen):',
                '  try:',
                '    if bin_data==None:',
                '      print("串口数据为空")',
                '    else:',
                '      if dlen == 1:',
                '        adds = bin_data[2]*256 + bin_data[3]',
                '        datalen = bin_data[10]',
                '        data = 0',
                '        bindata = [1,256,65536,16777215,4294967296,1099511627775]',
                '        for i in range(0,datalen,1):',
                '          data = data + (bin_data[11 + i] * bindata[datalen - i - 1])',
                '        return adds,data',
                '      if dlen == 0:',
                '        adds = bin_data[0]',
                '        datalen = bin_data[2]',
                '        data = 0',
                '        bindata = [1,256,65536,16777215,4294967296,1099511627775]',
                '        for i in range(0,datalen,1):',
                '          data = data + (bin_data[3 + i] * bindata[datalen - i - 1])',
                '        return adds,data',
                '  except:',
                '    print(\'解析modbus数据出错\')',
                '    return 0,0',
            ]);
        return ADDRESS+','+DATA+'='+functionName+'('+VARIABLE+',1)\n'
    }
    Blockly.Python['IoTb_parsingAllMessages'] = function(block){
        let DATA = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'DATA', Blockly.Python.ORDER_ATOMIC));
        Blockly.Python.definitions_['gmdt2'] = 'gmdt2=0\ngmdtn = 0';
        let functionName = Blockly.Python.provideFunction_(
            'getModbussensor_t2',
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '(bin_data):',
                '  global gmdt2',
                '  global gmdtn',
                '  try:' ,
                '    if bin_data==None:' ,
                '      print("串口数据为空")' ,
                '    else:' ,
                '      if bin_data[-1] == 10:' ,
                '        print("数据出现换行")' ,
                '        gmdtn = bin_data' ,
                '        return 0,0,0,0' ,
                '      if gmdtn != 0:' ,
                '        gmdt2 = gmdtn + bin_data' ,
                '        gmdtn = 0' ,
                '      if len(bin_data) == 8:' ,
                '        gmdt2 = bin_data' ,
                '        return 0,0,0,0' ,
                '      elif len(bin_data) >10:' ,
                '        device = bin_data[0]' ,
                '        start = bin_data[2]*256 + bin_data[3]' ,
                '        leng = bin_data[4]*256 + bin_data[5]' ,
                '        datalen = bin_data[10]' ,
                '        data = 0' ,
                '        bindata = [1,256,65536,16777215,4294967296,1099511627775]' ,
                '        for i in range(0,datalen,1):' ,
                '          data = data + (bin_data[11 + i] * bindata[datalen - i - 1])' ,
                '        bin_data = 0' ,
                '        return device,start,leng,data' ,
                '      elif len(bin_data) == 7:' ,
                '        device = gmdt2[0]' ,
                '        start = gmdt2[2]*256 + gmdt2[3]' ,
                '        leng = gmdt2[4]*256 + gmdt2[5]' ,
                '        datalen = bin_data[2]' ,
                '        data = 0' ,
                '        bindata = [1,256,65536,16777215,4294967296,1099511627775]' ,
                '        for i in range(0,datalen,1):' ,
                '          data = data + (bin_data[3 + i] * bindata[datalen - i - 1])' ,
                '        bin_data = 0' ,
                '        return device,start,leng,data' ,
                '      elif len(bin_data) == 9:' ,
                '        device = gmdt2[0]' ,
                '        start = gmdt2[2]*256 + gmdt2[3]' ,
                '        leng = gmdt2[4]*256 + gmdt2[5]' ,
                '        datalen = bin_data[2]' ,
                '        data = 0' ,
                '        bindata = [1,256,65536,16777215,4294967296,1099511627775]' ,
                '        for i in range(0,datalen,1):' ,
                '          data = data + (bin_data[3 + i] * bindata[datalen - i - 1])' ,
                '        bin_data = 0' ,
                '        return device,start,leng,data' ,
                '      else:' ,
                '       return 0,0,0,0' ,
                '  except:' ,
                '    print("解析modbus数据错误")',
            ]);
        return [functionName+'('+DATA+')']
    }
    Blockly.Python['IoTb_readSensor'] = function(block){
        Blockly.Python.definitions_['import_time'] = 'import time';
        let DEV_ADDRESS = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'DEV_ADDRESS', Blockly.Python.ORDER_ATOMIC));
        let REGISTER = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'REGISTER', Blockly.Python.ORDER_ATOMIC));
        let LENGTH = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'LENGTH', Blockly.Python.ORDER_ATOMIC));
        let dev = DEV_ADDRESS.substring(DEV_ADDRESS.length-2);
        let reg = REGISTER.substring(REGISTER.length-2);
        let len = LENGTH.substring(LENGTH.length-2);
        let ModbusCrc16 = ToModbusCRC16(dev+' 03 00 '+reg+' 00 '+len);
        let verifyLow = ModbusCrc16.substring(0,2);
        let verifyHigh = ModbusCrc16.substring(2,4);
        let functionName = Blockly.Python.provideFunction_(
            'WaterTemp_'+DEV_ADDRESS+'_'+REGISTER+'_'+LENGTH,
            ['def ' + Blockly.Python.FUNCTION_NAME_PLACEHOLDER_ + '():',
                '  cmd_hex = ['+DEV_ADDRESS+',0x03,0x00,'+REGISTER+',0x00,'+LENGTH+',0x'+verifyLow+',0x'+verifyHigh+']',
                '  cmd_bytes = bytes(cmd_hex)',
                '  uart.write(cmd_bytes)',
                '  time.sleep(0.3)',
                '  value = uart.read()',
                '  time.sleep(0.2)',
                '  try:',
                '    if value:',
                '      datalen = value[2]',
                '      data = 0',
                '      bindata = [1,256,65536,16777215,4294967296,1099511627775]',
                '      for i in range(0,datalen,1):',
                '        data = data + (value[3 + i] * bindata[datalen - i - 1])',
                '      return data',
                '  except:',
                '    print(\'解析传感器数据失败\')',
                '    return 0'
            ]);
        return [functionName+'()']
    }
}
