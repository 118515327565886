exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*$ui-primary: hsla(215, 100%, 95%, 1); !* #E5F0FF *!*/\n/* #E5F0FF */\n/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* #FF4254 主题颜色 */\n/* #3373CC */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* #FF661A */\n/* #E64D00 */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #E53C4C */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0B8E69 */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.menu_menu_1rWB9 {\n    position: absolute;\n    border: 1px solid hsla(0, 0%, 0%, 0.15);\n    border-radius: 0 0 8px 8px;\n    background-color: hsla(354, 100%, 63%, 1);\n    padding: 0;\n    margin: 0;\n    min-width: 90px;\n    max-width: 260px;\n    overflow: visible;\n    color: hsla(0, 100%, 100%, 1);\n    -webkit-box-shadow: 0 8px 8px 0 hsla(0, 0%, 0%, 0.15);\n            box-shadow: 0 8px 8px 0 hsla(0, 0%, 0%, 0.15);\n}\n.menu_menu_1rWB9.menu_left_1Zl7K {\n    right: 0;\n}\n.menu_menu_1rWB9.menu_right_12vPx {\n    left: 0;\n}\n.menu_menu-item_3ELPx {\n    display: block;\n    line-height: 34px;\n    white-space: nowrap;\n    padding: 0 10px;\n    font-size: .75rem;\n    margin: 0;\n    font-weight: bold;\n}\n.menu_menu-item_3ELPx.menu_active_7GxsI,\n.menu_menu-item_3ELPx:hover {\n    background-color: hsla(0, 0%, 0%, 0.15);\n}\n.menu_menu-item_3ELPx.menu_hoverable_3mGWm {\n    cursor: pointer;\n}\n.menu_menu-section_1ai_E {\n    border-top: 1px solid hsla(0, 0%, 0%, 0.15);\n}\n", ""]);

// exports
exports.locals = {
	"menu": "menu_menu_1rWB9",
	"left": "menu_left_1Zl7K",
	"right": "menu_right_12vPx",
	"menu-item": "menu_menu-item_3ELPx",
	"menuItem": "menu_menu-item_3ELPx",
	"active": "menu_active_7GxsI",
	"hoverable": "menu_hoverable_3mGWm",
	"menu-section": "menu_menu-section_1ai_E",
	"menuSection": "menu_menu-section_1ai_E"
};