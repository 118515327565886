const UPDATE_ArduinoCODE = 'scratch-gui/generator/UPDATE_ArduinoCODE';

const initialState = {
    arduinoCode:''
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case UPDATE_ArduinoCODE:
            return Object.assign({}, state, {
                arduinoCode: action.arduinoCode
            });
        default:
            return state;
    }
};

const updateBlockArduinoCode = function (arduinoCode) {
    return {
        type: UPDATE_ArduinoCODE,
        arduinoCode: arduinoCode
    };
};

export {
    reducer as default,
    initialState as generatorArduinoCodeInitialState,
    updateBlockArduinoCode
};
