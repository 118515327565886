/*
* author : zyk
* time : 2021-7-30
* */
import {replaceQuotationMarks} from '../common.js'
export default function (Blockly) {

    // 火星车引脚下拉框
    Blockly.Python['MarsRover_menu_MARS_ROVER_PIN_MENU'] = function(block){
        let MARS_ROVER_PIN = block.getFieldValue('MARS_ROVER_PIN_MENU');
        return [MARS_ROVER_PIN]
    }
    // 舵机端口下拉框
    Blockly.Python['MarsRover_menu_SERVO_POST_MENU'] = function(block){
        let SERVO_POST = block.getFieldValue('SERVO_POST_MENU');
        return [SERVO_POST]
    }
    // 火星车电机下拉框
    Blockly.Python['MarsRover_menu_MARS_ROVER_MOTOR_MENU'] = function(block){
        let MARS_ROVER_MOTOR = block.getFieldValue('MARS_ROVER_MOTOR_MENU');
        return [MARS_ROVER_MOTOR]
    }
    // 电机方向下拉框
    Blockly.Python['MarsRover_menu_MOTOR_DIRECTION_INFO_MENU'] = function(block){
        let MOTOR_DIRECTION_INFO = block.getFieldValue('MOTOR_DIRECTION_INFO_MENU');
        return [MOTOR_DIRECTION_INFO]
    }
    //数据长度
    Blockly.Python['MarsRover_menu_MARS_ROVER_ACTION_MENU'] = function(block){
        let MARS_ROVER_ACTION = block.getFieldValue('MARS_ROVER_ACTION_MENU');
        return [MARS_ROVER_ACTION]
    }
    // 打印
    Blockly.Python['MarsRover_printText'] = function (block) {
        let TEXT = Blockly.Python.valueToCode(block, 'TEXT', Blockly.Python.ORDER_ATOMIC);
        return 'print(' + TEXT + ')\n';
    };
    //初始化火星车扩展板
    Blockly.Python['MarsRover_initMarsRoverTrail'] = function(block){
        Blockly.Python.definitions_['import_mars_rover'] = 'import mars_rover_trail';
        let VARIABLE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC));
        let SDA_PIN = Blockly.Python.valueToCode(block, 'SDA_PIN', Blockly.Python.ORDER_ATOMIC);
        let SCL_PIN = Blockly.Python.valueToCode(block, 'SCL_PIN', Blockly.Python.ORDER_ATOMIC);
        return VARIABLE+' = mars_rover_trail.mars_rover_trail('+SDA_PIN+','+SCL_PIN+')\n'
    }
    Blockly.Python['MarsRover_setCarServo'] = function(block){
        Blockly.Python.definitions_['import_mars_rover'] = 'import mars_rover_trail';
        let VARIABLE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC));
        let POST = Blockly.Python.valueToCode(block, 'POST', Blockly.Python.ORDER_ATOMIC);
        let ANGLE = Blockly.Python.valueToCode(block, 'ANGLE', Blockly.Python.ORDER_ATOMIC);
        return VARIABLE+'.carservo.servo('+POST+','+ANGLE+')\n'
    }
    Blockly.Python['MarsRover_setMarsRoverTrailMotor'] = function(block){
        Blockly.Python.definitions_['import_mars_rover'] = 'import mars_rover_trail';
        let VARIABLE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC));
        let MOTOR = Blockly.Python.valueToCode(block, 'MOTOR', Blockly.Python.ORDER_ATOMIC);
        let DIRECTION = Blockly.Python.valueToCode(block, 'DIRECTION', Blockly.Python.ORDER_ATOMIC);
        let VELOCITY = Blockly.Python.valueToCode(block, 'VELOCITY', Blockly.Python.ORDER_ATOMIC);
        return VARIABLE+'.'+MOTOR+'('+DIRECTION+','+VELOCITY+')\n'
    }
    Blockly.Python['MarsRover_MarsRoverTrailDemo'] = function(block){
        Blockly.Python.definitions_['import_mars_rover'] = 'import mars_rover_trail';
        Blockly.Python.definitions_['import_time'] = 'import time';
        Blockly.Python.definitions_['import_machine'] = 'import machine';
        return 'def chong():\n' +
            '  rover_trail.carA(0,1000)\n' +
            '  rover_trail.carB(0,1000)\n' +
            '  rover_trail.carC(1,1000)\n' +
            '  rover_trail.carD(1,1000)\n' +
            '  time.sleep(0.3)\n' +
            '\n' +
            '\n' +
            '\n' +
            'def youpian():\n' +
            '  rover_trail.carA(1,1000)\n' +
            '  rover_trail.carB(1,1000)\n' +
            '  rover_trail.carC(0,1000)\n' +
            '  rover_trail.carD(0,1000)\n' +
            '\n' +
            '\n' +
            '\n' +
            'def zuopian():\n' +
            '  rover_trail.carA(0,1000)\n' +
            '  rover_trail.carB(0,1000)\n' +
            '  rover_trail.carC(1,1000)\n' +
            '  rover_trail.carD(1,1000)\n' +
            '\n' +
            '\n' +
            '\n' +
            'def tui():\n' +
            '  rover_trail.carA(1,2000)\n' +
            '  rover_trail.carB(1,2000)\n' +
            '  rover_trail.carC(0,1000)\n' +
            '  rover_trail.carD(0,1000)\n' +
            '  time.sleep(2)\n' +
            '\n' +
            '\n' +
            '\n' +
            'def you():\n' +
            '  rover_trail.carA(1,1000)\n' +
            '  rover_trail.carB(1,1000)\n' +
            '  rover_trail.carC(1,1000)\n' +
            '  rover_trail.carD(1,1000)\n' +
            '  time.sleep(1)\n' +
            '  rover_trail.carA(1,2000)\n' +
            '  rover_trail.carB(1,2000)\n' +
            '  rover_trail.carC(0,2000)\n' +
            '  rover_trail.carD(0,2000)\n' +
            '  time.sleep(0.85)\n' +
            '\n' +
            '\n' +
            '\n' +
            'def zuo():\n' +
            '  rover_trail.carA(1,1000)\n' +
            '  rover_trail.carB(1,1000)\n' +
            '  rover_trail.carC(1,1000)\n' +
            '  rover_trail.carD(1,1000)\n' +
            '  time.sleep(1)\n' +
            '  rover_trail.carA(0,2000)\n' +
            '  rover_trail.carB(0,2000)\n' +
            '  rover_trail.carC(1,2000)\n' +
            '  rover_trail.carD(1,2000)\n' +
            '  time.sleep(0.85)\n' +
            '\n' +
            '\n' +
            '\n' +
            'def qian():\n' +
            '  rover_trail.carA(1,1000)\n' +
            '  rover_trail.carB(1,1000)\n' +
            '  rover_trail.carC(1,1000)\n' +
            '  rover_trail.carD(1,1000)\n' +
            '\n' +
            '\n' +
            '\n' +
            'def ting():\n' +
            '  rover_trail.carA(1,0)\n' +
            '  rover_trail.carB(1,0)\n' +
            '  rover_trail.carC(1,0)\n' +
            '  rover_trail.carD(1,0)\n' +
            '\n' +
            '\n' +
            '\n' +
            'def analogRead(p):\n' +
            '  adc = machine.ADC(machine.Pin(p))\n' +
            '  adc.atten(machine.ADC.ATTN_11DB)\n' +
            '  return adc.read()\n' +
            '\n' +
            '\n' +
            'rover_trail = mars_rover_trail.mars_rover_trail(22,23)\n' +
            'ting()\n' +
            'zuozhuan = 0\n' +
            'while True:\n' +
            '  S1 = analogRead(34)\n' +
            '  S2 = analogRead(35)\n' +
            '  S3 = analogRead(32)\n' +
            '  S4 = analogRead(33)\n' +
            '  if (S2 + S3) > 0 and (S4 + S1) == 0:\n' +
            '    qian()\n' +
            '\n' +
            '  if S1 > 0 and (S2 + (S3 + S4)) == 0:\n' +
            '    zuopian()\n' +
            '\n' +
            '  if S4 > 0 and (S1 + (S2 + S3)) == 0:\n' +
            '    youpian()\n' +
            '\n' +
            '  if S2 > 0 and (S1 + (S3 + S4)) == 0:\n' +
            '    zuopian()\n' +
            '\n' +
            '  if S3 > 0 and (S1 + (S2 + S4)) == 0:\n' +
            '    youpian()\n'
    }

    // Blockly.Python['MarsRover_setMarsRoverTrailSpeed'] = function(block){
    //     Blockly.Python.definitions_['import_mars_rover'] = 'import mars_rover_trail';
    //     let VARIABLE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VARIABLE', Blockly.Python.ORDER_ATOMIC));
    //     let ACTION = Blockly.Python.valueToCode(block, 'ACTION', Blockly.Python.ORDER_ATOMIC);
    //     let VELOCITY = Blockly.Python.valueToCode(block, 'VELOCITY', Blockly.Python.ORDER_ATOMIC);
    //     let direction1
    //     let direction2
    //
    //     switch (Number(ACTION)) {
    //         case 0://前进
    //             direction1 = 1
    //             direction2 = 1
    //             break;
    //         case 1://后退
    //             direction1 = 0
    //             direction2 = 0
    //             break;
    //         case 2://左转
    //             direction1 = 1
    //             direction2 = 1
    //             break;
    //         case 3://右转
    //             direction1 = 1
    //             direction2 = 1
    //             break;
    //         case 4://停止
    //             direction1 = 1
    //             direction2 = 1
    //             break;
    //         case 5://掉头
    //             direction1 = 1
    //             direction2 = 0
    //             break;
    //     }
    //     let code = VARIABLE+'.CarA('+direction1+','+VELOCITY+')\n'+
    //         VARIABLE+'.CarB('+direction1+','+VELOCITY+')\n'+
    //         VARIABLE+'.CarC('+direction2+','+VELOCITY+')\n'+
    //         VARIABLE+'.CarD('+direction2+','+VELOCITY+')\n'
    //     return code+'\n'
    // }
}
