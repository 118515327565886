exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n/*$ui-primary: hsla(215, 100%, 95%, 1); !* #E5F0FF *!*/\n\n/* #E5F0FF */\n\n/* #E9F1FC */\n\n/* #D9E3F2 */\n\n/* 90% transparent version of motion-primary */\n\n/* #FFFFFF */\n\n/* 25% transparent version of ui-white */\n\n/* 25% transparent version of ui-white */\n\n/* 25% transparent version of ui-white */\n\n/* 15% transparent version of black */\n\n/* #575E75 */\n\n/* #FF4254 主题颜色 */\n\n/* #3373CC */\n\n/* 35% transparent version of motion-primary */\n\n/* 15% transparent version of motion-primary */\n\n/* #FF661A */\n\n/* #E64D00 */\n\n/* #CF63CF */\n\n/* #BD42BD */\n\n/* #FFAB19 */\n\n/* #E53C4C */\n\n/* #0FBD8C */\n\n/* #0FBD8C */\n\n/* #FF8C1A */\n\n/* #FFB366 */\n\n/* #FF8C1A */\n\n/* #0FBD8C */\n\n/* #0B8E69 */\n\n/* 35% transparent version of extensions-primary */\n\n/* opaque version of extensions-transparent, on white bg */\n\n/* lighter than motion-primary */\n\n.arduino-code-editor_container_1WZaU{\n    position: relative;\n    height:calc(70% - 3.5rem);\n    font-size: 13px;\n    font-weight: bold;\n    -webkit-box-sizing: border-box;\n            box-sizing: border-box;\n    /*border-left: 1px solid $ui-black-transparent;*/\n    padding: 0;\n    background-color:transparent;\n    /*margin-left: 3px;*/\n    /*margin-right: 3px;*/\n    border-top-left-radius: 0.5rem;\n    border-top-right-radius: 0.5rem;\n}\n\n.CodeMirror,.ReactCodeMirror{\n    height:100% !important;\n}\n\n", ""]);

// exports
exports.locals = {
	"container": "arduino-code-editor_container_1WZaU"
};