import {replaceQuotationMarks} from '../common.js'
import {regQuotationMarks} from "../common";

export default function (Blockly) {
    Blockly.Python['code_variables_data_type_menu'] = function(block) {
        let type = block.getFieldValue('TYPE');
        return [type];
    }

    // 获取变量
    Blockly.Python['code_variables_define_variables'] = function(block){
        return [Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC).replace(regQuotationMarks,"")];
    }
    // 定义变量
    Blockly.Python['code_variables_set_variables_value'] = function (block) {
        let NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC));
        let VALUE = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'VALUE', Blockly.Python.ORDER_ATOMIC));
        return NAME+' = '+VALUE+'\n';
    };
    // 设置全局变量
    Blockly.Python['code_variables_set_variable_global'] = function (block) {
        let NAME = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC));
        return 'global '+NAME+'\n';
    };

    // 类型转换
    Blockly.Python['code_variables_change_type'] = function (block) {
        let name = replaceQuotationMarks(Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC));
        let type = Blockly.Python.valueToCode(block, 'TYPE', Blockly.Python.ORDER_ATOMIC);
        return [type+'('+name+')'];
    };
}
