import React from 'react'
import classNames from 'classnames';
import styles from './modal.css';
import bindAll from 'lodash.bindall';
import closeImg from "../code-editor-wrap/close.svg";


class ManualModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'closeModal',
        ]);
        this.state = {
            showModal: false,  // 用于设置模态框的显示隐藏
        };
    }

    closeModal(){
        document.getElementById('manual-modal').style.display = 'none'
    }

    componentDidMount(){
        // this.props.onRef(this);
    }

    render() {
        return (
            <div id="manual-modal" className={styles.manualDialog}>
                <div className={styles.modalTitle}>
                    <div className={styles.headerLeft}>
                        <label className={styles.modalHeaderLab}>使用说明</label>
                    </div>

                    <div className={styles.headerRight}>
                        <button className={styles.closeBtn} onClick={this.closeModal}>
                            <img src={closeImg}/>
                        </button>
                    </div>
                </div>
                <div className={styles.manualContent}>
                    <div className={styles.textContent}>
                        <p className={styles.pLine}>1、使用硬件扩展前，需在驱动中下载并安装相关硬件驱动（CP210X、CH340）。添加硬件扩展后，
                            即可拖动Block进行编码。在出现的代码区域中间，点击上传可在相关硬件运行Block代码。</p>
                        <p className={styles.pLine}>2、使用人脸识别、图像识别等扩展前确保摄像头已连接好。使用图像识别、自然语言处理等扩展前需联网。</p>
                        <p className={styles.pLine}>3、硬件编程时，需要选择串口进行连接。若连接时出现失败提示，请尝试重新连接串口或重新插拔usb接口。</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManualModal;
