import React from 'react'
import classNames from 'classnames';
import styles from './serial-listen.css';
import bindAll from 'lodash.bindall';
import Modal from 'react-modal'
import closeImg from './close.svg'
import {toSerialSendMessage} from '../../reducers/menus.js'
// 波特率
const BAUD_RATE_ARR = [9600, 19200, 57200, 115200, 256000]
// 分隔符
const DELIMITER_MAP = {'no': '', '\\r': '\r','\\n': '\n', '\\r\\n': '\r\n'}

class SerialModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'openModal',
            'closeModal',
            'dragDiv',
            'handleBaudChange',
            'handleClearConsole',
            'handleSendToSerial'
        ]);
        this.state = {
            _move: false
        };
    }

    openModal(){
        document.getElementById('serial-modal').style.display = 'block'
    }

    closeModal(){
        toSerialSendMessage({'command':'close_serial_monitor', 'data': ''})
        document.getElementById('serial-modal').style.display = 'none';
    }
    dragDiv(elementId){
        var oDiv = document.getElementById(elementId)
        var disX = 0;
        var disY = 0;

        oDiv.onmousedown = function (ev){
            var oEvent = ev||event;

            disX =  oEvent.clientX - oDiv.offsetLeft;
            disY =  oEvent.clientY - oDiv.offsetTop;
            document.onmousemove = function (ev){
                var oEvent = ev||event;
                var l = oEvent.clientX - disX;
                var t = oEvent.clientY - disY;
                //此处的判断是为了防止拖拽框被拖出屏幕可视区域
                if(l<0) {
                    l=0;
                }else if(l>document.documentElement.clientWidth - oDiv.offsetWidth){
                    l = document.documentElement.clientWidth - oDiv.offsetWidth;
                }
                if(t<0) {
                    t=0;
                }else if(t>document.documentElement.clientHeight- oDiv.offsetHeight){
                    t = document.documentElement.clientHeight - oDiv.offsetHeight;
                }
                oDiv.style.left = l+ 'px';
                oDiv.style.top  = t+ 'px';
            };
            //此处是为了防止在火狐浏览器下拖拽时会出现的两次阴影的效果，此处代码可以禁用
            document.onmouseup = function (){
                document.onmousemove = null;
                document.onmouseup = null;
            }
        }
    }
    // 波特率变化
    handleBaudChange(){
        let baudSelectObj = document.getElementById('select_baud_rate');
        let baud = baudSelectObj.options[baudSelectObj.selectedIndex].value;
        let hasOpenPort = false;
        for(let port in global.comState){
            if (global.comState[port] === true){
                toSerialSendMessage({'command':'serial_monitor', 'data':{'port': port, 'baud': baud}})
                hasOpenPort = true
            }
        }
        if (!hasOpenPort){
            this.child.closeModal()
            alert('Arduino 串口监视器打开失败:没有选择串口 !')
        }

    }
    // 清空显示
    handleClearConsole(){
        document.getElementById('serial-console').innerText = '';
    }
    // 发送按钮事件 发送到串口
    handleSendToSerial(){
        let delimiterSelectObj = document.getElementById('select_delimiter'),
            delimiter = delimiterSelectObj.options[delimiterSelectObj.selectedIndex].value,
            msg = document.getElementById('serial_message_input').value+delimiter;
        toSerialSendMessage({'command': 'arduino_send_serial', 'data':msg})
        document.getElementById('serial_message_input').value = '';
    }

    componentDidMount(){
        this.props.onRef(this);
        setTimeout(()=>this.dragDiv('serial-modal'),500)
    }

    render() {
        return (
            <div id="serial-modal" className={styles.dialogModal}>
                <div className={styles.modalTitle}>
                    <div className={styles.headerLeft}>
                        <label className={styles.modalHeaderLab}>串口监视器</label>
                    </div>
                    <div className={styles.headerRight}>
                        <button className={styles.closeBtn} onClick={this.closeModal}>
                            <img src={closeImg}/>
                        </button>
                    </div>
                </div>
                <div className={styles.modalContent} id="serial-modal-content">
                    <pre className={styles.consoleBody} id="serial-console"/>
                </div>
                <div className={styles.modalTool}>
                    <div className={styles.toolBox}>
                        <label className={styles.modalTitleLab}>波特率</label>
                        <select id="select_baud_rate" className={classNames(styles.primarySelect, styles.baudSelect)} defaultValue={9600} onChange={this.handleBaudChange}>
                            {
                                BAUD_RATE_ARR.map((item, index) =>{
                                    return <option key={index} value={item}>{item}</option>;
                                })
                            }
                        </select>
                        <label className={classNames(styles.modalTitleLab, styles.gapLeft)}>分隔符</label>
                        <select id="select_delimiter" className={classNames(styles.primarySelect, styles.delimiterSelect)} defaultValue={"\r\n"}>
                            {
                                Object.keys(DELIMITER_MAP).map((item, index) =>{
                                    return <option key={index} value={DELIMITER_MAP[item]}>{item}</option>
                                })
                            }
                        </select>
                        <label className={classNames(styles.modalTitleLab, styles.gapLeft)}>自动滚屏</label>
                        <input type="checkbox" className={classNames(styles.scrollCheckbox)} id={'ckb_scroll_console'} defaultChecked={true}/>
                        <button className={classNames(styles.clearSerialConsoleButton)} onClick={this.handleClearConsole}>清空</button>
                    </div>
                    <input type="text" className={styles.serialInput} id={'serial_message_input'}/>
                    <button className={styles.serialSendButton} onClick={this.handleSendToSerial}>发送</button>
                </div>
            </div>
        );
    }
}

export default SerialModal;



